import React from 'react';
import './Organicfarms.css';
import './GayatriMenon.css';

function GayatriMenonFirstSession() {
    return (
        <>
            <div className="main-banner">
                <h1>Sustainable Business Entrepreneurship Series</h1>
                <img src="images/Home/Sustainability_News/sustainability_news_banner.png" alt="banner" className='banner-img' />
            </div>
            <div className="back-home">
                <p><a href="/home">Home</a></p>
            </div>
            <div className="organicfarms-container">
                <div className="organicfarms-wrap">
                    <p>The <span className='text-highlighter'>Sustainable Business Entrepreneurship Series</span> brings to you inspirational stories and perspectives from people who envision the future, go ahead to create it, and make an impact. Each of these sessions has something we can take away and apply; also benefit from the thoughtfulness and knowledge of the speakers. The diverse topics cut across the <a href="https://www.undp.org/sustainable-development-goals?utm_source=EN&utm_medium=GSR&utm_content=US_UNDP_PaidSearch_Brand_English&utm_campaign=CENTRAL&c_src=CENTRAL&c_src2=GSR&gclid=Cj0KCQiA8t2eBhDeARIsAAVEga0wn2wYvtbbhI1H-HofbJHtWyCLUUgwnOq1qX3VAI7OIo9VJuKnzgsaAo5aEALw_wcB" target="_blank">17 UN SDGs</a>, and we do hope they would open up your minds to new thoughts and possibilities! </p>
                    <p>Do you have comments or suggestions? We would love to hear at <a href="mailto:ATCI.Sust.Research@accenture.com"> ATCI.Sust.Research@accenture.com</a></p>
                    <p>
                        Regards, <br />
                        <span className='text-highlighter'>Shridhar Rajgopalan,</span> Lead - Sustainability and Song, ATCI <br />
                        <span className='text-highlighter'>Anupama Nithyanand,</span> Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI
                    </p>
                    <h2>SUSTAINABLE BUSINESS ENTREPRENEURSHIP SERIES</h2>
                    <h3>About the session:</h3>
                    <div className='aboutsession'>
                        <div className='aboutsession_textContent'>
                            <p>Unleash the potential of innovation in shaping the sustainable future. Discover the power of systems thinking, contextual creativity, life cycles and processes with respect to sustainability in this two-part talk series with <b>Dr. Gayatri Menon.</b></p>
                            <p>A mentor, creative thinker, and a believer in “all things sustainability”, Professor Menon is a principal faculty at the National Institute of Design, NID, with more than 25 years of experience in design education, research, and practice. She is the overall lead of Design foundation studies at NID.</p>
                            <h3 className='aboutsection_joinus_header'>Join us...</h3>
                            <p>Listen to Professor Dr. Gayatri Menon about her passion for ideation and product design innovation, understanding sustainability, broad concerns, creative idea generation for systemic innovation  and <b className='aboutsection_bold'>how you can create differentiated solutions for your clients</b> in the sustainability space.</p>
                        </div>
                        <div>
                            <img src="images/Home/Entrepreneur/gayatri_menon_abt.png" alt="card" className='brother-card-img'/>
                        </div>
                    </div>    
                    <div className='session_details_section'>
                    <table className='session_details_table'>
                    <tr>
                    <th style={{width:"16%"}}>Session details</th>
                    <th style={{width:"16%"}}>Date</th>
                    <th style={{width:"16%"}}>Time</th>
                    <th style={{width:"16%"}}>Joining link</th>
                    <th style={{width:"36%"}}>Topics</th>
                    </tr>
                    <tr>
                    <td>Session: <br></br><b>Sustainability and Innovation</b></td>
                    <td>June 2, 2023</td>
                    <td>9.30am–11.30am</td>
                    <td><a className="session_details_a" href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_MjRjNWM5MWMtYjBiZC00MGJjLTg0ZGYtODNjZGJlMmI1MGYy%40thread.v2/0?context=%7b%22Tid%22%3a%22e0793d39-0939-496d-b129-198edd916feb%22%2c%22Oid%22%3a%223823fab2-503c-469e-837d-af9e0ce609c6%22%7d" target='_blank'>Click here to join the meeting</a></td>
                    <td>
                    <ul className='session_details_table_ul'>
                        <li>Understanding sustainability; open/closed systems</li>
                        <li>Life cycle and circular economy</li>
                        <li>Process analysis from sustainability pov</li>
                        <li>Sustainability audits for organizations</li>
                        <li>Identifying concerns; creative ideation and innovation</li>
                    </ul>
                    </td>
                    </tr>
                    </table>
                    <span className='aboutsession_span'>* We will continue the conversation in subsequent session-2 on June 9th with advanced sustainability frameworks.</span>
                    </div>
                    <div className='more_about_speaker'>
                        <h3 className='aboutspeaker_header'>More about the speaker:</h3>
                        <p>For detailed speaker profile, please visit: <a href="https://nid.edu/people/detail/gayatri-menon" target='_blank'>https://nid.edu/people/detail/gayatri-menon</a></p>
                        <p>Get ready to get inspired! <b>Please accept the calendar invite</b> to attend the session. We eagerly await your presence for this conversation.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GayatriMenonFirstSession;