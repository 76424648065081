import React from 'react';
import './WhitepaperandArtical.css';
import axios from 'axios';
// import EntrepreneurshipTabs from '../function-components/EntrepreneurshipTabs'
import { useMsal } from "@azure/msal-react";

function WhitepaperandArtical() {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();        
    let loggedInCareerLevel = "";
    let loggedInEnterpriseId = '';
    if(activeAccount) {
        loggedInCareerLevel = activeAccount.idTokenClaims.jobFamilyDescription;
        loggedInEnterpriseId = activeAccount.idTokenClaims.enterpriseID;
    }        

    const handleWhitePapersAndArticles = (page) => {    
        if(process.env.NODE_ENV === 'production' && loggedInEnterpriseId !== '') 
        {            
            if((loggedInCareerLevel !== ""  && loggedInCareerLevel != 7 && loggedInCareerLevel != 8 && loggedInCareerLevel != 9 && loggedInCareerLevel != 10 && loggedInCareerLevel != 11 && loggedInCareerLevel != 12)) 
            {
                axios.post('https://atci.sustainabilityresearch.accenture.com/sustainability-research-apis/white-papers-and-articles/views-count', 
                {
                    enterprise_id : loggedInEnterpriseId,            
                    page: page   
                },
                {
                    headers: {
                        'Authorization': localStorage.getItem("token"),
                        'Content-Type': 'application/json'
                    }, 
                })
                .then((response) => {                        
                    console.log("count added");            
                })
                .catch((error)=>{
                    console.log(error);
                })
            }
        }
    }
    return (
        <>
            <div className="whitePaperContainer">
                <div className="paperArticalWrap">
                    <h2><span>White Papers and Articles</span></h2>
                    <p>This section offers our thought-provoking white papers, providing comprehensive insights into diverse sustainability challenges and solutions. Additionally, our engaging articles present perspectives on the evolving sustainability landscape.</p>
                    <div className='WP-cardWrap'>
                        <div className='Papaer-Artical-card'>
                            <div className='cardImage'>
                                <img src='images/Home/WhitePaperandArticles/tile_1.png' className='img-responsive' />
                            </div>
                            {/* <div className='cardinfo-Text'>
                                <h3>Lorem Ipsum is simply dummy text</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <p className='vieMoreLink'><a href="">View More</a></p>
                            </div> */}
                            <div className='WP_card_content'>
                                <div className='WP_card_box'>
                                    <h4 className='WP_card_box_header'>Sustainable Performance Measurement</h4>
                                    {/* <p className='WP_card_box_para'>An Overview By Sheena Siddiqui, Monisha Bhattacharya, Shantanu Biswas</p> */}
                                </div>
                                <div className='WP_Card_box_p_wrap'>
                                <p className='WP_card_box_para1'><a onClick={() => handleWhitePapersAndArticles('Sustainable Measurement')} href="images/Sustainability_Measurement_Whitepaper_Formatted_v4.pdf#toolbar=0" target='_blank' className='WP_card_box_anchor'>View white paper</a></p>
                                </div>
                            </div>
                        </div>
                        <div className='Papaer-Artical-card'>
                            <div className='cardImage'>
                                <img src='images/Home/WhitePaperandArticles/tile_2.png' className='img-responsive' />
                            </div>
                            {/* <div className='cardinfo-Text'>
                                <h3>Lorem Ipsum is simply dummy text</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <p className='vieMoreLink'><a href="">View More</a></p>
                            </div> */}
                            <div className='WP_card_content'>
                                <div className='WP_card_box'>
                                    <h4 className='WP_card_box_header'>Environmental Societal and Governance Reporting </h4>
                                    {/* <p className='WP_card_box_para'>An Overview By Sheena Siddiqui, Monisha Bhattacharya, Shantanu Biswas</p> */}
                                </div>
                                <div className='WP_Card_box_p_wrap'>
                                <p className='WP_card_box_para1'><a onClick={() => handleWhitePapersAndArticles('Sustainable Reporting')} href="images/ESG_Whitepaper_Formatted_v3.pdf#toolbar=0" target='_blank' className='WP_card_box_anchor'>View white paper</a></p>
                                </div>
                            </div>
                        </div>
                        <div className='Papaer-Artical-card'>
                            <div className='cardImage'>
                                <img src='images/Home/WhitePaperandArticles/tile_3.png' className='img-responsive' />
                            </div>
                            {/* <div className='cardinfo-Text'>
                                <h3>Lorem Ipsum is simply dummy text</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <p className='vieMoreLink'><a href="">View More</a></p>
                            </div> */}
                            <div className='WP_card_content'>
                                <div className='WP_card_box'>
                                    <h4 className='WP_card_box_header'>Sustainable Fashion – Textile Industry</h4>
                                    {/* <p className='WP_card_box_para'>By Sheela Ganesh</p> */}
                                    
                                </div>
                                <div className='WP_Card_box_p_wrap'>
                                <p className='WP_card_box_para1'><a onClick={() => handleWhitePapersAndArticles('Sustainable Fashion')} href="images/Sustainable_Fashion_Textile_Industry_Article_Formatted_v5.pdf#toolbar=0" target='_blank' className='WP_card_box_anchor'>View article</a></p>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <img src="images/Home/bg9.png" alt="" className='bg9' />
                <img src="images/Home/bg10.png" alt="" className='bg10' />
            </div>
        </>
    )
}

export default WhitepaperandArtical