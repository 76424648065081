import React from "react";
import "./SNOmkar.css";
import "./SankalpTaruFoundation.css";

const SNOmkar = () => {
  return (
    <>
      <div className="main-banner">
        <h1>Sustainable Business Entrepreneurship Series</h1>
        <img
          src="images/Home/Sustainability_News/sustainability_news_banner.png"
          alt="banner"
          className="banner-img"
        />
      </div>
      <div className="back-home">
        <p>
          <a href="/home">Home</a>
        </p>
      </div>
      <div className="organicfarms-container">
        <div className="organicfarms-wrap">
          <p>
            The{" "}
            <span className="text-highlighter">
              Sustainable Business Entrepreneurship Series
            </span>{" "}
            brings to you inspirational stories and perspectives from people who
            envision the future, go ahead to create it, and make an impact. Each
            of these sessions has something we can take away and apply; also
            benefit from the thoughtfulness and knowledge of the speakers. The
            diverse topics cut across the{" "}
            <a
              href="https://www.undp.org/sustainable-development-goals?utm_source=EN&utm_medium=GSR&utm_content=US_UNDP_PaidSearch_Brand_English&utm_campaign=CENTRAL&c_src=CENTRAL&c_src2=GSR&gclid=Cj0KCQiA8t2eBhDeARIsAAVEga0wn2wYvtbbhI1H-HofbJHtWyCLUUgwnOq1qX3VAI7OIo9VJuKnzgsaAo5aEALw_wcB"
              target="_blank"
            >
              17 UN SDGs
            </a>
            , and we do hope they would open up your minds to new thoughts and
            possibilities!{" "}
          </p>
          <p>
            Do you have comments or suggestions? We would love to hear at{" "}
            <a href="mailto:ATCI.Sust.Research@accenture.com">
              {" "}
              ATCI.Sust.Research@accenture.com
            </a>
          </p>
          <p>
            Regards, <br />
            <span className="text-highlighter">Shridhar Rajgopalan,</span> Lead
            - Sustainability and Song, ATCI <br />
            <span className="text-highlighter">Anupama Nithyanand,</span> Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI
          </p>
          <h2>SUSTAINABLE BUSINESS ENTREPRENEURSHIP SERIES</h2>
          <div className="sankalptaru_content_div">
            <p>
            We live in a fast paced, technology intensive, rapidly changing, complex world. Our ability to adapt continuously to build our resilience and thrive in this world depends on how we are able to manage ourselves. We are extremely pleased to announce an exciting and highly relevant talk addressing this topic.
            </p>
            <h3 className="sn-main-header">Topic Name: <span className="sn-main-span">Self-Sustainability - Managing Time and Stress</span></h3>
            <h3>Description:</h3>
            <p>The concepts of time and stress have become central topics in our fast-paced modern world. In this presentation, we will embark on a journey to explore the intricate relationship between time and stress. We will delve into the profound question of whether they are friends or foes, and uncover valuable insights on how to effectively manage them. Furthermore, we will delve into the philosophical aspect, questioning their very existence and exploring the compelling reasons behind the need to manage them.</p>
          </div>
          <div className="sankalptaru_wrap">
            <div className="sankalptaru-row row1">
              <div className="sankalptaru-box">
                <img
                  src="images/Home/Entrepreneur/sn-omkar-about-new.png"
                  alt="card"
                  className="sn-card-img"
                />
                <h3 className="sn-about-header">Dr. S N Omkar</h3>
                <p className="sn-about-para">
                Chief Research Scientist, Department of Aerospace Engineering, Indian Institute of Science
                </p>
                <p className="sn-about-para">
                Honorary Professor in Yoga, Vedic Wellness University
                </p>
                <p className="sn-about-para">Yoga Consultant, Shankara Cancer Research Foundation</p>
                <p className="sn-about-para">Chairperson: Standardization of Drones Committee by the Bureau of Indian Standards</p>
                <p className="sn-about-para">Convener, Standardization of Agricultural Drones Special Committee by the Bureau of Indian Standards</p>
                <p className="sn-about-para">Chair, Center for Advanced Air Mobility</p>
              </div>
            </div>
            <div className="sankalptaru-row row2">
              <div className="sankalptaru-box">
                <h3 className="sankalptaru-box_header">
                Profile of the Speaker:
                </h3>
                <p className="sankalptaru_mid_para">
                Dr. S. N. Omkar’s research interests include Satellite image processing, Uninhabited Air Vehicles (UAV), Drone Computing, Nature-inspired algorithms, Biomechanics and Yoga. He is also pursuing several projects and research work in the area of Satellite and UAV image processing; Flood and Disaster Management; and Yoga. He has developed several algorithms and used them for a variety of applications like road extraction, flood assessment, crop classification, crop growth analysis, radiation monitoring etc. The other facet of Dr. Omkar's research includes composite design optimization using nature inspired techniques. He has also pioneered in using biomechanical simulation framework for analysis of yogic postures. He has established a laboratory facility towards this.
                </p>
                <p>Dr. Omkar is also a celebrated practitioner and teacher of Yoga. He has been conferred the Karnataka State Rajyothsava Award from the Government of Karnataka and the Kempegowda Award from Bruhat Bengaluru Mahanagara Palike for his contributions in the field of Yoga.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="talk-container">
          <div className="talk-wrap-sn">
            <h3 className="session_header">SESSION DETAILS</h3>
            <div className="session_wrap">
            <div className="session_containers">
            <p className="session_para">Date: <span className="session_span">14th July 2023</span></p>
            <p className="session_para">Time: <span className="session_span">9:30 am to 11:00 am</span></p>
            </div>
            <div className="sn-about-anchor_section"><a className="sn-about-anchor" href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZjQyYmEyMzctYTA0Yi00NTJmLWE0MzktYWY1ZDViNWIzYzhj%40thread.v2/0?context=%7b%22Tid%22%3a%22e0793d39-0939-496d-b129-198edd916feb%22%2c%22Oid%22%3a%22158c819c-1d1c-45b8-866f-212bba66c8a4%22%7d" target="_blank">JOIN SESSION</a></div>  
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SNOmkar;
