import {useState, useEffect, forwardRef} from 'react'
import axios from 'axios';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './Insights.css';
import Navbarmenu from '../Navbarmenu';
import Modal from '@material-ui/core/Modal';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';

function Insights() {    
    const [viewsUserwise, setViewsUserwise] = useState([]);
    const [open, setOpen] = useState(false);
    const [totalAndUniqueViewsForNewsPages, setTotalAndUniqueViewsForNewsPages] = useState([]);
    const [totalAndUniqueViewsForArticlesPages, setTotalAndUniqueViewsForArticlesPages] = useState([]);

    const [fromStartDate, setFromStartDate] = useState(new Date("2023/01/01"));    
    const [toEndDate, setToEndDate] = useState(new Date());

    // let url = "http://localhost:3051";    
    let url = "https://atci.sustainabilityresearch.accenture.com/sustainability-research-apis";

    const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
        <button className="date-picker-input-btn" onClick={onClick} ref={ref}>
          {value}
        </button>
    ));

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

    const handleBarClick = (e) => {                                   
        if(e.chart.tooltip.dataPoints[0].dataset.label === 'Unique Views') {            
            getViewsUserwise(e.chart.tooltip.dataPoints[0].label.substring(0,3), Moment(fromStartDate).format('YYYY'));
            handleOpen();
        }        
    }

    const handleBarClick1 = (e) => {                   
        if(e.chart.tooltip.dataPoints[0].dataset.label === 'Unique Views') {
            getArticlesViewsUserwise(e.chart.tooltip.dataPoints[0].label);
            handleOpen();
        }        
    }

    const handleStartDate = (date) => {        
        setFromStartDate(date)                   
        getTotalAndUniqueViewsForAllPages('Sustainability News', Moment(date).format('YYYY'));
    }

    const options = {    
        scales: {
            y: {
                title: {
                    color: 'grey',
                    display: true,
                    text: 'Views',
                    font: {
                        weight: 'bold'
                    }
                }
            }
        },    
        responsive: true,
        onClick: handleBarClick,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Unique and Total Views Bar Chart',
            padding: 20,
            color: '#6e0089a6',
            font: {
                size: 20
            }
          },
        },
    };

    const options1 = {    
        scales: {
            y: {
                title: {
                    color: 'grey',
                    display: true,
                    text: 'Views',
                    font: {
                        weight: 'bold'
                    }
                }
            }
        },    
        responsive: true,
        onClick: handleBarClick1,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Unique and Total Views Bar Chart',
            padding: 20,
            color: '#6e0089a6',
            font: {
                size: 20
            }
          },
        },
    };

    const data = {
        labels: totalAndUniqueViewsForNewsPages.map((obj)=> {return obj.page.substring(0,3).charAt(0).toUpperCase() + obj.page.substring(0,3).slice(1)}),
        datasets: [
          {
            label: 'Unique Views',
            data: totalAndUniqueViewsForNewsPages.map((obj)=> {return obj.unique_views}),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            datalabels: {
                color: '#6e0089',
                anchor: 'end',
                align: 'top',
                offset: 0,
                font: {
                    weight: 'bolder'
                }
            }
          },
          {
            label: 'Total Views',
            data: totalAndUniqueViewsForNewsPages.map((obj)=> {return obj.total_views}),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            datalabels: {
                color: '#6e0089',
                anchor: 'end',
                align: 'top',
                offset: 0,
                font: {
                    weight: 'bolder'
                }
            }
          },
        ],
    };

    const data1 = {
        labels: totalAndUniqueViewsForArticlesPages.map((obj)=> {return obj.page.charAt(0).toUpperCase() + obj.page.slice(1)}),
        datasets: [
          {
            label: 'Unique Views',
            data: totalAndUniqueViewsForArticlesPages.map((obj)=> {return obj.unique_views}),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            datalabels: {
                color: '#6e0089',
                anchor: 'end',
                align: 'top',
                offset: 0,
                font: {
                    weight: 'bolder'
                }
            }
          },
          {
            label: 'Total Views',
            data: totalAndUniqueViewsForArticlesPages.map((obj)=> {return obj.total_views}),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            datalabels: {
                color: '#6e0089',
                anchor: 'end',
                align: 'top',
                offset: 0,
                font: {
                    weight: 'bolder'
                }
            }
          },
        ],
    };

    const getViewsUserwise = (month,year) => {
        axios.get(
            url + '/sustainbility-news/views-userwise/'+ year + '/' + month,
            {                
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'Authorization': localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                }
            }
        )        
        .then((response)=>{                                                           
            setViewsUserwise(response.data.result);
        })  
        .catch((err)=>{
            console.log(err);    
        })
    }

    const getArticlesViewsUserwise = (page) => {
        axios.get(
            url + '/white-papers-and-articles/views-userwise/'+page,
            {                
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'Authorization': localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                }
            }
        )        
        .then((response)=>{                                                           
            setViewsUserwise(response.data.result);
        })  
        .catch((err)=>{
            console.log(err);    
        })
    }
    
    const getTotalAndUniqueViewsForAllPages = (topic, year) => {
        axios.get(
            url + '/sustainbility-news/totalAndUniqueViews/' + year,
            {                
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'Authorization': localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                }
            }
        )        
        .then((response) => {
            topic === 'Sustainability News' ?                                                           
                setTotalAndUniqueViewsForNewsPages(response.data.result) :
                setTotalAndUniqueViewsForArticlesPages(response.data.result);
        })  
        .catch((err)=>{
            console.log(err);    
        })
    }

    const getTotalAndUniqueViewsForArticlesPages = (topic) => {
        axios.get(
            url + '/white-papers-and-articles/totalAndUniqueViews',
            {                
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'Authorization': localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                }
            }
        )        
        .then((response) => {
            topic === 'Sustainability News' ?                                                           
                setTotalAndUniqueViewsForNewsPages(response.data.result) :
                setTotalAndUniqueViewsForArticlesPages(response.data.result);
        })  
        .catch((err)=>{
            console.log(err);    
        })
    }

    useEffect(() => {        
        getTotalAndUniqueViewsForAllPages('Sustainability News', Moment(fromStartDate).format('YYYY'));
        getTotalAndUniqueViewsForArticlesPages('Sustainable Papers and Articles');                
    },[]);  

    return (
        <>
            <Navbarmenu />            
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div>
                    <table id='user_view'>
                        <tr>
                            <th>Enterprise ID</th>
                            <th>Total Views</th>
                        </tr>
                        {viewsUserwise.map((obj, indx) => {
                            return <tr>
                                <td>{obj.enterprise_id}</td>
                                <td>{obj.views}</td>
                            </tr>                        
                        })}                        
                    </table>                    
                </div>      
            </Modal>            
            <div className='bar-charts__container'>                
                <div className='sustainbility_news__barchart'>
                    <div className='barchart_heading__container'>
                        <span className='barchart_heading_text'>Sustainability News From the World</span> &nbsp;:&nbsp;                        
                            <DatePicker selected={fromStartDate} onChange={(date) => handleStartDate(date)} showYearPicker dateFormat="yyyy" 
                                customInput={<DatePickerInput/>} minDate={new Date("2023/01/01")} maxDate={toEndDate} />                        
                        <span className='barchart_heading_text'>Edition</span>
                    </div>                    
                    <Bar plugins={[ChartDataLabels]} options={options} data={data}/>
                </div>
                <div className='sustainbility_articles__barchart'>                    
                    <span className='barchart_heading_text'>White Papers and Articles</span>                    
                    <Bar plugins={[ChartDataLabels]} options={options1} data={data1}/>
                </div>                    
            </div>                            
        </>                            
    )
}

export default Insights;