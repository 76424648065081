import React, { useEffect } from "react";
import "./SustainabilityNewsJune.css";
import "./SustainabilityNewsFeb.css";
import "./SustainabilityNewsJuly.css";
import "./SustainabilityNewsAugust.css";
import Feedback from './Feedback';
import { Link } from 'react-router-dom';

const SustainabilityNewsSept = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div className="sustainability_main">
                <div className="sustainability_header">
                    <h2>Sustainability News From the World - SEPTEMBER 2023 Edition</h2>
                </div>
                <div className='banner-container'>
                    <div className='home-banner'   >
                        <img src={process.env.PUBLIC_URL + "/images/Home/Sustainability_News/sustainability_news_banner.png"} className="sust_news_gif_img" />
                    </div>
                </div>
                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <div className="sustainability_mid_container">
                    <h2>LEADING WITH SUSTAINABILITY: TRANSLATING INTENT INTO ACTION</h2>
                    <p>As we continue to work toward our goal of translating sustainability from intent to action, we want to thank you for helping us shape the sustainability agenda for ourselves and our clients to deliver value.</p><br />
                    <p>It is indeed heartening to see such enthusiasm to bring sustainability into the heart of all conversations. Organizations around the world are stepping up to meet the sustainability challenge. The <b>ATCI Sustainability Research Cell</b> brings you the latest updates covering technology, industry, clients, Accenture, and the larger ecosystem. Hope you enjoy reading it and find inspiring ideas that you can apply to your ecosystem.</p>
                    <p className="sustainability_para">You can also <a className="sustainability_para_a" href="mailto:ATCI.Sust.Research@accenture.com?subject=ATCI Sustainability Research">share your ideas and suggestions</a> with us. We would love to hear more about your “sustainability stories” and feature them in our newsletter.</p><br />
                    <p>Let’s keep creating incredible conversations.</p>
                    <p className="sustainability_para">Regards,</p>
                    <p><strong>Shridhar Rajgopalan</strong>, Lead - Sustainability and Song, ATCI</p>
                    <p><strong>Anupama Nithyanand</strong>, Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI</p>
                </div>
                <div className="sustainability_li_container">
                    <h3>SUSTAINABILITY NEWS FROM THE WORLD - SEPTEMBER 2023 EDITION</h3>
                    <div className="sustainability_wrap">
                        <ul>
                            <li>What's making news?</li>
                            <li>Can't miss: Trends & Technology</li>
                            <li>In-depth</li>
                            <li>Must-reads…</li>
                            <li>Soulful living!</li>
                            <li>Did you know</li>
                            <li>Sustainability Priority Learnings</li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_highlight_div">
                    <h4>What's making news?</h4>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon12.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">What’s new in assets? A new tool to measure sustainability performance</h4>
                            <p>Explore the <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=125&p=48657" target={"_blank"}>Digital Portfolio Sustainability Analyzer (DPSA)</a> —an AI-led Sustainability Assessment tool that can enable businesses to assess their sustainability performance, enhance their sustainability practices, and align with global goals for a greener future.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon14.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Must read: Embed Sustainability blog series</h4>
                            <p>Presenting an all-new blog series on how to build sustainable businesses. <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=91&p=34757&caturl=aHR0cHM6Ly9pbi5hY2NlbnR1cmUuY29tL3N1c3RhaW5hYmlsaXR5L25ld3Mv" target={"_blank"}>Bookmark this page</a> now for innovative ideas on how companies can seamlessly weave sustainability into their operations and create deep value.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon6.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Our annual sustainability ratings are in!</h4>
                            <p><a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=398&p=37352" target={"_blank"}>See how</a> we did this year in the EcoVadis annual sustainability rating across 21 different criteria on environment, ethics, labor and human rights, and sustainable procurement.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon_16_1.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Latest in SQ learning: Sustainable Leadership and Organization</h4>
                            <p>The new Sustainability Quotient (SQ) module takes you through why sustainability plays a crucial role in the world today and why we all need to act now for a better future. <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=282&p=37723&caturl=aHR0cHM6Ly9pbi5hY2NlbnR1cmUuY29tL3N1c3RhaW5hYmlsaXR5L25ld3Mv" target={"_blank"}>Explore now</a> and earn cool badges.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon_17_1.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Latest in events: The legal landscape of ESG (Environment, Social, Governance)</h4>
                            <p>Block your calendar on September 14 for the Backstage Pass event to learn more about the evolving audits and regulations, impacts and risks and legal aspects in the ESG space. <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=398&p=37227&caturl=aHR0cHM6Ly9pbi5hY2NlbnR1cmUuY29tL3N1c3RhaW5hYmlsaXR5L25ld3Mv" target={"_blank"}>Download the invite now</a>.</p>
                        </div>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Can't miss: Trends & Technology</h4>
                    <div className="midsection_li">
                        <div className="trends-sec-row">
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewAug/icon1.png"} alt="" />
                                <p>Here’s a newer, greener way to produce drugs! Not only does it lower the carbon footprint, it also doesn’t use water in the manufacturing process. | <a className="mid_section_a" href="https://phys.org/news/2023-08-approach-hydrogen-combined-electricity-pharmaceutical.html" target={"_blank"}>Read more</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewAug/icon2.png"} alt="" />
                                <p>Say hello to Sustainable or ClimateTech—tech that either offsets carbonisation or is produced using green materials or processes | <a className="mid_section_a" href="https://cionews.co.in/trending-technologies-to-change-our-world/" target={"_blank"}>Explore</a></p>
                            </div>     
                        </div>
                        <div className="vertical-line-may"></div>
                        <div className="trends-sec-row">
                        <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewAug/icon3.png"} alt="" />
                                <p>How are IoT, AI and blockchain transforming the food packaging industry, increasing both profitability and sustainability. | <a className="mid_section_a" href="https://www.packaging-gateway.com/features/tackling-food-packaging-challenges-with-new-technologies/" target={"_blank"}>Explore</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewAug/icon4.png"} alt="" />
                                <p>How can companies adopt circular practices  to achieve sustainable growth and customer engagement? | <a className="mid_section_a" href="https://www.forbesindia.com/article/insead/sustainable-growth-and-customer-engagement-in-a-circular-economy/87651/1" target={"_blank"}>Know more</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">In-depth</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>AI has become essential. But how can we ensure that it is sustainable, too? Here are five approaches to consider. | <a className="mid_section_a" href="https://www.gartner.com/en/articles/keep-ai-from-doing-more-climate-harm-than-good" target={"_blank"}>Learn more</a></li>
                            <li>Explore the top 10 global sustainability trends for a more sustainable future. | <a className="mid_section_a" href="https://www.greenmatch.co.uk/blog/sustainability-trends" target={"_blank"}>Know more</a></li>
                            <li>Three ways in which AI is being used to boost environmental sustainability. | <a className="mid_section_a" href="https://sustainabilitymag.com/articles/google-using-ai-machine-learning-to-boost-sustainability" target={"_blank"}>Read more</a></li>
                            <li>Top three reasons to adopt circular economy for driving sustainability. | <a className="mid_section_a" href="https://earthshotprize.org/dumping-the-take-make-waste-mindset-3-reasons-to-embrace-a-circular-economy/" target='_blank'>Explore</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Must-reads…</h4>
                    <div className="midsection_li_june">
                        <ul>
                            <li>What is Green Hydrogen and how is India becoming a front runner for producing it? | <a className="mid_section_a" href="https://www.reuters.com/sustainability/climate-energy/india-govt-sets-emission-limit-hydrogen-qualify-green-2023-08-19/" target={"_blank"}>Know more</a></li>
                            <li>How are governments across the globe scaling up the EV adoption for decarbonization and sustainability? | <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/08/charging-ahead-how-to-unlock-electric-vehicle-revolution/" target={"_blank"}>Learn more</a></li>
                            <li>See how used cooking oils can be refined into renewable fuels and turned into sustainable, bio-based plastics. | <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/08/used-cooking-oils-circular-economy/" target={"_blank"}>Read on</a></li>
                            <li>What can the world do about the global water crisis? Some innovative solutions from the World Water Week. | <a className="mid_section_a" href="https://news.un.org/en/story/2023/08/1139942" target={"_blank"}>Know more</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    {/* <hr /> */}
                </div>

                {/* <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Reports and Magazines</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>5 charts from the IPCC, Intergovernmental Panel on Climate Change report highlights risks of lower temperatures due to climate change <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/03/climate-change-ipcc-emissions-risks-net-zero/" target={"_blank"}>Read more...</a></li>
                            <li>What are nature climate solutions and how it can help to reduce climate change and bio-diversity loss. <a className="mid_section_a" href="https://www3.weforum.org/docs/WEF_NCSA_NCS_for_Corporates_2021.pdf" target={"_blank"}>Read more...</a></li>
                            <li>Understanding progress in water related ecosystem, SDGs and global data availability. <a className="mid_section_a" href="https://www.unep.org/resources/report/measuring-progress-water-related-ecosystems-and-sdgs" target={"_blank"}>Read more...</a></li>
                            <li>A study on non-native plants and its rapid growth highlights need for monitoring schemes to understand long term impact. <a className="mid_section_a" href="https://www.nature.com/articles/d44151-023-00034-y" target={"_blank"}>Read more...</a></li>
                            <li>Only second ever UN conference on water saw host of countries discuss water disasters related to climate change. <a className="mid_section_a" href="https://www.theguardian.com/environment/2023/mar/23/water-un-conference-climate-crisis-disasters" target={"_blank"}>Read more...</a></li>
                        </ul>
                    </div>
                </div> */}

                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Soulful Living!</h4>
                    <div className="midsec_box">
                        <div className="midsection_para_aug">
                            {/* <p><strong>Innovative use of AI to Assess Potholes issues on City Roads by a Bengaluru startup</strong></p> */}
                            <p className="mid_section_p">This low-cost Hydroponics farm model in Pune is changing the game for Indian farmers. Till date, this innovation has helped thousands of farmers reduce up to 99% of their water use in producing green fodder, making way for sustainable farming practices. | <a className="mid_section_a" href="https://www.thebetterindia.com/327478/ashwin-sawant-low-cost-hydroponics-model-helps-farmers-grow-fodder-save-water/" target="_blank">Know more about the inspiring initiative</a>.</p>
                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + "/images/Home/Sustainability_NewsSept/soulful_img_sept.png"} alt="sust_news" className="sust_news_img" />
                        </div>
                    </div>

                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>

                <div className="sustainability_mid_section_july">
                    <h4 className="midsection_h4">Did You Know</h4>
                    <div className="lastsection_march">
                        <div className="lastsection_march_1stCard">
                            <h4 className="lastsection_march_h4">931</h4>
                            {/* <p className="diduknow_p">Earth's surface mainly consists of 3 things: hydrosphere, biosphere & atmosphere.</p> */}
                            <p className="diduknow_p_sept">Globally, 17% of total food available to consumers is wasted. That’s nearly 931 million metric tons of potential meals. | <a className="mid_section_a" href="https://www.euronews.com/next/2023/06/21/how-changing-your-dinner-menu-will-help-save-the-planet" target={"_blank"}>Read on</a></p>
                        </div>
                        <div className="lastsection_march_2ndCard">
                            <h4 className="lastsection_march_h4">60%</h4>
                            <p className="diduknow_p_sept">Land and ocean ecosystems absorb 60% of human-caused emissions and store massive amounts of carbon dioxide on the planet. | <a className="mid_section_a" href="https://unfoundation.org/blog/post/biodiversity-explained-facts-myths-and-the-race-to-protect-it/" target={"_blank"}>Know more</a></p>
                        </div>
                        <div className="lastsection_march_3rdCard">
                            <h4 className="lastsection_march_h4">80%</h4>
                            <p className="diduknow_p_sept">Indigenous people manage more than 20% of the planet’s land and 80% of its biodiversity. | <a className="mid_section_a" href="https://www.un.org/development/desa/dspd/2021/04/indigenous-peoples-sustainability/" target={"_blank"}>Learn more</a></p>
                        </div>
                    </div>
                </div>

                <div className="sustainability_hr_div">
                    <hr />
                </div>

                <div className="sustainability_quiz_section">
                <h4 className="midsection_h4">The Green Challenge: Sustainability Quiz</h4>
                <p className="quiz_para">Think you have got what it takes to be a sustainability superstar? Fun quiz to find out.</p>
                <div className="quiz_section">
                <a className="quiz_link" href="https://www.menti.com/alveuhq7yp2w/0" target="_blank">
                    Click here to start the quiz
                </a>
                </div>
                </div>

                <div className="sustainability_hr_div_quiz">
                    <hr />
                </div>

                <div className="priority-learnings-container_july">
                    <h4 className="midsection_h4">Have you checked out the Sustainability Priority Learnings, yet?</h4>
                    <div className="priority-learnings-wrap">
                        <div className="priority-learnings-row">
                            <div className="priority-learnings-box">
                                <p>Course 1: <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/2020982" target="_blank">Green Software for Practitioners</a></p>
                                <p>|</p>
                                <p>Course 2: <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/1795845" target="_blank">Accenture Green Software</a></p>
                            </div>
                            <p>Here's a <a href="https://mediaexchange.accenture.com/media/t/1_n0hrgtw9?st=0" target="_blank">step-by-step video guide</a> on how to access Sustainability Priority Learnings.</p>
                            <p>Have queries? Check out the <a href="https://collections.accenture.com/FileViewer.aspx?f=4e9fb50d-00bd-4124-9212-33cdb7495382">FAQs here</a>.</p>
                        </div>
                    </div>
                </div>

                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <Feedback subject={"Sustainability News From The World - September 2023 Edition"} />
            </div>
        </>
    )
}

export default SustainabilityNewsSept;