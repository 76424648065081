import React from "react";
import "./PondMan.css";

const PondMan = () => {
  return (
    <>
      <div className="main-banner">
        <h1>Sustainable Business Entrepreneurship Series</h1>
        <img
          src="images/Home/Sustainability_News/sustainability_news_banner.png"
          alt="banner"
          className="banner-img"
        />
      </div>
      <div className="back-home">
        <p>
          <a href="/home">Home</a>
        </p>
      </div>
      <div className="organicfarms-container">
        <div className="organicfarms-wrap">
          <p>
            The{" "}
            <span className="text-highlighter">
              Sustainable Business Entrepreneurship Series
            </span>{" "}
            brings to you inspirational stories and perspectives from people who
            envision the future, go ahead to create it, and make an impact. Each
            of these sessions has something we can take away and apply; also
            benefit from the thoughtfulness and knowledge of the speakers. The
            diverse topics cut across the{" "}
            <a
              href="https://www.undp.org/sustainable-development-goals?utm_source=EN&utm_medium=GSR&utm_content=US_UNDP_PaidSearch_Brand_English&utm_campaign=CENTRAL&c_src=CENTRAL&c_src2=GSR&gclid=Cj0KCQiA8t2eBhDeARIsAAVEga0wn2wYvtbbhI1H-HofbJHtWyCLUUgwnOq1qX3VAI7OIo9VJuKnzgsaAo5aEALw_wcB"
              target="_blank"
            >
              17 UN SDGs
            </a>
            , and we do hope they would open up your minds to new thoughts and
            possibilities!{" "}
          </p>
          <p>
            Do you have comments or suggestions? We would love to hear at{" "}
            <a href="mailto:ATCI.Sust.Research@accenture.com">
              {" "}
              ATCI.Sust.Research@accenture.com
            </a>
          </p>
          <p>
            Regards, <br />
            <span className="text-highlighter">Shridhar Rajgopalan,</span> Lead
            - Sustainability and Song, ATCI <br />
            <span className="text-highlighter">Anupama Nithyanand,</span> Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI
          </p>
          <h2>SUSTAINABLE BUSINESS ENTREPRENEURSHIP SERIES</h2>
          <div className="pondman_content_div">
            {/* <p>
              Climate change is no longer a future possibility. It is real and
              now, manifesting in myriad ways, as we witness and experience it
              in our daily lives. It clearly warrants action from everyone, in
              every way we can. While every responsible and conscious earth
              citizen acts in their own ways to alleviate the effects, some make
              it their life's purpose!
            </p> */}
            {/* <p className="pondman_para">
              We are extremely pleased to announce an insightful and exciting
              talk, "
              <span className="afforestation_span">
                Afforestation and Environmental Sustainability
              </span>
              " by{" "}
              <span className="afforestation_span">
                Apurva Bhandari, CEO and Founder of pondman Foundation,
              </span>{" "}
              on <b>26-May-2023, from 9:30 am to 10:45 am.</b>
            </p>
            <a
              className="meeting_link"
              href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmM2YWE0ZGUtY2RmOC00ZmQyLTlhNzgtYmJkNGNiYzY2YmM2%40thread.v2/0?context=%7b%22Tid%22%3a%22e0793d39-0939-496d-b129-198edd916feb%22%2c%22Oid%22%3a%22158c819c-1d1c-45b8-866f-212bba66c8a4%22%7d"
              target="_blank"
            >
              Click here to join the meeting
            </a> */}
          </div>
          <div className="pondman_wrap">
            <div className="pondman-row row1">
              <div className="pondman-box">
                <img
                  src="images/Home/Entrepreneur/card3.png"
                  alt="card"
                  className="brother-card-img"
                />
              </div>
            </div>
            <div className="pondman-row row2">
              <div className="pondman-box">
                <h3 className="pondman-box_header">
                  Introducing Ramveer Tanwar (PondMan), Founder - Say Earth NGO:
                </h3>
                <p className="pondman_mid_para">
                  Mr. Ramveer Tanwar (Pond Man), who is a much-celebrated
                  Environmentalist and TEDx Speaker, based in Greater Noida has
                  been passionate about water conservation since his student
                  life. He started his campaign called Jal Chaupal in Gautam
                  Budhha Nagar, Uttar Pradesh; urged people to conserve water
                  and to save natural resources like ponds, lakes & Wetlands. He
                  got trained by The Central Pollution Control Board (CPCB),
                  MOEF, and Govt. of India. Presently Mr. Tanwar is the founder
                  of Say Earth NGO.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="talk-container">
          {/* <div className="talk-wrap">
            <h3>During his talk, Ramveer will delve into the following:</h3>
            <div className="talk-row row1">
              <div className="talk-box box1">
                <ul className="talk-lists">
                  <li className="talk_li">Ramveer introduction and coverage</li>
                  <li>
                    How technology can play a crucial role in creating social
                    impact
                  </li>
                </ul>
              </div>
            </div>
            <div className="talk-row row2">
              <div className="talk-box box2">
                <ul className="talk-lists">
                  <li className="talk_li">
                    How to build sustainable social enterprises
                  </li>
                  <li>Importance of sustainable lifestyle practices</li>
                </ul>
              </div>
            </div>
          </div> */}
          <p className="pondman_last_apara">
            Join us to hear from and interact with this Environmentalist and
            TEDx Speaker. We eagerly await your presence for this conversation.
          </p>
        </div>
      </div>
    </>
  );
};

export default PondMan;
