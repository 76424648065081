import React, { useEffect } from "react";
import "./SustainabilityNewsJune.css";
import "./SustainabilityNewsFeb.css";
import "./SustainabilityNewsJuly.css";
import Feedback from '../function-components/Feedback';
import { Link } from 'react-router-dom';

const SustainabilityNewsJuly = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div className="sustainability_main">
                <div className="sustainability_header">
                    <h2>Sustainability News From the World - July 2023 Edition</h2>
                </div>
                <div className='banner-container'>
                    <div className='home-banner'   >
                        <img src={process.env.PUBLIC_URL + "/images/Home/Sustainability_News/sustainability_news_banner.png"} className="sust_news_gif_img" />
                    </div>
                </div>
                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <div className="sustainability_mid_container">
                    <h2>LEADING WITH SUSTAINABILITY: TRANSLATING INTENT INTO ACTION</h2>
                    <p>As we continue to work toward our goal of translating sustainability from intent to action, we want to thank you for helping us shape the sustainability agenda for ourselves and our clients to deliver value.</p><br />
                    <p>It is indeed heartening to see such enthusiasm to bring sustainability into the heart of all conversations. Organizations around the world are stepping up to meet the sustainability challenge. The <b>ATCI Sustainability Research Cell</b> brings you the latest updates covering technology, industry, clients, Accenture, and the larger ecosystem. Hope you enjoy reading it and find inspiring ideas that you can apply to your ecosystem.</p>
                    <p className="sustainability_para">You can also <a className="sustainability_para_a" href="mailto:ATCI.Sust.Research@accenture.com?subject=ATCI Sustainability Research">share your ideas and suggestions</a> with us. We would love to hear more about your “sustainability stories” and feature them in our newsletter.</p><br />
                    <p>Let’s keep creating incredible conversations.</p>
                    <p className="sustainability_para">Regards,</p>
                    <p><strong>Shridhar Rajgopalan</strong>, Lead - Sustainability and Song, ATCI</p>
                    <p><strong>Anupama Nithyanand</strong>, Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI</p>
                </div>
                <div className="sustainability_li_container">
                    <h3>SUSTAINABILITY NEWS FROM THE WORLD - JULY 2023 EDITION</h3>
                    <div className="sustainability_wrap">
                        <ul>
                            <li>What's making news?</li>
                            <li>Can't miss: Trends & Technology</li>
                            <li>In-depth</li>
                            <li>Must-reads…</li>
                            <li>Soulful living!</li>
                            <li>Did you know</li>
                            <li>Sustainability Priority Learnings</li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_highlight_div">
                    <h4>What's making news?</h4>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon12.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Accelerating sustainable transformation – Dispelling old myths to seize new value</h4>
                            <p>Three imperatives that can help leaders accelerate sustainable transformation while generating new sources of profitable growth. Explore the latest <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=282&p=36910" target={"_blank"}>Accenture report</a>.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon13.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Helping organizations scale advances in generative AI</h4>
                            <p>Accenture and Google Cloud will co-develop new solutions that can help organizations use generative AI to create new opportunities to drive next-level innovation, optimization and reinvention. This includes improving business processes  with a strategic focus on sales and marketing, supply chain, healthcare, financial services, sustainability and more. <a className="hightlight_b" href="https://newsroom.accenture.com/news/accenture-and-google-cloud-help-organizations-scale-advances-in-generative-ai.htm" target={"_blank"}>Read on</a>.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon14.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Circular economy and the future of packaging POV</h4>
                            <p>Packaging is the biggest contributor to plastic pollution responsible for nearly 40% of global plastic demand. How can companies reimagine their business models to embrace viable solutions? Explore our <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=282&p=36874" target={"_blank"}>latest report</a>.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon6.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">New Solution Tablets alert!</h4>
                            <p>We’re on a roll this July…with <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=282&p=36865" target={"_blank"}>five new Solution Tablets</a> to help you deliver value to our clients by building sustainable strategies, value chains, technology and more.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon7.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">See how we are delivering 360-degree value for our clients with sustainability consulting</h4>
                            <p><a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=282&p=36885" target={"_blank"}>Explore</a> how we are helping a multinational energy network company to develop its sustainability strategy as it prepares for new reporting requirements.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon15.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Toward a sustainable future</h4>
                            <p>Find out <a className="hightlight_b" href="https://in.accenture.com/digitalworker/?site=1&blog=965&p=21174" target={"_blank"}>four simple actions</a> you can take today to slash your carbon footprint and step towards a more sustainable future.</p>
                        </div>
                    </div>
            
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Can't miss: Trends & Technology</h4>
                    <div className="midsection_li">
                        <div className="trends-sec-row">
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon8.png"} alt="" />
                                <p>Role of technology in making the business more sustainable. | <a className="mid_section_a" href="https://www.ft.com/content/9cfc3e01-6bc3-4aa7-8fac-c0f6c6fd7e2b" target={"_blank"}>Explore</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon9.png"} alt="" />
                                <p>Globally, 1.3 billion tonnes of food production is wasted each year. This could amount to losses up to $1.5 trillion by 2030. How can food companies use technology to change this? | <a className="mid_section_a" href="https://restauranttechnologynews.com/2023/06/reducing-food-waste-how-restaurants-can-use-technology-to-implement-sustainable-practices/" target={"_blank"}>Know more</a></p>
                            </div>     
                        </div>
                        <div className="vertical-line-may"></div>
                        <div className="trends-sec-row">
                        <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon10.png"} alt="" />
                                <p>Cloud Native Computing Foundation talks about building ecologically sustainable software. | <a className="mid_section_a" href="https://www.forbes.com/sites/adrianbridgwater/2023/04/20/cncf-sustainable-software-projects-breed-eco-sustainable-computing-systems/?sh=60346fa44de2" target={"_blank"}>Read on</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon11.png"} alt="" />
                                <p>Automation, augmentation and acceleration—three ways in which generative AI is emerging as a game-changer in fashion design, production, and marketing. | <a className="mid_section_a" href="https://analyticsindiamag.com/unleashing-creativity-ai-and-the-future-of-fashion/" target={"_blank"}>Explore the use cases</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">In-depth</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>It’s not just for compliance. Companies must develop new business models with sustainability at their core. | <a className="mid_section_a" href="https://www.consultancy.eu/news/8857/push-for-sustainability-must-go-beyond-just-compliance" target={"_blank"}>Know more</a></li>
                            <li>EVs emit zero tailpipe emissions, but they are still a long way from net-zero mobility. | <a className="mid_section_a" href="https://sustainabilitymag.com/articles/the-misconceptions-of-electric-vehicle-sustainability" target={"_blank"}>Know more</a></li>
                            <li>What are the mental health consequences of climate change and how can that lead to transformative social innovation? | <a className="mid_section_a" href="https://ssir.org/articles/entry/learning_from_the_climate_mental_health_convergence" target={"_blank"}>Find out</a></li>
                            <li>Climate change could turn “man’s best friend” against us, a new research warns. | <a className="mid_section_a" href="https://www.euronews.com/green/2023/06/19/dogs-could-become-more-hostile-to-humans-as-the-planet-heats-up" target='_blank'>Know more</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Must-reads…</h4>
                    <div className="midsection_li_june">
                        <ul>
                            <li>A solar-powered reactor that converts captured CO2 and plastic waste into clean, sustainable fuels. | <a className="mid_section_a" href="https://energy.economictimes.indiatimes.com/news/renewable/clean-sustainable-fuels-created-from-thin-air-and-plastic-trash/101125405" target={"_blank"}>Learn more</a></li>
                            <li>Top five governance risks that  businesses face today and the opportunities they present for change to build a more sustainable future. | <a className="mid_section_a" href="https://sustainabilitymag.com/articles/governance-5-risks-and-opportunities-facing-businesses" target={"_blank"}>Explore</a></li>
                            <li>Which are the top three impacts of climate change on supply chain and actions that companies can take to adapt and grow? | <a className="mid_section_a" href="https://www.gartner.com/en/articles/3-key-actions-for-supply-chain-s-response-to-climate-change" target={"_blank"}>Find out</a></li>
                            <li>Which industries are most impacted by generative AI and what are the sustainability opportunities it presents. | <a className="mid_section_a" href="https://www.gartner.com/en/topics/generative-ai" target={"_blank"}>Gartner report</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    {/* <hr /> */}
                </div>

                {/* <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Reports and Magazines</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>5 charts from the IPCC, Intergovernmental Panel on Climate Change report highlights risks of lower temperatures due to climate change <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/03/climate-change-ipcc-emissions-risks-net-zero/" target={"_blank"}>Read more...</a></li>
                            <li>What are nature climate solutions and how it can help to reduce climate change and bio-diversity loss. <a className="mid_section_a" href="https://www3.weforum.org/docs/WEF_NCSA_NCS_for_Corporates_2021.pdf" target={"_blank"}>Read more...</a></li>
                            <li>Understanding progress in water related ecosystem, SDGs and global data availability. <a className="mid_section_a" href="https://www.unep.org/resources/report/measuring-progress-water-related-ecosystems-and-sdgs" target={"_blank"}>Read more...</a></li>
                            <li>A study on non-native plants and its rapid growth highlights need for monitoring schemes to understand long term impact. <a className="mid_section_a" href="https://www.nature.com/articles/d44151-023-00034-y" target={"_blank"}>Read more...</a></li>
                            <li>Only second ever UN conference on water saw host of countries discuss water disasters related to climate change. <a className="mid_section_a" href="https://www.theguardian.com/environment/2023/mar/23/water-un-conference-climate-crisis-disasters" target={"_blank"}>Read more...</a></li>
                        </ul>
                    </div>
                </div> */}

                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Soulful Living!</h4>
                    <div className="midsec_box">
                        <div className="midsection_para">
                            {/* <p><strong>Innovative use of AI to Assess Potholes issues on City Roads by a Bengaluru startup</strong></p> */}
                            <p className="mid_section_p">This women-led start up is diverting food scraps from landfills with an aim to reduce methane emissions and enrich the soil. | <a className="mid_section_a" href="https://emirateswoman.com/how-this-women-led-startup-became-a-sustainability-trailblazer-in-dubai/" target="_blank">Learn more about this nature-based composting start-up</a>.</p>
                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewJuly/ppl_july_new.png"} alt="sust_news" className="sust_news_img" />
                        </div>
                    </div>

                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>

                <div className="sustainability_mid_section_july">
                    <h4 className="midsection_h4">Did You Know</h4>
                    <div className="lastsection_march">
                        <div className="lastsection_march_1stCard">
                            <h4 className="lastsection_march_h4">11%</h4>
                            {/* <p className="diduknow_p">Earth's surface mainly consists of 3 things: hydrosphere, biosphere & atmosphere.</p> */}
                            <p className="diduknow_p">Deforestation contributes to 11 percent of GHG emissions and is one of the most harmful human activities.  | <a className="mid_section_a" href="https://8billiontrees.com/trees/facts-about-the-environment/" target={"_blank"}>Know more</a></p>
                        </div>
                        <div className="lastsection_march_2ndCard">
                            <h4 className="lastsection_march_h4">30%</h4>
                            <p className="diduknow_p">Oceans help in big way by absorbing and storing around 30 percent of global CO2 emissions. | <a className="mid_section_a" href="https://8billiontrees.com/trees/facts-about-the-environment/" target={"_blank"}>Know more</a></p>
                        </div>
                        <div className="lastsection_march_3rdCard">
                            <h4 className="lastsection_march_h4">10</h4>
                            <p className="diduknow_p">Organic farming requires 2.5 times more labor than conventional farming, but it yields 10 times the profit. | <a className="mid_section_a" href="https://www.dosomething.org/us/facts/11-facts-about-sustainable-agriculture" target={"_blank"}>Know more</a></p>
                        </div>
                    </div>
                </div>

                <div className="sustainability_hr_div">
                    <hr />
                </div>

                <div className="sustainability_quiz_section">
                <h4 className="midsection_h4">The Green Challenge: Sustainability Quiz</h4>
                <p className="quiz_para">Think you have got what it takes to be a sustainability superstar? Take the fun quiz to find out.</p>
                <div className="quiz_section">
                <a className="quiz_link" href="https://www.menti.com/alx3v9a2idgg/0" target="_blank">
                    Click here to start the quiz
                </a>
                </div>
                </div>

                <div className="sustainability_hr_div_quiz">
                    <hr />
                </div>

                <div className="priority-learnings-container_july">
                    <h4 className="midsection_h4">Have you checked out the Sustainability Priority Learnings, yet?</h4>
                    <div className="priority-learnings-wrap">
                        <div className="priority-learnings-row">
                            <div className="priority-learnings-box">
                                <p>Course 1: <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/2020982" target="_blank">Green Software for Practitioners</a></p>
                                <p>|</p>
                                <p>Course 2: <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/1795845" target="_blank">Accenture Green Software</a></p>
                            </div>
                            <p>Here's a <a href="https://mediaexchange.accenture.com/media/t/1_n0hrgtw9?st=0" target="_blank">step-by-step video guide</a> on how to access Sustainability Priority Learnings.</p>
                            <p>Have queries? Check out the <a href="https://collections.accenture.com/FileViewer.aspx?f=4e9fb50d-00bd-4124-9212-33cdb7495382">FAQs here</a>.</p>
                        </div>
                    </div>
                </div>

                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <Feedback subject={"Sustainability News From The World - July 2023 Edition"} />

            </div>
        </>
    )
}

export default SustainabilityNewsJuly;