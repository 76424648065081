import React from 'react';
import './Organicfarms.css';

function Organicfarms() {
    return (
        <>
            <div className="main-banner">
                <h1>Sustainable Business Entrepreneurship Series</h1>
                <img src="images/Home/Sustainability_News/sustainability_news_banner.png" alt="banner" className='banner-img' />
            </div>
            <div className="back-home">
                <p><a href="/home">Home</a></p>
            </div>
            <div className="organicfarms-container">
                <div className="organicfarms-wrap">
                    <p>The <span className='text-highlighter'>Sustainable Business Entrepreneurship Series</span> brings to you inspirational stories and perspectives from people who envision the future, go ahead to create it, and make an impact. Each of these sessions has something we can take away and apply; also benefit from the thoughtfulness and knowledge of the speakers. The diverse topics cut across the <a href="https://www.undp.org/sustainable-development-goals?utm_source=EN&utm_medium=GSR&utm_content=US_UNDP_PaidSearch_Brand_English&utm_campaign=CENTRAL&c_src=CENTRAL&c_src2=GSR&gclid=Cj0KCQiA8t2eBhDeARIsAAVEga0wn2wYvtbbhI1H-HofbJHtWyCLUUgwnOq1qX3VAI7OIo9VJuKnzgsaAo5aEALw_wcB" target="_blank">17 UN SDGs</a>, and we do hope they would open up your minds to new thoughts and possibilities! </p>
                    <p>Do you have comments or suggestions? We would love to hear at <a href="mailto:ATCI.Sust.Research@accenture.com"> ATCI.Sust.Research@accenture.com</a></p>
                    <p>
                        Regards, <br />
                        <span className='text-highlighter'>Shridhar Rajgopalan,</span> Lead - Sustainability and Song, ATCI <br />
                        <span className='text-highlighter'>Anupama Nithyanand,</span> Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI
                    </p>
                    <h2>SUSTAINABLE BUSINESS ENTREPRENEURSHIP SERIES</h2>
                    <h3>How about: </h3>
                    <ul className='about-list'>
                        <li>A virtual tour of a farm to experience our connection to the larger ecology, a world that is not centered around the human species?</li>
                        <li>To virtually visit and study a community-driven business that prioritizes preserving nature, ancient farm practices, and traditional food for future generations to know that food doesn't come from packets and supermarkets but something that Mother Nature lets us grow, harvest and become one with?</li>
                    </ul>
                    <div className="organicfarms-row row1">
                        <div className="organicfarms-box">
                            <img src="images/Home/Entrepreneur/brother1.png" alt="card" className='brother-card-img' />
                        </div>
                    </div>
                    <div className="organicfarms-row row2">
                        <div className="organicfarms-box">
                            <p>All in an exciting conversation with <span className='text-highlighter'>Satyajit Hange</span>, an <span className='text-highlighter'>Agripreneur</span>, who co-founded <span className='text-highlighter'>Two Brothers Organic Farms</span> (author profile attached) and made it a profitable business that benefitted the larger ecosystem. </p>
                            <p>The objective of this conversation is to enable Food-Farming-and-Future conversations with Farming Enthusiasts, Conscious Consumers, Families & Entrepreneurs; and to dwell on how <span className='text-highlighter'>Sustainable Regenerative Farming</span> be a promising and lucrative career choice for young India.</p>
                        </div>
                    </div>
                </div>
                <div className="talk-container">
                    <div className="talk-wrap">
                        <h3>The talk will broadly cover the following points:</h3>
                        <div className="talk-row row1">
                            <div className="talk-box box1">
                                <ul className='talk-lists'>
                                    <li>Two Brothers Organic Farms - The journey so far, Why Farming? </li>
                                    <li>About Entrepreneurship - Can sustainable farming be lucrative? How did we build a business and a brand while staying true to our values? </li>
                                </ul>
                            </div>
                        </div>
                        <div className="talk-row row2">
                            <div className="talk-box box2">
                                <ul className='talk-lists'>
                                    <li>Key learnings - Inner voice, no plan B, staying the course</li>
                                    <li>Virtual tour of the Two Brothers organic farms </li>
                                    <li>Q&A</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <p>Session completed on <span className='text-highlighter'>Friday, 10-Feb-2023</span></p>
                </div>
                <div className="about-brother-wrap">
                    <h3>About Two Brothers Organic Farms:</h3>
                    <div className="about-brother-row row1">
                        <div className="about-brother-box box1">
                            <p>Two Brothers Organic Farms has played host to people from diverse backgrounds. Agripreneurs selflessly share the trials, tribulations and journey from the socially acceptable corporate sector to the noble cause of Regenerative Organic Farming. Curious Children want to know where and how food grows, Concerned Parents about the origin and farmers of their family's food, Farmer Co-operative Leaders who want to learn about the new age marketing technologies, Doctors who want to be assured that it's ok to switch profession towards farming and Farmers who aspire to profitably practice organic farming, whom the Two Brothers are happy to serve and guide.</p>
                        </div>
                    </div>
                    <div className="about-brother-row row2">
                        <div className="about-brother-box box2">
                            <img src="images/Home/Entrepreneur/brother2.png" alt="card" className='brother-card-img' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Organicfarms