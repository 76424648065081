import React,{useEffect} from 'react'
import CoreTeam from './CoreTeam';
import HanggingMenu from './HanggingMenu';
import './HomeContent.css';
import MonthlyRoundup from './MonthlyRoundup';
import Entrepreneurship from './Entrepreneurship';
import WhitepaperandArtical from './WhitepaperandArtical';
import SustainabilityReels from './SustainabilityReels';
import Publications from './Publications';
import Navbarmenu from './Navbarmenu';
import Weeklybits from './Weeklybites';

function HomeContent() {

    return (
        <>
        <Navbarmenu/>
            <div className='main-container'>
                <div className='banner-container'>
                    <video className='home-banner'  autoplay="autoplay" loop muted >
                    <source src="images/Home/Sustainability_News/SR-Banner-New.mp4" type="video/mp4" className="sust_news_gif_img" />
                    </video>
                </div>
                <div className='sub-container'>
                    {/* <div className='aboutus-container'>
                        <div className='aboutus-img-wrapper'>
                            <img className='aboutus-img' src='images/Home/aboutus-img.jpg' alt='aboutUs-Image' />
                        </div>
                        <div className='aboutus-content'>
                            <h2>Overview</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde est quaerat animi, illum sed aliquam dolore nihil nobis reiciendis asperiores saepe aliquid reprehenderit consequuntur rem! Temporibus excepturi illo dolor eligendi sequi! Fuga reiciendis nisi, incidunt obcaecati iusto mollitia ex molestias cupiditate laudantium eveniet adipisci nostrum voluptates at, culpa nobis ad? </p>
                        </div>
                    </div> */}
                    <div className='banner-container2'>
                    <video className='home-banner'  autoplay="autoplay" loop muted >
                    <source src="images/Home/aboutus-new-banner.mp4" type="video/mp4" className="sust_news_gif_img" />
                    </video>
                </div>
                    <HanggingMenu />
                    <div className='leader-container'>
                        <div className='leader-wrapper'>
                            <h2><span>Our Leads</span></h2>
                            <div className='leader-content-row'>
                                <div className='leader-content-box'>
                                    <div className='leader-details'>
                                        <img className='leader-img' src='images/Home/leader1.png' alt='Leader-image' />
                                        <div className='leader-designation'>
                                            <h3>Shridhar Rajgopalan</h3>
                                            <p>Lead - Sustainability and Song, ATCI</p>
                                        </div>
                                    </div>
                                    {/* <img src='images/Home/left-quote.png' alt='Left-quote'/>
                                    <p className='leader-message'>
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugiat atque enim quibusdam neque modi nemo, quas soluta, provident quis, ratione in nihil dignissimos eaque? At rem molestiae quas molestias maxime?
                                        </p>
                                        <img className='right-quote' src='images/Home/right-quote.png' alt='right-quote'/> */}
                                </div>
                                <div className='leader-content-box1'>
                                    <div className='leader-details'>
                                        <img className='leader-img' src='images/Home/ambrish.png' alt='Leader-image' />
                                        <div className='leader-designation'>
                                            <h3>Ambrish Rastogi</h3>
                                            <p>Lead-L&TT, Leadership Transformation, ATCI</p>
                                        </div>
                                    </div>
                                    {/* <img src='images/Home/left-quote.png' alt='Left-quote'/>
                                    <p className='leader-message'>
                                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugiat atque enim quibusdam neque modi nemo, quas soluta, provident quis, ratione in nihil dignissimos eaque? At rem molestiae quas molestias maxime?
                                    </p>
                                    <img className='right-quote' src='images/Home/right-quote.png' alt='right-quote'/> */}
                                </div>
                            </div>
                        </div>
                        <img src="images/Home/bg1.png" alt="" className='bg1'/>
                        <img src="images/Home/bg2.png" alt="" className='bg2'/>
                    </div>
                </div>
                {/* <div id = 'sustainabilitybites'><Weeklybits/></div> */}
                <div id ='sustainabilitynews' className='container_div'><MonthlyRoundup /></div>
                <div id ='entrepreneurshipseries'className='container_div'><Entrepreneurship /></div>
                <div id ='publications' className='container_div'><Publications /></div>
                <div id ='whitepapersandarticles' className='container_div'><WhitepaperandArtical /></div>
                <div id ='sustainabilityreels' className='container_div'><SustainabilityReels /></div>
                <div id ='coreteam'><CoreTeam /></div>
            </div>
        </>
    )
}

export default HomeContent
