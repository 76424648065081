import React, { useEffect } from "react";
import "./SustainabilityNewsFeb.css";
import Feedback from './Feedback';

const SustainabilityNewsFeb = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div className="sustainability_main">
                <div className="sustainability_header">
                    <h2>SUSTAINABILITY NEWS FROM THE WORLD</h2>
                </div>
                <div className='banner-container'>
                    <div className='home-banner'   >
                        <img src={process.env.PUBLIC_URL + "/images/Home/Sustainability_News/sustainability_news_banner.png"} className="sust_news_gif_img" />
                    </div>
                </div>
                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <div className="sustainability_mid_container">
                    <p>It is wonderful to see a world where Sustainability is becoming the heart of most conversations.  There are significant commitments and actions as individuals, organizations, countries, and the world move to translate intentions into actions.   We are pleased to bring you monthly bytes that cover the curated news from the world of technology, industry, governments, clients, Accenture, and our larger ecosystem.  This is coming straight from our <strong><i>ATCI Sustainability Research Cell</i></strong>. Hope you enjoy reading it and find some meaningful “sustainable aha” moments that you can apply to your ecosystem.</p>
                    <p className="sustainability_para">Do you have comments or suggestions? We would love to hear at <a className="sustainability_para_a" href="mailto:ATCI.Sust.Research@accenture.com">ATCI.Sust.Research@accenture.com</a></p>
                    <p className="sustainability_para">Regards,</p>
                    <p><strong>Shridhar Rajgopalan</strong>, Lead - Sustainability and Song, ATCI</p>
                    <p><strong>Anupama Nithyanand</strong>, Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI</p>
                </div>
                <div className="sustainability_li_container">
                    <h3>SUSTAINABILITY NEWS FROM THE WORLD – FEB 2023 EDITION</h3>
                    <div className="sustainability_wrap">
                        <ul>
                            <li>Highlights</li>
                            <li>Trends & Technology</li>
                            <li>Industry Reads</li>
                            <li>Accenture Newsroom</li>
                            <li>Reports & magazines</li>
                            <li>Soulful Living!</li>
                            <li>Did you know</li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_highlight_div">
                    <h4>Highlights</h4>
                    <div>
                        <h4 className="highlight_h4_tag">ESG and Sustainability: Key stories from 2022</h4>
                        <p><i>HBR - Dec 21, 2022</i></p>
                        <p>As we get all charged up and welcome the new year, let’s look at some of the top stories from 2022, and how it brings the focus on the importance of sustainability in business as well as day to day lives of the people. It also reminds us that in 2023, we will continue to see sustainability gaining more ground as it becomes the new normal… </p>
                        <a className="hightlight_a" href="https://hbr.org/2022/12/2022-a-tumultuous-year-in-esg-and-sustainability" target={"_blank"}>Full Article</a>
                    </div>
                    <div>
                        <h4 className="highlight_h4_tag">World Economic Forum meet at Davos</h4>
                        <p><i>Outlook Planet Desk - Jan 16, 2023</i></p>
                        <p>Climate change, technology, decarbonization will be among the widely discussed topics as government functionaries, business and civil society leaders come together for the annual meeting of the WEF…</p>
                        <a className="hightlight_a" href="https://planet.outlookindia.com/news/changing-climate-at-world-economic-forum-news-414737" target={"_blank"}>Full Article</a>
                    </div>
                    <div>
                        <h4 className="highlight_h4_tag">Energy Security and Sustainability</h4>
                        <p><i>World Economic Forum - Jan 12, 2023</i></p>
                        <p>Transitioning into new energy systems and Energy security strategies need to go hand in hand as it complements each other. The World Economic Forum, in collaboration with Accenture, proposes a comprehensive framework which talks about six dimensions that are interconnected and how it can address security challenges to ensure security of energy transition…</p>
                        <a className="hightlight_a" href="https://www.weforum.org/agenda/2023/01/davos23-energy-transition-security-sustainability-whitepaper/" target={"_blank"}>Full Article</a>
                    </div>
                    <div>
                        <h4 className="highlight_h4_tag">Sustainable Development Goals: How far we have reached</h4>
                        <p><i>Mint - Oct 02, 2022</i></p>
                        <p>Taking stock of how the world and India is faring on the SDG goals that they have set for 2030. Considering the covid and other uncertainties that unfolded, Will focusing on specific targets be more effective? the article delves into the progress statistics…</p>
                        <a className="hightlight_a" href="https://www.livemint.com/opinion/online-views/sustainable-development-goals-how-india-and-the-world-are-doing-11664731506364.html" target={"_blank"}>Full Article</a>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Trends & Technology</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>How AI can help in fighting the climate change on a way to achieve environmental sustainability <a className="mid_section_a" href="https://cio.economictimes.indiatimes.com/news/next-gen-technologies/harnessing-the-power-of-ai-to-fight-climate-change-and-achieve-environmental-sustainability/94088221" target={"_blank"} s>Read more...</a></li>
                            <li>Green technology to alleviate sustainability problems as the demand for Web3 technologies rise <a className="mid_section_a" href="https://www.forbes.com/sites/bernardmarr/2023/01/13/why-blockchain-nfts-and-web3-have-a-sustainability-problem/?sh=6a0b7ef05b0b" target={"_blank"}>Read more...</a></li>
                            <li>World Economic Forum Annual meeting - How AI can play important role in meeting the ESG goals <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/01/ai-can-help-meet-esg-goals-and-climate-change/" target={"_blank"}>Read more...</a></li>
                            <li>Can Distributed ledger technologies (DLTs) help bring in accuracy to emissions inventories in sustainability measurement and reporting of carbon footprints <a className="mid_section_a" href="https://www.forbes.com/sites/servicenow/2022/12/28/blockchain-the-trust-network-for-a-sustainable-world/?sh=66f29ad74f82" target={"_blank"}>Read more...</a></li>
                            <li>McKinsey Technology Trends Outlook 2002 – <a className="mid_section_a" href="https://www.mckinsey.com/~/media/mckinsey/business%20functions/mckinsey%20digital/our%20insights/the%20top%20trends%20in%20tech%202022/mckinsey-tech-trends-outlook-2022-next-gen-software.pdf" target={"_blank"}>Next generation Software Development - August 2022</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Industry Reads</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>Top 6 key news on Environmental sustainability as a wrap to 2022  <a className="mid_section_a" href="https://earth.org/positive-environmental-news-2022/" target={"_blank"} s>Read more...</a></li>
                            <li>Global Supply chains - the new EU law to discourage global deforestation -'Zero deforestation products'  <a className="mid_section_a" href="https://earth.org/eu-global-deforestation/" target={"_blank"}>Read more...</a></li>
                            <li>Nature based solutions as a way toward long term climate change strategy <a className="mid_section_a" href="https://earth.org/nature-based-solutions-can-help-tackle-the-climate-crisis/" target={"_blank"}>Read more...</a></li>
                            <li>Sustainable investing - ESG considerations and how to improve ESG reporting standards <a className="mid_section_a" href="https://www.weforum.org/agenda/2021/12/esg-sustainable-investment-backlash/" target={"_blank"}>Read more...</a></li>
                            <li>Regenerative agriculture: is it a viable alternative to traditional farming practices? <a className="mid_section_a" href="https://idronline.org/article/agriculture/sustainable-agriculture-bringing-indian-farmers-on-board/" target={"_blank"}>Read more...</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Accenture Newsroom</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>Creating 3600 Value through Diverse & Inclusive workforce-Accenture Ranked No.1 on Refinitiv Index of World’s Most Diverse and Inclusive Companies <a className="mid_section_a" href="https://newsroom.accenture.com/news/accenture-ranked-no-1-on-refinitiv-index-of-worlds-most-diverse-and-inclusive-companies.htm" target={"_blank"} s>Read more...</a></li>
                            <li>UN Global Compact and Accenture-How CEOs are embracing Sustainability to create long-term resilience <a className="mid_section_a" href="https://newsroom.accenture.com/news/alarmed-by-speed-and-scale-of-disruption-ceos-embrace-sustainability-to-build-long-term-resilience-according-to-un-global-compact-and-accenture.htm" target={"_blank"}>Read more...</a></li>
                            <li>Accenture and Planet Labs collaborate on AI-powered Geospatial Intelligence Tools <a className="mid_section_a" href="https://newsroom.accenture.com/news/accenture-and-planet-to-collaborate-on-ai-powered-geospatial-intelligence-tools-for-sustainability-traceable-supply-chain-and-climate-risk-solutions.htm" target={"_blank"}>Read more...</a></li>
                            <li>Energy efficiency levers, inspired by the B20 <a className="mid_section_a" href="https://www.accenture.com/us-en/blogs/sustainability/energy-efficiency-three-levers-to-consider-inspired-by-the-b20" target={"_blank"}>Read more...</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Reports and Magazines</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>Gartner- 3 Emerging technologies to watch out for on environmental sustainability.  <a className="mid_section_a" href="https://www.gartner.com/en/newsroom/press-releases/2022-04-21-gartner-says-three-emerging-environmental-sustainability-technologies-will-see-early-mainstream-adoption-by-2025" target={"_blank"} s>Read more...</a></li>
                            <li>COP26 Methane Agreement- significance of cutting the methane emissions by 2030 <a className="mid_section_a" href="https://earth.org/data_visualization/why-methane-should-be-our-main-concern/" target={"_blank"}>Read more...</a></li>
                            <li>Mckinsey report-How businesses can help toward sustainable, inclusive future <a className="mid_section_a" href="https://www.mckinsey.com/featured-insights/sustainable-inclusive-growth/toward-a-sustainable-inclusive-growing-future-the-role-of-business?cid=eml-web" target={"_blank"}>Read more...</a></li>
                            <li>2023 - Top 5 Environmental issues in India and how the country is dealing with those.  <a className="mid_section_a" href="https://earth.org/environmental-issues-in-india/" target={"_blank"}>Read more...</a></li>
                            <li>India Energy Outlook 2021 report covering Energy consumption, energy demand, key energy indicators and energy concerns. <a className="mid_section_a" href="https://www.iea.org/reports/india-energy-outlook-2021/energy-in-india-today" target={"_blank"}>Read more...</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Soulful Living!</h4>
                    <div className="midsec_box">
                        <div className="midsection_para">
                            <p>Sustainability can be a way of life, and everyone can contribute toward creating a sustainable future, be it as an individual, communities or businesses. One such inspiring story is about <span className="mid_section_span">Tulasi Gowda</span> who was the recipient of the 2021 Padma Shri award for her contribution toward the protection of the environment.</p>
                            <p className="mid_section_p">Tulasi is known as the <strong>'Encyclopedia of Forest'</strong> because of her immense knowledge about the plants, herbs, and trees. She has planted more than 30,000 saplings in over six decades. <a className="mid_section_a" href="https://www.indiatoday.in/india/story/padma-shri-tulasi-gowda-barefoot-environmentalist-encyclopedia-of-forest-1874499-2021-11-08" target={"_blank"}>Read more…</a></p>
                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + "/images/Home/Sustainability_News/Tulasi_gowda.png"} alt="sust_news" className="sust_news_img" />
                        </div>
                    </div>

                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Did you Know</h4>
                    <div className="lastsection">
                        <div className="lastsection_1stCard">
                            <h4 className="lastsection_h4">1.6 Earths</h4>
                            <p>We use 60% more resources than the earth can regenerate in a year. At this rate, we could need 3-4 earths by 2050. <a className="mid_section_a" href="https://www.weforum.org/agenda/2021/10/7-surprising-facts-to-know-about-the-circular-economy-for-cop26/" target={"_blank"}>Full article</a></p>
                        </div>
                        <div className="lastsection_2ndCard">
                            <h4 className="lastsection_h4">40%</h4>
                            <p>Globally 40% of the food produced goes waste. Amount of food lost on farms, can feed every undernourished person in the world four times over. <a className="mid_section_a" href="https://www.weforum.org/agenda/2022/06/recycling-global-statistics-facts-plastic-paper/" target={"_blank"}>Full article</a></p>
                        </div>
                        <div className="lastsection_3rdCard">
                            <h4 className="lastsection_h4">60%</h4>
                            <p>Over the last 40 years, the world has witnessed 60% decline in wildlife across land, sea, and freshwater. <a className="mid_section_a" href="https://www.weforum.org/agenda/2018/08/here-s-how-technology-can-help-us-save-the-planet/" target={"_blank"}>Full article</a></p>
                        </div>
                    </div>
                </div>
                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <Feedback subject={"Sustainability News From The World"} />
            </div>
        </>
    )
}

export default SustainabilityNewsFeb;