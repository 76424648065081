import React, { useEffect } from "react";
import "./SustainabilityNewsJune.css";
import "./SustainabilityNewsFeb.css";
import Feedback from '../function-components/Feedback';

const SustainabilityNewsJune = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div className="sustainability_main">
                <div className="sustainability_header">
                    <h2>Sustainability News From the World - June 2023 Edition</h2>
                </div>
                <div className='banner-container'>
                    <div className='home-banner'   >
                        <img src={process.env.PUBLIC_URL + "/images/Home/Sustainability_News/sustainability_news_banner.png"} className="sust_news_gif_img" />
                    </div>
                </div>
                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <div className="sustainability_mid_container">
                    <h2>LEADING WITH SUSTAINABILITY: TRANSLATING INTENT INTO ACTION</h2>
                    <p>As we continue to work toward our goal of translating sustainability from intent to action, we want to thank you for helping us shape the sustainability agenda for ourselves and our clients to deliver value.</p><br />
                    <p>It is indeed heartening to see such enthusiasm to bring sustainability into the heart of all conversations. Organizations around the world are stepping up to meet the sustainability challenge. The <b>ATCI Sustainability Research Cell</b> brings you the latest updates covering technology, industry, clients, Accenture, and the larger ecosystem. Hope you enjoy reading it and find inspiring ideas that you can apply to your ecosystem.</p>
                    <p className="sustainability_para">You can also <a className="sustainability_para_a" href="mailto:ATCI.Sust.Research@accenture.com?subject=ATCI Sustainability Research">share your ideas and suggestions</a> with us. We would love to hear more about your “sustainability stories” and feature them in our newsletter.</p><br />
                    <p>Let’s keep creating incredible conversations.</p>
                    <p className="sustainability_para">Regards,</p>
                    <p><strong>Shridhar Rajgopalan</strong>, Lead - Sustainability and Song, ATCI</p>
                    <p><strong>Anupama Nithyanand</strong>, Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI</p>
                </div>
                <div className="sustainability_li_container">
                    <h3>SUSTAINABILITY NEWS FROM THE WORLD - JUNE 2023 EDITION</h3>
                    <div className="sustainability_wrap">
                        <ul>
                            <li>What's making news?</li>
                            <li>Can't miss: Trends & Technology</li>
                            <li>In-depth</li>
                            <li>Must-reads…</li>
                            <li>Soulful living!</li>
                            <li>Did you know</li>
                            <li>Sustainability Priority Learnings</li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_highlight_div">
                    <h4>What's making news?</h4>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon1.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Micro action for macro impact</h4>
                            <p><a className="hightlight_b" href="https://in.accenture.com/corporatecitizenship/eco-action-season-fy23/" target={"_blank"}>Explore</a> how you can respond, commit, volunteer and learn to take micro actions for macro impact during the Eco Action Season 2023—a six-week period dedicated to coming together as One Accenture for the environment. What’s more? Recognition points galore for your efforts.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon2.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Learn how you can differentiate through a responsible business</h4>
                            <p>A responsible business mindset can be a powerful differentiator in our client conversations as well as in how we approach projects. Know how you can ace this with the Responsible Digital Experience playbook and assessment framework with case studies and other resources <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=301&p=9988" target={"_blank"}>here</a>. Bookmark the page now.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon3.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">All that we are doing for environmental sustainability management</h4>
                            <p><a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=398&p=36715" target={"_blank"}>Learn more</a> about Accenture’s environmental sustainability management that’s focused on addressing climate change and how we are scaling solutions to make real, lasting change.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon4.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Innovative solutions for clients seeking resilience amid increased climate risk</h4>
                            <p><a className="hightlight_b" href="https://newsroom.accenture.com/news/accenture-and-cervest-collaborate-to-bring-innovative-solutions-to-clients-seeking-resilience-amid-increased-climate-risk.htm" target={"_blank"}>Accenture has entered into a collaboration agreement with Cervest</a>, an AI-powered climate intelligence (CI) platform, to deliver on-demand access to historical, current and predictive views of combined climate risks to assess and address asset and portfolio vulnerabilities for clients across industries.</p>
                        </div>
                    </div>
                    {/* <div>
                    <h4 className="highlight_h4_tag">Sustainable Development Goals: How far we have reached</h4>
                    <p><i>Mint - Oct 02, 2022</i></p>
                    <p>Taking stock of how the world and India is faring on the SDG goals that they have set for 2030. Considering the covid and other uncertainties that unfolded, Will focusing on specific targets be more effective? the article delves into the progress statistics…</p>
                    <a className="hightlight_a" href="https://www.livemint.com/opinion/online-views/sustainable-development-goals-how-india-and-the-world-are-doing-11664731506364.html" target={"_blank"}>Full Article</a>
                </div> */}
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Can't miss: Trends & Technology</h4>
                    <div className="midsection_li">
                        <div className="trends-sec-row">
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewJune/A-01.png"} alt="" />
                                <p>A new digital innovation called the Urban Mobility Scorecard tool is helping cities meet key sustainability and mobility goals | <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/05/new-digital-tool-cities-tackle-inclusivity-sustainability/" target={"_blank"}>Read more</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewJune/B-01.png"} alt="" />
                                <p>Four key barriers for green purchase decisions—affordability, functionality, findability and distraction—reported by 30-50% shoppers in a study | <a className="mid_section_a" href="https://sustainabilitymag.com/sustainability/soaring-eco-consumer-spending-power-and-the-untapped-segment" target={"_blank"}>Know more</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewJune/C-01.png"} alt="" />
                                <p>Building a circular economy is a must. But so is bringing in sustainable consumption—strategies that focus on reusing and reducing consumption. | <a className="mid_section_a" href="https://ssir.org/articles/entry/response_driving_sustainability_from_within_business" target={"_blank"}>Expert view</a></p>
                            </div>
                        </div>
                        <div className="vertical-line-may"></div>
                        <div className="trends-sec-row">
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewJune/D-01.png"} alt="" />
                                <p>Almost three-fifths of clothing manufactured globally ends up in landfills or an incinerator within a few years. Technology can change this | <a className="mid_section_a" href="https://www.rte.ie/brainstorm/2023/0518/1384287-blockchain-artificial-intelligence-fashion-sustainability-transparency/" target={"_blank"}>Know how</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewJune/E-01.png"} alt="" />
                                <p>ICT sector carbon emissions up from 1.5% in 2007 to 4% and may reach 14% by 2040. How can technology itself be made more sustainable? | <a className="mid_section_a" href="https://sustainabilitymag.com/sustainability/technology-the-subject-curator-of-a-circular-economy" target={"_blank"}>Read on</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewJune/F-01.png"} alt="" />
                                <p>Three critical strategies that food companies need to implement now to ace their sustainability measures as well as bring in cost-effectiveness. | <a className="mid_section_a" href="https://hbr.org/2023/05/how-food-companies-can-better-measure-their-sustainability" target={"_blank"}>Find out</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">In-depth</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>Blue ecosystems: How do underwater Kelp forests help in mitigating climate change? By seizing and converting carbon dioxide into biomass. | <a className="mid_section_a" href="https://www.unep.org/interactives/why-blue-ecosystems-matter/" target={"_blank"}>Know more</a></li>
                            <li>Five ways in which the high-tech industry can help move the sustainability agenda forward | <a className="mid_section_a" href="https://www.forbes.com/sites/forbesbusinessdevelopmentcouncil/2023/04/24/high-tech-innovations-are-key-to-a-greener-economy-5-ways-to-ensure-a-more-sustainable-future/?sh=4d35e8172395" target={"_blank"}>Read more</a></li>
                            <li>New UN report examines economic and business models needed to end plastic pollution and create a #circulareconomy. | <a className="mid_section_a" href="https://www.unep.org/resources/turning-off-tap-end-plastic-pollution-create-circular-economy" target={"_blank"}>Read the report</a></li>
                            <li>While global leaders remain committed to SDGs, more action and support are needed to achieve them. | <a className="mid_section_a" href="images/Accenture-CEO-Study-United-Nations-Global-Compact.pdf#toolbar=0" target='_blank'>Explore</a></li>
                            <li>Seven to eight million new jobs could be created if waste management and recycling are properly applied: New study | <a className="mid_section_a" href="https://www.ilo.org/sector/news/WCMS_881334/lang--en/index.htm" target={"_blank"}>ILO</a></li>
                            <li>Three aspects of sustainability that businesses must understand and fast to stay ahead | <a className="mid_section_a" href="https://www.forbes.com/sites/esri/2023/05/23/the-one-thing-all-businesspeople-are-going-to-be-thinking-about-for-the-next-10-years/?sh=4a1a73617b86" target={"_blank"}>Explore</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Must-reads…</h4>
                    <div className="midsection_li_june">
                        <ul>
                            <li>Did you know that training large AI models can consume up to 700,000 litres of clean freshwater? | <a className="mid_section_a" href="https://www.indiatimes.com/explainers/news/explained-what-is-the-water-footprint-of-ai-and-how-ai-tools-are-raising-environmental-concerns-603277.html" target={"_blank"}>Learn more</a> about AI’s water footprint</li>
                            <li>Big leap in harnessing solar energy more effectively: Innovative solar cells with 50% more efficiency than standard solar panels. | <a className="mid_section_a" href="https://sustainabilitymag.com/articles/solar-panel-efficiency-to-increase-50-with-miracle-cells" target={"_blank"}>Explore</a></li>
                            <li>What is carbon capture? Tech to reduce carbon pollution by catching it at the source of pollution. | <a className="mid_section_a" href="https://edition.cnn.com/2023/05/11/world/carbon-capture-removal-pollution-climate-intl/index.html" target={"_blank"}>Know more</a></li>
                            <li>A by-product of sugarcane production that can be used as a biomaterial to replace concrete and brick. | <a className="mid_section_a" href="https://www.archdaily.com/1001501/from-agro-waste-to-sustainable-structures-concrete-made-from-sugarcane" target={"_blank"}>Explore</a></li>
                            <li>AI models can analyze vast amounts of data to inform environmental research at scale but also consume vast amounts of energy while getting built. How will this affect the ESG in the future? | <a className="mid_section_a" href="https://www.investisdigital.com/blog/corporate-communications/how-responsible-ai-will-change-esg" target={"_blank"}>Read on</a></li>
                            <li>You can use (this special) dust to cool the planet. Explore the new carbon removal technique | <a className="mid_section_a" href="https://www.bbc.com/news/science-environment-65648361" target={"_blank"}>BBC</a></li>
                            <li>Find out how saving disappearing and minority languages can save nature | <a className="mid_section_a" href="https://www.bbc.co.uk/ideas/videos/can-saving-languages-save-nature/p0fh50s5" target={"_blank"}>Watch to learn</a></li>
                            <li>Latest research: Melting polar caps have slowed down a major global deep ocean current with critical consequences for earth’s climate patterns and sea levels | <a className="mid_section_a" href="https://www.theguardian.com/science/2023/may/25/slowing-ocean-current-caused-by-melting-antarctic-ice-could-have-drastic-climate-impact-study-says" target={"_blank"}>Learn more</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    {/* <hr /> */}
                </div>

                {/* <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Reports and Magazines</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>5 charts from the IPCC, Intergovernmental Panel on Climate Change report highlights risks of lower temperatures due to climate change <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/03/climate-change-ipcc-emissions-risks-net-zero/" target={"_blank"}>Read more...</a></li>
                            <li>What are nature climate solutions and how it can help to reduce climate change and bio-diversity loss. <a className="mid_section_a" href="https://www3.weforum.org/docs/WEF_NCSA_NCS_for_Corporates_2021.pdf" target={"_blank"}>Read more...</a></li>
                            <li>Understanding progress in water related ecosystem, SDGs and global data availability. <a className="mid_section_a" href="https://www.unep.org/resources/report/measuring-progress-water-related-ecosystems-and-sdgs" target={"_blank"}>Read more...</a></li>
                            <li>A study on non-native plants and its rapid growth highlights need for monitoring schemes to understand long term impact. <a className="mid_section_a" href="https://www.nature.com/articles/d44151-023-00034-y" target={"_blank"}>Read more...</a></li>
                            <li>Only second ever UN conference on water saw host of countries discuss water disasters related to climate change. <a className="mid_section_a" href="https://www.theguardian.com/environment/2023/mar/23/water-un-conference-climate-crisis-disasters" target={"_blank"}>Read more...</a></li>
                        </ul>
                    </div>
                </div> */}

                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Soulful Living!</h4>
                    <div className="midsec_box">
                        <div className="midsection_para">
                            {/* <p><strong>Innovative use of AI to Assess Potholes issues on City Roads by a Bengaluru startup</strong></p> */}
                            <p className="mid_section_p">How else can plastic be recycled? A Kochi-based cleantech start-up tells us how. Introducing interlocking tiles with plastic pellets and furniture from plastic waste such as reusable bags, food containers, plastic carts, and toys. The start-up has successfully created a product that is light weight, long lasting as well as recyclable. | <a className="mid_section_a" href="https://www.thehindu.com/news/cities/Kochi/kochi-based-start-up-makes-interlocking-tiles-and-furniture-from-plastic-waste/article66060782.ece" target="_blank">Know more about the inspiring initiative</a>.</p>
                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewJune/ppl_img_june.jpg"} alt="sust_news" className="sust_news_img" />
                        </div>
                    </div>

                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>

                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Did You Know</h4>
                    <div className="lastsection_june">
                        <div className="lastsection_may_1stCard">
                            <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewJune/ship.png"} alt="" className="dyk-1"/>
                            <h4 className="lastsection_may_h4">21.5 trillion</h4>
                            {/* <p className="diduknow_p">Earth's surface mainly consists of 3 things: hydrosphere, biosphere & atmosphere.</p> */}
                            <p className="diduknow_p">Study of nearly 2,000 lakes globally found they are losing ∼21.5 trillion litres/year. | <a className="mid_section_a" href="https://abcnews.go.com/Technology/wireStory/incredible-shrinking-lakes-humans-climate-change-diversion-costs-99434603" target={"_blank"}>Read on</a></p>
                        </div>
                        <div className="lastsection_may_2ndCard">
                            <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewJune/Papers.png"} alt="" className="dyk-2"/>
                            <h4 className="lastsection_may_h4">6</h4>
                            <p className="diduknow_p">Paper that comes from trees can be recycled a maximum of six times. | <a className="mid_section_a" href="https://8billiontrees.com/trees/facts-about-the-environment/" target={"_blank"}>Know more</a></p>
                        </div>
                        <div className="lastsection_may_3rdCard">
                            <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewJune/NAD.png"} alt="" className="dyk-3"/>
                            <h4 className="lastsection_may_h4">80%</h4>
                            <p className="diduknow_p">Phytoplanktons get energy through photosynthesis and produce ∼80% of the world’s oxygen. | <a className="mid_section_a" href="https://eos.org/research-spotlights/worlds-biggest-oxygen-producers-living-in-swirling-ocean-waters" target={"_blank"}>Find out more</a></p>
                        </div>
                    </div>
                </div>

                <div className="priority-learnings-container">
                    <h4 className="midsection_h4">Have you checked out the Sustainability Priority Learnings, yet?</h4>
                    <div className="priority-learnings-wrap">
                        <div className="priority-learnings-row">
                            <div className="priority-learnings-box">
                                <p>Course 1: <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/2020982" target="_blank">Green Software for Practitioners</a></p>
                                <p>|</p>
                                <p>Course 2: <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/1795845" target="_blank">Accenture Green Software</a></p>
                            </div>
                            <p>Here's a <a href="https://mediaexchange.accenture.com/media/t/1_n0hrgtw9?st=0" target="_blank">step-by-step video guide</a> on how to access Sustainability Priority Learnings.</p>
                            <p>Have queries? Check out the <a href="https://collections.accenture.com/FileViewer.aspx?f=4e9fb50d-00bd-4124-9212-33cdb7495382">FAQs here</a>.</p>
                        </div>
                    </div>
                </div>

                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <Feedback subject={"Sustainability News From The World - June 2023 Edition"} />

            </div>
        </>
    )
}

export default SustainabilityNewsJune;