import React from 'react';
import './Publications.css';
import Carousel from "react-multi-carousel";
import { useState } from 'react';

function Publications() {
    const [isFlipped, setIsFlipped] = useState(false);
    const handleClick = () => {
        setIsFlipped(!isFlipped);
    }
    const [isFlipped2, setIsFlipped2] = useState(false);
    const handleClick2 = () => {
        setIsFlipped2(!isFlipped2);
    }
    const [isFlipped3, setIsFlipped3] = useState(false);
    const handleClick3 = () => {
        setIsFlipped3(!isFlipped3);
    }
    const [isFlipped4, setIsFlipped4] = useState(false);
    const handleClick4 = () => {
        setIsFlipped4(!isFlipped4);
    }
    const [isFlipped5, setIsFlipped5] = useState(false);
    const handleClick5 = () => {
        setIsFlipped5(!isFlipped5);
    }

    const [isFlipped6, setIsFlipped6] = useState(false);
    const handleClick6 = () => {
        setIsFlipped6(!isFlipped6);
    }
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 540 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 539, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    // const [yearEdition3, setYearEdition3] = useState('current');

    return (
        <>
            <div className="Publications-container">
                <div className="Publications-wrap">
                    <h2><span>Research Publications</span></h2>
                    <p>This segment provides insight into our contributions towards sustainability, as evidenced by papers recognized and accepted at various esteemed conferences worldwide.</p>

                    <div className='Publications-box-row'>
                        <p className='Publications-box-row-para active'>2023 Editions</p>
                       
                        {/* <p className='vertical-line'></p>
                        <p className={yearEdition3 == 'previous' ? 'Publications-box-row-para active' : 'Publications-box-row-para inactive'} onClick={() => setYearEdition3('previous')}>Previous Year Edition (2022)</p> */}
                    </div>
                    <hr></hr>
                    {/* {yearEdition3 == 'current' ? ( */}
                        <div className="Publications-box">
                            {/* <Carousel className='Public-wrap'
                                responsive={responsive}
                                swipeable={false}
                                draggable={false}
                                showDots={true}
                                arrows={true}
                                renderArrowsWhenDisabled={true}
                                keyBoardControl={true}
                                partialVisible={false}> */}
                                   

                                



                                <div className="Public-row">
                                    <div className="Public-box box2">
                                        <div class="flip-card">
                                            <div className={`flip-card-inner ${isFlipped2 ? 'is-flipped' : ''}`} onClick={handleClick2}>
                                                <div class="flip-card-front">
                                                    <img src="images/Home/publications/public-card2-new.jpg" alt="card" className='card-imgs' />
                                                    <h3>Technological and operational approaches for sustainable food supply chains – A critical review</h3>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h4>Paper accepted for presentation in the EurOMA Sustainability Forum 2023 <a href="https://euroma-sustainabilityforum.org/" target='_blank'>(https://euroma-sustainabilityforum.org/)</a>, to be held virtually on 20-23 March 2023 in Hamburg, Germany.</h4>
                                                    <p>Authors: Gunjan Bansal, Sheena Siddiqui, Monisha Bhattacharya, Anirudh A. Goel, Shantanu Biswas</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Public-row">
                                    <div className="Public-box box3">
                                        <div class="flip-card">
                                            <div className={`flip-card-inner ${isFlipped3 ? 'is-flipped' : ''}`} onClick={handleClick3}>
                                                <div class="flip-card-front">
                                                    <img src="images/Home/publications/public-card3-new.jpg" alt="card" className='card-imgs' />
                                                    <h3>The Use of AI in Food Systems and its Impact on Existing Global Inequalities <span style={{ visibility: 'hidden' }}>A critical review rev</span></h3>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h4>Paper accepted for presentation in Bonn Sustainable AI Conference Sustainable AI <a href="https://sustainable-ai-conference.eu/" target='_blank'>(sustainable-ai-conference.eu)</a>, to be held on 29-30 May 2023 at Bonn. </h4>
                                                    <p>Authors: Sheena Siddiqui, Shantanu Biswas, Gunjan Bansal, Monisha Bhattacharya, Anirudh Goel</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Public-row">
                                    <div className="Public-box box4">
                                        <div class="flip-card">
                                            <div className={`flip-card-inner ${isFlipped4 ? 'is-flipped' : ''}`} onClick={handleClick4}>
                                                <div class="flip-card-front">
                                                    <img src="images/Home/publications/public-card4-new.jpg" alt="card" className='card-imgs' />
                                                    <h3>Enroute to gender parity: a review and augmentation of industry solutions in AI <span style={{ visibility: 'hidden' }}>A critical </span></h3>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h4>Paper accepted for presentation in Bonn Sustainable AI Conference Sustainable AI <a href="https://sustainable-ai-conference.eu/" target='_blank'>(sustainable-ai-conference.eu)</a>, to be held on 29-30 May 2023 at Bonn. </h4>
                                                    <p>Authors: Aparna Chakravorty, Monisha Bhattacharya, Shantanu Biswas, Anirudh Goel </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Public-row">
                                    <div className="Public-box box4">
                                        <div class="flip-card">
                                            <div className={`flip-card-inner ${isFlipped5 ? 'is-flipped' : ''}`} onClick={handleClick5}>
                                                <div class="flip-card-front">
                                                    <img src="images/Home/publications/public-card5.png" alt="card" className='card-imgs' />
                                                    <h3>An immersive metaverse experience in transforming farmers’ perspective to adopt end-to-end sustainable agriculture practices<span style={{ visibility: 'hidden' }}>A critical </span></h3>
                                                </div>
                                                <div class="flip-card-back">
                                                    <h4>Paper accepted for presentation in Rome Sustainable Development International Conference <a href="https://www.ecsdev.org/conference" target='_blank'>Conference (ecsdev.org)</a>, to be held on 6-7 Sep 2023 at Rome, Italy.</h4>
                                                    <p>Authors: Vanita Patil, Sheela Ganesh, Anupama Nithyanand</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Public-row">
                                    <div className="Public-box box1">
                                        <div class="flip-card">
                                            <div className={`flip-card-inner ${isFlipped ? 'is-flipped' : ''}`} onClick={handleClick}>
                                                <div className="flip-card-front">
                                                    <img src="images/Home/publications/public-card1-new.jpg" alt="card" className='card-imgs' />
                                                    <h3>Contribution of sustainable value chains in industries: A systematic review <span style={{ visibility: 'hidden' }}>A critic review rev</span> </h3>
                                                </div>
                                                <div className="flip-card-back">
                                                    <h4>Paper accepted for presentation in the EurOMA Sustainability Forum 2023 <a href="https://euroma-sustainabilityforum.org/" target='_blank'>(https://euroma-sustainabilityforum.org/)</a>, to be held virtually on 20-23 March 2023 in Hamburg, Germany.</h4>
                                                    <p>Authors: Gunjan Bansal, Sheena Siddiqui, Monisha Bhattacharya, Anirudh A. Goel, Shantanu Biswas</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="Public-row">
                                    <div className="Public-box box1">
                                        <div class="flip-card">
                                            <div className={`flip-card-inner ${isFlipped6 ? 'is-flipped' : ''}`} onClick={handleClick6}>
                                                <div className="flip-card-front">
                                                    <img src="images/Home/publications/public-card5-new.png" alt="card" className='card-imgs' />
                                                    <h3>Utilizing cutting edge technologies in India’s agricultural and dairy Industries to reduce food loss</h3>
                                                </div>
                                                <div className="flip-card-back">
                                                    <h4>Paper accepted for presentation in Rome Sustainable Development International Conference <a href="https://www.ecsdev.org/conference" target='_blank'>Conference (ecsdev.org)</a> to be held on 6-7 Sep 2023 at Rome, Italy.</h4>
                                                    <p>Authors: Sheela Ganesh, Vanita Patil, Anupama Nithyanand</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            {/* </Carousel> */}
                        </div>
                    {/* ) : (
                        <div>
                            <br /> <br /> <br /> <br /> <br /><br /> <br />
                        </div>
                    )
                    } */}

                </div>
                <img src="images/Home/bg7.png" alt="" className='bg7' />
                <img src="images/Home/bg8.png" alt="" className='bg8' />
            </div>
        </>
    )
}

export default Publications