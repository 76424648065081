import React, {useEffect} from "react";
import { useIsAuthenticated , useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */

 
 function handleLogin(instance) {
    console.log("handle login ");

    instance.loginRedirect(loginRequest).catch(e => {
        
        console.error("login error msg",e);
    });
}

export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    useEffect(() => {
        if (isAuthenticated) {
            console.log("authenticated");
            if (window.location.hash) {
                window.history.replaceState("", document.title, window.location.pathname);
            }
        }
        else {

            handleLogin(instance);
        }
        
        // console.log("from use effect ")
      },[ instance ])
    
    return (
        <>
            {
	        isAuthenticated ? props.children : 
            <div style={{margin: '20px 0 0 10px',fontSize: '20px',fontStyle: 'italic',fontWeight: 'bold'}}>
            Checking Authentication...
            </div>
            }
        </>
    );
};