import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
// Config object to be passed to Msal on creation

const clientId = process.env.REACT_APP_CLIENT_ID;
const authority = process.env.REACT_APP_AUTHORITY;
const redirectUri = process.env.REACT_APP_REDIRECT_URI;
const postLogoutRedirectUri = process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI;
const refersh_token = process.env.REACT_APP_REFRESH_TOKEN;

export const msalConfig = {
  auth: {
      clientId: clientId,
      authority: authority,            
      redirectUri: redirectUri,
      postLogoutRedirectUri: postLogoutRedirectUri
      // production config 
      //  clientId: "d15d8964-5c94-4ea8-9de5-2124a2a48a97",
      //  authority: "https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb/",
      //  validateAuthority: false,
      //  redirectUri: "https://atci.lkm.accenture.com/ASPIRA",
      //  postLogoutRedirectUri: 'https://atci.lkm.accenture.com/ASPIRA',
      //  navigateToLoginRequestUrl: false, 


    //  staging config   
    // clientId: "8b701598-8a31-4de9-bd66-5e53a470956f",
    // authority: "https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    // redirectUri: "http://localhost:3000",
    // //  redirectUri: "https://atci.lkm.accenture.com/",
    //  postLogoutRedirectUri: 'https://atci.lkm.accenture.com/ASPIRA'
  },

  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
            if (containsPii) {  
                return;
            }
            switch (level) {    
                case LogLevel.Error:    
                    console.error(message);
                    return;
                case LogLevel.Info:
                    console.info(message);  
                    return;
                case LogLevel.Verbose:  
                    console.debug(message);
                    return;
                case LogLevel.Warning:  
                    console.warn(message);  
                    return;
                default:
                    return;
            }
        }
    }
}
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: ["openid","profile","email","User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};




// export const requiresInteraction = (esoError) => {

//   if(!esoError || !esoError.length)
//   {
//       return false;
//   }

//   else
//   return (
// esoError.indexOf('consent_required') > -1 ||
// esoError.indexOf('interaction_required') > -1 ||
// esoError.indexOf('login_required') > -1
//   )
// }

export const fetchMsGraph = async (url, accessToken) => {
  const response = await fetch(url, {
  headers: {
  Authorization: `Bearer ${accessToken}`,
  },
  });
         
  return response.json();
  };
  export const AUTH_REQUESTS = {
    LOGIN: {
    scopes: ['openid', 'profile'],
    },
    EMAIL: {
    scopes: [],
    },
    REFRESH_TOKEN: {
    scopes: [refersh_token],
    },
    };