import React from 'react';
import './FAQContent.css'

function FAQContent() {
  return (
    <>
    <h2>FAQ</h2>
    </>
  )
}

export default FAQContent