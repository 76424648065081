import {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import './Feedback.css';
import { Link } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import axios from 'axios';


function getModalStyle() {
  const top = '20%';
  const left = '35%';

  return {    
    top: '25%',
    left: '38%',
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  paper : {
    position : 'absolute',
    width: 420,
    backgroundColor: theme.palette.background.paper,    
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2,4,3)
  }
}));

  const Feedback = (props) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [text,setText] = useState('');
  const [rating, setRating] = useState([]);
  const [feedbackBtn, setFeedbackBtn] = useState(true);
  const [taInlnStyle, setTaInlnStyle] = useState({height: "15px"});
  const [comment, setComment] = useState('');
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const handleOpen = () =>{
    setOpen(true);
    setToggle(true);
    setRating([]);
  }

  const handleClose = () =>{
    setOpen(false);
    setToggle(false);
  }  

  useEffect(() => {
    if(props.subject == ("Sustainability News From The World - March 2023 Edition")) {
      setText("Mar 2023 Edition!")
    }
    else if (props.subject == "Mar 2023 Edition") {
      setText("Mar 2023 Edition!")
    }
    else if (props.subject == "Sustainability News From The World - April 2023 Edition") {
      setText("Apr 2023 Edition!")
    }
    else if (props.subject == "Apr 2023 Edition") {
      setText("Apr 2023 Edition!")
    }
    else if (props.subject == "Sustainability News From The World - May 2023 Edition") {
      setText("May 2023 Edition!")
    }
    else if (props.subject == "May 2023 Edition") {
      setText("May 2023 Edition!")
    }
    else if (props.subject == "Sustainability News From The World - June 2023 Edition") {
      setText("June 2023 Edition!")
    }
    else if (props.subject == "June 2023 Edition") {
      setText("June 2023 Edition!")
    }
    else if (props.subject == "Sustainability News From The World - July 2023 Edition") {
      setText("July 2023 Edition!")
    }
    else if (props.subject == "July 2023 Edition") {
      setText("July 2023 Edition!")
    }
    else if (props.subject == "Sustainability News From The World - August 2023 Edition") {
      setText("August 2023 Edition!")
    }
    else if (props.subject == "August 2023 Edition") {
      setText("August 2023 Edition!")
    }
    else if (props.subject == "Sustainability News From The World - September 2023 Edition") {
      setText("September 2023 Edition!")
    }
    else if (props.subject == "September 2023 Edition") {
      setText("September 2023 Edition!")
    }
    else if (props.subject == "Sustainability News From The World - October 2023 Edition") {
      setText("October 2023 Edition!")
    }
    else if (props.subject == "October 2023 Edition") {
      setText("October 2023 Edition!")
    }
    else if (props.subject == "Sustainability News From The World - November 2023 Edition") {
      setText("November 2023 Edition!")
    }
    else if (props.subject == "November 2023 Edition") {
      setText("November 2023 Edition!")
    }
    else {
      setText("Feb 2023 Edition!") 
    }
    
    },[props.subject]);


  const closeFeedbackBtn = () => {
    setFeedbackBtn(false);
  }
  const handleComment = (commentVal) => {
    if(commentVal.length > 46 && commentVal.length < 92) {
        setTaInlnStyle({height: "45px"});            
    } else if(commentVal.length > 92) {
        setTaInlnStyle({height: "70px"});            
    }else if(commentVal.length < 46) {
        setTaInlnStyle({height: "21px"});
    }
    setComment(commentVal);
}

const handleSubmit = async (event) => {
  event.preventDefault();  
  if(process.env.NODE_ENV === 'development') {                                                            
       alert("Not allowed to submit feedback from localhost.");
  }            
  else 
  {
      setToggle(false);      
      axios.post('https://atci.sustainabilityresearch.accenture.com/sustainability-research-apis/feedback', {      
        subject: props.subject,
        enterprise_id: activeAccount.idTokenClaims.enterpriseID,
        rating: rating.length,                                                        
        comment: comment,          
      },{
          headers: {              
            'Authorization': localStorage.getItem("token"),
            'Content-Type': 'application/json'
          }
      }
      )
      .then((response) => {                        
          // setFeedbackRes(response.data.message);
      })
      .catch((error)=>{
          // setFeedbackRes(error.data.message);
      })                                                                          
      setComment('');
  }       
}

  const body = (
    <div style={modalStyle} className={classes.paper}>            
      { toggle && <div id="feedback_form">
        <form onSubmit={handleSubmit}>
        <div className="feedback_label">
            Send Feedback 
        </div>
          <div className="feedback">             
            <span id="thankYou-close" onClick={handleClose}></span>
            <h6 className="question">Tell us your experience with the {text}</h6>                                                          
            <div className='feedback-rating'>
                <img src={rating.includes(1) ? "/images/feedback/star_active.svg" : "/images/feedback/star_inactive.svg"} alt="" onClick={()=>setRating([1])}/>
                <img src={rating.includes(2) ? "/images/feedback/star_active.svg" : "/images/feedback/star_inactive.svg"} alt="" onClick={()=>setRating([1,2])}/>
                <img src={rating.includes(3) ? "/images/feedback/star_active.svg" : "/images/feedback/star_inactive.svg"} alt="" onClick={()=>setRating([1,2,3])}/>
                <img src={rating.includes(4) ? "/images/feedback/star_active.svg" : "/images/feedback/star_inactive.svg"} alt="" onClick={()=>setRating([1,2,3,4])}/>
                <img src={rating.includes(5) ? "/images/feedback/star_active.svg" : "/images/feedback/star_inactive.svg"} alt="" onClick={()=>setRating([1,2,3,4,5])}/>            
                <p id="star-text" tabindex="0">{rating.length}/5</p>
            </div>                                  
            <textarea id="feedback-input" type="text" rows="2" placeholder="Please provide suggestions or feedback here" 
            onChange={(event)=>{ handleComment(event.target.value) }} style={taInlnStyle}>
            </textarea>
            <div className="submit">
              <button type="submit">Send</button>              
            </div>            
          </div>
        </form>
      </div>}  
      { !toggle && <div id="success_form">            
            <span id="thankYou-close" onClick={handleClose}></span>
            <h2 className="feedback-success">Thank you!</h2>                      
            <p className="feedback-success-msg"></p>                      
      </div> }                
    </div>        
  );

  return (
    <div className='feedback_btn_sec'>      
        {feedbackBtn && <div id="feedback-btn" role="button" tabindex="0" aria-label="Feedback" className="keyboard-outline">
          <div>
          <Link className="feedback-lbl" onClick={handleOpen}><img src={process.env.PUBLIC_URL + "/images/Home/MonthlyRoundup/Rating_icon.png"} className='rating_icon'/>Rate this edition</Link>
          </div>
         
        {/* <span role="button" class="feedback-btn-close" onClick={closeFeedbackBtn}></span> */}
    </div>}            
      <Modal open={open} aria-labelledby="simple-modal-tile" aria-describedby="simple-modal-description">
        {body}
      </Modal>        
    </div>   
  );
}

export default Feedback;