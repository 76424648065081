import React, {useState,useEffect, forwardRef} from 'react';
import "./FeedbackData.css";
import axios from 'axios';
import { CSVLink } from "react-csv";
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const FeedbackData = () =>{
    const [feedbacks, setFeedbacks] = useState([]);
    const [feedbacksDataForDownload, setFeedbacksDataForDownload] = useState([]);    
    
    const [fromStartDate, setFromStartDate] = useState(new Date("2023/01/01"));    
    const [toEndDate, setToEndDate] = useState(new Date());    

    const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
        <button className="date-picker-input-btn" onClick={onClick} ref={ref}>
          {value}
        </button>
    ));

    const handleStartDate = (date) => {        
        setFromStartDate(date)   
        let startDate = Moment(date).format('YYYY-MM-DD');
        let endDate = Moment(toEndDate).endOf('month').format('YYYY-MM-DD');                         
        fetchFilteredAPI(startDate, endDate);        
    }

    const handleEndDate = (date) => {                
        setToEndDate(date)        
        let startDate = Moment(fromStartDate).format('YYYY-MM-DD');
        let endDate = Moment(date).endOf('month').format('YYYY-MM-DD');                
        fetchFilteredAPI(startDate, endDate);        
    }

    const handleFeedbackDownload = () => {
        let updatedDownloadData = feedbacksDataForDownload.map((feedback, idx) => {            
            return {                
                'SL#': idx + 1,
                'Enterprise Id': feedback.enterprise_id,
                'Subject': feedback.subject,
                'Rating': feedback.rating === 0 ? '' : feedback.rating,
                'Comment': feedback.comment.trim(),
                'Submitted Date': `${Moment(feedback.created_at).format('DD-MM-YYYY')}`,                                
            };
        })        
        setFeedbacksDataForDownload(updatedDownloadData);
    }

    const fetchData = async () => {
        try {
                let response = await axios({
                    method: 'get',
                    url: 'https://atci.sustainabilityresearch.accenture.com/sustainability-research-apis/feedback',  
                    headers: {
                        'Authorization': localStorage.getItem("token"),
                        'Content-Type': 'application/json'
                    },                                                           
                })                                     
                setFeedbacks(response.data.results);
                setFeedbacksDataForDownload(response.data.results);
        } catch(error) {
            // console.log(error);
        }        
    }

    const fetchFilteredAPI = async (startDate, endDate) => {
        try {
                let response = await axios({
                    method: 'get',
                    url: `https://atci.sustainabilityresearch.accenture.com/sustainability-research-apis/feedback/${startDate}/${endDate}`,  
                    headers: {
                        'Authorization': localStorage.getItem("token"),
                        'Content-Type': 'application/json'
                    },                                                 
                })                                     
                setFeedbacks(response.data.results);
                setFeedbacksDataForDownload(response.data.results);
        } catch(error) {
            // console.log(error);
        }        
    }
    
    useEffect(() => {
        
        fetchData();
    },[]);
    return (
        <>
        <div className="feedData_main_container">
            <div className="fddata_container">            
            <h2>Feedbacks</h2>
            <div className="fddata_mid_sec">
            <div className='date-picker-container'>
                            <span className='filter-text'>Filter | &nbsp;</span>
                            <div className='date-picker-start'>                    
                                <DatePicker
                                    selected={fromStartDate}
                                    onChange={(date) => handleStartDate(date)}                                    
                                    minDate={new Date("2023/01/01")}
                                    maxDate={toEndDate}
                                    showMonthYearPicker
                                    dateFormat="MM, yyyy"
                                    customInput={<DatePickerInput/>}
                                />
                            </div>
                            <span className='filter-text'> &nbsp;&nbsp;to&nbsp;&nbsp; </span> 
                            <div className='date-picker-end'> 
                                <DatePicker
                                    selected={toEndDate}
                                    onChange={(date) => handleEndDate(date)}                                    
                                    minDate={fromStartDate}
                                    maxDate={new Date()}  
                                    showMonthYearPicker
                                    dateFormat="MM, yyyy"
                                    customInput={<DatePickerInput/>}
                                />
                            </div>    
                        </div>
                        {feedbacksDataForDownload.length > 0 && <div className='sr-feedback-download'>                
                            Feedback |&nbsp;
                            <CSVLink filename={`sr-feedback.csv`} data={feedbacksDataForDownload} onClick={handleFeedbackDownload}>Download</CSVLink>
                        </div>}  
            </div>
            <table className="fddata_table">
            <thead>
                        <tr>
                            <th>SL#</th>
                            <th>Enterprise Id</th>
                            <th>Subject</th>
                            <th>Rating</th>
                            <th>Comment</th>
                            <th>Feedback Submitted Date</th>                        
                        </tr>                                                
                    </thead>  
            <tbody>                
                    {feedbacks.length > 0 ? feedbacks.map((feedback, idx) => {                                                                      
                        return <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{feedback.enterprise_id}</td>
                            <td>{feedback.subject !== ''? feedback.subject : '-'}</td>
                            <td>{feedback.rating !== 0 ? feedback.rating : '-'}</td>
                            <td>{feedback.comment !== '' ? feedback.comment : '-'}</td>
                            <td>{Moment(feedback.created_at).format('DD-MM-YYYY')}</td>                       
                        </tr>
                    }) : <tr><td colspan="6">No Feedbacks Found </td></tr>}                            
                    </tbody>
            </table>
            </div>  
        </div>
        </>
    )
}

export default FeedbackData;