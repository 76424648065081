import React from "react";
import "./SankalpTaruFoundation.css";

const SankalpTaruFoundation = () => {
  return (
    <>
      <div className="main-banner">
        <h1>Sustainable Business Entrepreneurship Series</h1>
        <img
          src="images/Home/Sustainability_News/sustainability_news_banner.png"
          alt="banner"
          className="banner-img"
        />
      </div>
      <div className="back-home">
        <p>
          <a href="/home">Home</a>
        </p>
      </div>
      <div className="organicfarms-container">
        <div className="organicfarms-wrap">
          <p>
            The{" "}
            <span className="text-highlighter">
              Sustainable Business Entrepreneurship Series
            </span>{" "}
            brings to you inspirational stories and perspectives from people who
            envision the future, go ahead to create it, and make an impact. Each
            of these sessions has something we can take away and apply; also
            benefit from the thoughtfulness and knowledge of the speakers. The
            diverse topics cut across the{" "}
            <a
              href="https://www.undp.org/sustainable-development-goals?utm_source=EN&utm_medium=GSR&utm_content=US_UNDP_PaidSearch_Brand_English&utm_campaign=CENTRAL&c_src=CENTRAL&c_src2=GSR&gclid=Cj0KCQiA8t2eBhDeARIsAAVEga0wn2wYvtbbhI1H-HofbJHtWyCLUUgwnOq1qX3VAI7OIo9VJuKnzgsaAo5aEALw_wcB"
              target="_blank"
            >
              17 UN SDGs
            </a>
            , and we do hope they would open up your minds to new thoughts and
            possibilities!{" "}
          </p>
          <p>
            Do you have comments or suggestions? We would love to hear at{" "}
            <a href="mailto:ATCI.Sust.Research@accenture.com">
              {" "}
              ATCI.Sust.Research@accenture.com
            </a>
          </p>
          <p>
            Regards, <br />
            <span className="text-highlighter">Shridhar Rajgopalan,</span> Lead
            - Sustainability and Song, ATCI <br />
            <span className="text-highlighter">Anupama Nithyanand,</span> Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI
          </p>
          <h2>SUSTAINABLE BUSINESS ENTREPRENEURSHIP SERIES</h2>
          <div className="sankalptaru_content_div">
            <p>
              Climate change is no longer a future possibility. It is real and
              now, manifesting in myriad ways, as we witness and experience it
              in our daily lives. It clearly warrants action from everyone, in
              every way we can. While every responsible and conscious earth
              citizen acts in their own ways to alleviate the effects, some make
              it their life's purpose!
            </p>
            <p className="sankalptaru_para">
              We are extremely pleased to announce an insightful and exciting
              talk, "
              <span className="afforestation_span">
                Afforestation and Environmental Sustainability
              </span>
              " by{" "}
              <span className="afforestation_span">
                Apurva Bhandari, CEO and Founder of SankalpTaru Foundation,
              </span>{" "}
              on <b>26-May-2023, from 9:30 am to 10:45 am.</b>
            </p>
            <a
              className="meeting_link"
              href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmM2YWE0ZGUtY2RmOC00ZmQyLTlhNzgtYmJkNGNiYzY2YmM2%40thread.v2/0?context=%7b%22Tid%22%3a%22e0793d39-0939-496d-b129-198edd916feb%22%2c%22Oid%22%3a%22158c819c-1d1c-45b8-866f-212bba66c8a4%22%7d"
              target="_blank"
            >
              Click here to join the meeting
            </a>
          </div>
          <div className="sankalptaru_wrap">
            <div className="sankalptaru-row row1">
              <div className="sankalptaru-box">
                <img
                  src="images/Home/Entrepreneur/apurva_about_image.png"
                  alt="card"
                  className="brother-card-img"
                />
              </div>
            </div>
            <div className="sankalptaru-row row2">
              <div className="sankalptaru-box">
                <h3 className="sankalptaru-box_header">
                  Introducing Apurva Bhandari: Steering Sustainable Change with
                  SankalpTaru Foundation
                </h3>
                <p className="sankalptaru_mid_para">
                  Apurva Bhandari, an alumnus of Bharat Petroleum Corporation
                  Ltd and a former IT consultant, is now at the helm of
                  SankalpTaru Foundation, a tech-based firm dedicated to our
                  planet's green cover. His commitment to sustainable living has
                  led to the planting over 5 million trees across India,
                  transforming barren lands into flourishing landscapes. With a
                  unique blend of modern technology and traditional knowledge,
                  he works tirelessly to ensure the survival and growth of these
                  trees, thereby fostering a healthier future for all.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="talk-container">
          <div className="talk-wrap">
            <h3>During his talk, Apurva will delve into the following:</h3>
            <div className="talk-row row1">
              <div className="talk-box box1">
                <ul className="talk-lists">
                  <li className="talk_li">
                    SankalpTaru introduction and coverage
                  </li>
                  <li>
                    How technology can play a crucial role in creating social
                    impact
                  </li>
                </ul>
              </div>
            </div>
            <div className="talk-row row2">
              <div className="talk-box box2">
                <ul className="talk-lists">
                  <li className="talk_li">
                    How to build sustainable social enterprises
                  </li>
                  <li>Importance of sustainable lifestyle practices</li>
                </ul>
              </div>
            </div>
          </div>
          <p className="sankalptaru_last_apara">
            Join us to hear from and interact with this visionary social
            entrepreneur. We eagerly await your presence for this conversation.
          </p>
        </div>
      </div>
    </>
  );
};

export default SankalpTaruFoundation;
