import {useState, useEffect} from 'react'
import axios from 'axios';
import { useMsal } from "@azure/msal-react";

import './HomeAnalytics.css';

function Analytics() {
    const [totalViews, setTotalViews] = useState(0);
    const [totalUserViews, setTotalUserViews] = useState(0);
    
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();        

    // let url = "http://localhost:3051/analytics";
    let url = "https://atci.sustainabilityresearch.accenture.com/sustainability-research-apis/analytics";

    let loggedInEnterpriseId = '';
    if(activeAccount)
        loggedInEnterpriseId = activeAccount.idTokenClaims.enterpriseID;
    
    const lkmPagesAnalytics = () => {    
        axios.post(url, {
            enterprise_id : loggedInEnterpriseId   
        },{
            headers: {              
              'Authorization': localStorage.getItem("token"),
              'Content-Type': 'application/json'
            }
        })
        .then((response) => {                        
            getAnalytics();
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    const getAnalytics = () => {
        axios.get(url,{
            headers: {              
              'Authorization': localStorage.getItem("token"),
              'Content-Type': 'application/json'
            }
        })        
        .then((response)=>{            
            setTotalViews(response.data.totalViews);
            setTotalUserViews(response.data.totalUniqueUsers);
        })  
        .catch((err)=>{
            console.log(err);    
        })
    }
    
    useEffect(() => {
        setTimeout(() => {
            if(process.env.NODE_ENV === 'production' && loggedInEnterpriseId !== '') 
                lkmPagesAnalytics();                                                
        },[1000])        
    },[]);  

    return (        
        <>
            {process.env.NODE_ENV === 'production' && <span className='analytics-span'><img src={process.env.PUBLIC_URL + "/images/Home/medium-eye.png"} className='eye-icon' alt="eye" /> Total Views: {totalViews} &nbsp; | &nbsp; Unique Views: {totalUserViews}</span>}
        </>        
        
    )
}

export default Analytics;