import React from 'react';
import './AnkurWarikoo.css';

function AnkurWarikoo() {
    return (
        <>
            <div className="main-banner">
                <h1>Sustainable Business Entrepreneurship Series</h1>
                <img src="images/Home/Sustainability_News/sustainability_news_banner.png" alt="banner" className='banner-img' />
            </div>
            <div className="back-home">
                <p><a href="/home">Home</a></p>
            </div>
            <div className="organicfarms-container">
                <div className="organicfarms-wrap">
                    <p>The <span className='text-highlighter'>Sustainable Business Entrepreneurship Series</span> brings to you inspirational stories and perspectives from people who envision the future, go ahead to create it, and make an impact. Each of these sessions has something we can take away and apply; also benefit from the thoughtfulness and knowledge of the speakers. The diverse topics cut across the <a href="https://www.undp.org/sustainable-development-goals?utm_source=EN&utm_medium=GSR&utm_content=US_UNDP_PaidSearch_Brand_English&utm_campaign=CENTRAL&c_src=CENTRAL&c_src2=GSR&gclid=Cj0KCQiA8t2eBhDeARIsAAVEga0wn2wYvtbbhI1H-HofbJHtWyCLUUgwnOq1qX3VAI7OIo9VJuKnzgsaAo5aEALw_wcB" target="_blank">17 UN SDGs</a>, and we do hope they would open up your minds to new thoughts and possibilities! </p>
                    <p>Do you have comments or suggestions? We would love to hear at <a href="mailto:ATCI.Sust.Research@accenture.com"> ATCI.Sust.Research@accenture.com</a></p>
                    <p>
                        Regards, <br />
                        <span className='text-highlighter'>Shridhar Rajgopalan,</span> Lead - Sustainability and Song, ATCI <br />
                        <span className='text-highlighter'>Anupama Nithyanand,</span> Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI

                    </p>
                    <h2>SUSTAINABLE BUSINESS ENTREPRENEURSHIP SERIES</h2>

                    <div className='about-list'>
                        In a world characterized by rapid technological advances, continuous change, and increasing complexity, our progress depends on our ability to adapt, build resilience, and maintain a sustainable growth mindset. Recognizing the significance of this need, we are extremely pleased to announce a highly relevant talk on the topic of "<strong>Growing Sustainably</strong>" by the esteemed speaker <strong>Ankur Warikoo</strong>.
                    </div>
                    <h3 style={{ marginBottom: '1%', marginTop: '1%' }}>Topic Name: <span style={{ color: 'black' }}>Growing Sustainably</span> </h3>
                    <ul className="about-list1">
                        <span style={{ marginBottom: '20px' }}>In this compelling session, Ankur will share his unique insights on:</span>
                        <li>Growth mindset is what we should demand from ourselves</li>
                        <li>Sustainability is what the world demands from us</li>
                        <li>Marrying the two then becomes the need of the hour</li>
                    </ul>

                    <div className="profile-container">
                        <div className="profile-details">
                            <img src="images/Home/Entrepreneur/ankurWarikoo.png" alt="profile-img" />
                            <h4>Ankur Warikoo</h4>
                            <p>Entrepreneur | Teacher | Content Creator | Mentor
                                Founder, nearbuy.com | Founding CEO, Groupon India
                                Forbes India Top 100 Digital Creators | FORTUNE India 40 Under 40
                                LinkedIn Top Voice 2018/19/20
                            </p>
                        </div>
                        <div className="profile-content">
                            <h3>Profile of the Speaker:</h3>
                            <p>Ankur is a celebrated internet entrepreneur, influential content creator, and dedicated mentor. His digital influence extends to over 9 million followers across various platforms. As a bestselling author, his books "DO EPIC SHIT" and "GET EPIC SHIT DONE" continue to inspire readers across India and beyond.</p>
                            <p>Previously, Ankur founded and led nearbuy.com, India's leading lifestyle app, and served as the Founding CEO of Groupon's India venture. His remarkable contributions to the business and digital realm have been recognized by Fortune Magazine’s 40 under 40 List for India and Forbes Top 100 Digital Creators list.</p>
                            <p>Join us for this exciting session and learn from one of the industry's leading figures.</p>
                        </div>
                    </div>

                </div>
                <div className="session-details">
                    <h3>SESSION DETAILS</h3>
                    <div className="session-outer">
                        <div className="session-wrap">
                            <div className="session-date"><span>Date: </span>28<sup>th</sup> July 2023</div>
                            <div className="session-date"><span>Time: </span>9:30 am to 11:00 am</div>
                        </div>
                        <div className="join-button"><a href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZjkwMTAzMjUtMTMxYS00ZjRlLThmMTEtNTIxMGVlZGJjN2Y5%40thread.v2/0?context=%7b%22Tid%22%3a%22e0793d39-0939-496d-b129-198edd916feb%22%2c%22Oid%22%3a%22158c819c-1d1c-45b8-866f-212bba66c8a4%22%7d" target='_blank'>JOIN SESSION</a></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnkurWarikoo