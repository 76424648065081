import React from 'react';
import "./CoreTeam.css"

const CoreTeam = () => {
   return (
      <div className='coreteam-container'>
         <div className='coreteam-wrapper'>
            <h2><span>Core Team</span></h2>
            <div className='team-leads-container'>
               <div className='team-card'>
                  <img className='lead-img' src='images/Home/CoreTeam/ambrish-lead1.png' />
                  <div className='lead-details'>
                     <h4>Ambrish Rastogi</h4>
                     <p>Lead-L&TT, Leadership Transformation, ATCI</p>
                     {/* <p>Lead-LKM, ATCI Sustainability Research</p>  */}
                     {/* <p> ATCI Sustainability Research</p> */}
                  </div>
                  <div className='contact-icons'>
                     <a href='https://people.accenture.com/People/user/ambrish.rastogi' target="_blank"><img className='icons' src='images/Home/CoreTeam/icon1-new.png' alt='user-icon' /></a>
                     <a target="_blank" href='https://www.linkedin.com/in/ambrish-rastogi-6428b63/'><img className='icons' src='images/Home/CoreTeam/icon2-new.png' alt='in-icon' /></a>
                     <a href='mailto:ambrish.rastogi@accenture.com'><img className='icons' src='images/Home/CoreTeam/icon3-new.png' alt='mail-icon' /></a>
                  </div>
               </div>
               <div className='team-card'>
                  <img className='lead-img' src='images/Home/CoreTeam/lead2.jpg' />
                  <div className='lead-details'>
                     <h4>Anirudh Goel</h4>
                     <p>Sustainability, Innovation, AI Learning & Knowledge Management, ATCI</p>
                     {/* <p> LKM</p> */}
                  </div>
                  <div className='contact-icons'>
                     <a target="_blank" href='https://people.accenture.com/People/user/anirudh.a.goel'><img className='icons' src='images/Home/CoreTeam/icon1-new.png' alt='user-icon' /></a>
                     <a target="_blank" href='https://www.linkedin.com/authwall?trk=bf&trkInfo=AQEp7hzGVLN8zAAAAYSef7OA4BcA-WFmyCxxDAIK7j-5l4vVDazYUHkGi7S-_8HOyjJXNGgEthy1dIJSjCk7VWiZJPt_CsGnjVlX_6TzqdAatQmOYFHqQ4Wiru3Q0xlxsFtdXuo=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fanirudh-goel-blr%2F'><img className='icons' src='images/Home/CoreTeam/icon2-new.png' alt='in-icon' /></a>
                     <a href='mailto:anirudh.a.goel@accenture.com'><img className='icons' src='images/Home/CoreTeam/icon3-new.png' alt='mail-icon' /></a>
                  </div>
               </div>
               <div className='team-card'>
                  <img className='lead-img' src='images/Home/CoreTeam/lead3.jpg' />
                  <div className='lead-details'>
                     <h4>Shantanu Biswas</h4>
                     <p>Associate Director, L&TT</p>
                     {/* <p> LKM</p> */}
                  </div>
                  <div className='contact-icons'>
                     <a target="_blank" href='https://people.accenture.com/People/user/shantanu.a.biswas'><img className='icons' src='images/Home/CoreTeam/icon1-new.png' alt='user-icon' /></a>
                     <a target="_blank" href='https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHdDb9Ldx5-4QAAAYSegnaItTTqaSSzPtKrlXgX-Ef8LSIgChSbAB-iFIMlPPxUS9nES9vgj42Sn5pQeDJnD-wec001w_VTQIsNSEaCWVvXgH9aM_U2pzb6wqROEP_C4Dhe0M0=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fvanita-patil-71a18615%2F%3ForiginalSubdomain%3Din'><img className='icons' src='images/Home/CoreTeam/icon2-new.png' alt='in-icon' /></a>
                     <a href='mailto:shantanu.a.biswas@accenture.com'><img className='icons' src='images/Home/CoreTeam/icon3-new.png' alt='mail-icon' /></a>
                  </div>
               </div>
               <div className='team-card'>
                  <img className='lead-img' src='images/Home/CoreTeam/lead4.jpg' />
                  <div className='lead-details'>
                     <h4>Vanita Patil</h4>
                     <p>Lead-Learning Experiences, L&TT</p>
                     {/* <p> LKM</p> */}
                  </div>
                  <div className='contact-icons'>
                     <a target="_blank" href='https://people.accenture.com/People/user/vanitadevi.patil'><img className='icons' src='images/Home/CoreTeam/icon1-new.png' alt='user-icon' /></a>
                     <a target="_blank" href='https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHdDb9Ldx5-4QAAAYSegnaItTTqaSSzPtKrlXgX-Ef8LSIgChSbAB-iFIMlPPxUS9nES9vgj42Sn5pQeDJnD-wec001w_VTQIsNSEaCWVvXgH9aM_U2pzb6wqROEP_C4Dhe0M0=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fvanita-patil-71a18615%2F%3ForiginalSubdomain%3Din'><img className='icons' src='images/Home/CoreTeam/icon2-new.png' alt='in-icon' /></a>
                     <a href='mailto:vanitadevi.patil@accenture.com'><img className='icons' src='images/Home/CoreTeam/icon3-new.png' alt='mail-icon' /></a>
                  </div>
               </div>
               <div className='team-card'>
                  <img className='lead-img' src='images/Home/CoreTeam/lead5.jpg' />
                  <div className='lead-details'>
                     <h4>Manisha Surve</h4>
                     <p>Sustainability Learning & Knowledge Management, ATCI</p>
                     {/* <p> LKM</p> */}
                  </div>
                  <div className='contact-icons'>
                     <a target="_blank" href='https://people.accenture.com/People/user/manisha.a.surve'><img className='icons' src='images/Home/CoreTeam/icon1-new.png' alt='user-icon' /></a>
                     <a target="_blank" href='https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHdDb9Ldx5-4QAAAYSegnaItTTqaSSzPtKrlXgX-Ef8LSIgChSbAB-iFIMlPPxUS9nES9vgj42Sn5pQeDJnD-wec001w_VTQIsNSEaCWVvXgH9aM_U2pzb6wqROEP_C4Dhe0M0=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fvanita-patil-71a18615%2F%3ForiginalSubdomain%3Din'><img className='icons' src='images/Home/CoreTeam/icon2-new.png' alt='in-icon' /></a>
                     <a href='mailto:manisha.a.surve@accenture.com'><img className='icons' src='images/Home/CoreTeam/icon3-new.png' alt='mail-icon' /></a>
                  </div>
               </div>
               <div className='team-card'>
                  <img className='lead-img' src='images/Home/CoreTeam/lead6.png' />
                  <div className='lead-details'>
                     <h4>Sheela Ganesh</h4>
                     <p>Lead-Quality Engineering</p>
                     {/* <p> LKM</p> */}
                  </div>
                  <div className='contact-icons'>
                     <a target="_blank" href='https://people.accenture.com/People/user/sheela.ganesh.t'><img className='icons' src='images/Home/CoreTeam/icon1-new.png' alt='user-icon' /></a>
                     <a target="_blank" href='https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHdDb9Ldx5-4QAAAYSegnaItTTqaSSzPtKrlXgX-Ef8LSIgChSbAB-iFIMlPPxUS9nES9vgj42Sn5pQeDJnD-wec001w_VTQIsNSEaCWVvXgH9aM_U2pzb6wqROEP_C4Dhe0M0=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fvanita-patil-71a18615%2F%3ForiginalSubdomain%3Din'><img className='icons' src='images/Home/CoreTeam/icon2-new.png' alt='in-icon' /></a>
                     <a href='mailto:sheela.ganesh.t@accenture.com'><img className='icons' src='images/Home/CoreTeam/icon3-new.png' alt='mail-icon' /></a>
                  </div>
               </div>
               <div className='team-card'>
                  <img className='lead-img' src='images/Home/CoreTeam/lead7.png' />
                  <div className='lead-details'>
                     <h4>Monisha Bhattacharya</h4>
                     <p>Data Scientist Principal, L&TT</p>
                     {/* <p> LKM</p> */}
                  </div>
                  <div className='contact-icons'>
                     <a target="_blank" href='https://people.accenture.com/People/user/monisha.bhattacharya'><img className='icons' src='images/Home/CoreTeam/icon1-new.png' alt='user-icon' /></a>
                     <a target="_blank" href='https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHdDb9Ldx5-4QAAAYSegnaItTTqaSSzPtKrlXgX-Ef8LSIgChSbAB-iFIMlPPxUS9nES9vgj42Sn5pQeDJnD-wec001w_VTQIsNSEaCWVvXgH9aM_U2pzb6wqROEP_C4Dhe0M0=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fvanita-patil-71a18615%2F%3ForiginalSubdomain%3Din'><img className='icons' src='images/Home/CoreTeam/icon2-new.png' alt='in-icon' /></a>
                     <a href='mailto:monisha.bhattacharya@accenture.com'><img className='icons' src='images/Home/CoreTeam/icon3-new.png' alt='mail-icon' /></a>
                  </div>
               </div>
               <div className='team-card'>
                  <img className='lead-img' src='images/Home/CoreTeam/lead8.png' />
                  <div className='lead-details'>
                     <h4>Raji Raghunath</h4>
                     <p>Manager, L&TT</p>
                     {/* <p> LKM</p> */}
                  </div>
                  <div className='contact-icons'>
                     <a target="_blank" href='https://people.accenture.com/People/user/raji.raghunath'><img className='icons' src='images/Home/CoreTeam/icon1-new.png' alt='user-icon' /></a>
                     <a target="_blank" href='https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHdDb9Ldx5-4QAAAYSegnaItTTqaSSzPtKrlXgX-Ef8LSIgChSbAB-iFIMlPPxUS9nES9vgj42Sn5pQeDJnD-wec001w_VTQIsNSEaCWVvXgH9aM_U2pzb6wqROEP_C4Dhe0M0=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fvanita-patil-71a18615%2F%3ForiginalSubdomain%3Din'><img className='icons' src='images/Home/CoreTeam/icon2-new.png' alt='in-icon' /></a>
                     <a href='mailto:raji.raghunath@accenture.com'><img className='icons' src='images/Home/CoreTeam/icon3-new.png' alt='mail-icon' /></a>
                  </div>
               </div>
            </div>
         </div>
         <img src="images/Home/bg13.png" alt="" className='bg13' />
         <img src="images/Home/bg14.png" alt="" className='bg14' />
      </div>
   )
}

export default CoreTeam