import React from 'react';
import './Searchnav.css';
import WelcomeName from './WelcomeName';
import { AiOutlineHome } from "react-icons/ai";
import { Link, useLocation } from 'react-router-dom';

function Searchnav() {
    const location = useLocation();
    return (
        <>
        <div class="searchnav-container">
            <div class="searchnav-wrap">
                <div class="searchnav-row">
                    <div className='logo-container'>
                <img src={process.env.PUBLIC_URL + "/images/Acc-logo.png"} class="acc-logo" alt="logo" />
                <div className='verticalbar'></div>
                <p>Sustainability Research Cell</p>
                { location.pathname == '/feedbackdata' ? <><div className='verticalbar'></div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <Link to="/home"> <AiOutlineHome style={{ fontSize: '23px', color: '#6e0089', cursor: 'pointer'}}/> </Link></> : null }
                </div>
                    <div class="searchnav-box" >
                        
                        
                        {/* <p class="desktop-tag">Sustainability Research </p> */}
                        {/* <p class="mobile-tag">Sustainability Research</p> */}
                        <div className='user-container'>
                        <WelcomeName />
                        <img src={process.env.PUBLIC_URL + "/images/AvatarNew.png"} class="user-img" alt="user-image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Searchnav
