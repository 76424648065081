import React from 'react';
import HomeContent from '../function-components/HomeContent';

function Home() {
  return (
    <>
    <HomeContent />
    </>
  )
}

export default Home