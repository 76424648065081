import {useParams,Redirect} from "react-router-dom";
import SustainabilityNewsFeb from "../SustainabilityNewsFeb";
import SustainabilityNewsMar from "../SustainabilityNewsMar";
import SustainabilityNewsApr from "../SustainabilityNewsApr";
import SustainabilityNewsMay from "../SustainabilityNewsMay";
import SustainabilityNewsJun from "../SustainabilityNewsJune";
import SustainabilityNewsJul from "../SustainabilityNewsJuly";
import SustainabilityNewsAug from "../SustainabilityNewsAugust";
import SustainabilityNewsSep from "../SustainabilityNewsSept";
import SustainabilityNewsOct from "../SustainabilityNewsOct";
import SustainabilityNewsNov from "../SustainabilityNewsNov";

export default function SustainabilityNews() {
    const params = useParams();

    return <>
        {
        params.calendar === 'feb2023' ? <SustainabilityNewsFeb/> :
        params.calendar === 'mar2023' ? <SustainabilityNewsMar/> :
        params.calendar === 'apr2023' ? <SustainabilityNewsApr/> :
        params.calendar === 'may2023' ? <SustainabilityNewsMay/> :
        params.calendar === 'jun2023' ? <SustainabilityNewsJun/> :
        params.calendar === 'jul2023' ? <SustainabilityNewsJul/> :
        params.calendar === 'Aug2023' ? <SustainabilityNewsAug/> :
        params.calendar === 'Sep2023' ? <SustainabilityNewsSep/> :
        params.calendar === 'Oct2023' ? <SustainabilityNewsOct/> :
        params.calendar === 'Nov2023' ? <SustainabilityNewsNov/> :
        <Redirect to="/home" />
    }
    </>;
}