import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";
import './Navbarmenu.css';
import { HashLink } from 'react-router-hash-link';
import { useMsal } from "@azure/msal-react";

const Navbarmenu = () => {
    const [isMenu, setisMenu] = useState(false);
    const [isResponsiveclose, setResponsiveclose] = useState(false);
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    let loggedInEnterpriseId = '';
    if(activeAccount)
    loggedInEnterpriseId = activeAccount.idTokenClaims.enterpriseID; 
    const allowedUsersForFeedback = [
      'ankur.e.mishra',
      'h.srinivasan',
      'aluru.akash',
      'arpita.m.singh',
      'jeevadharani.ramanan',
      'varsha.a.tyagi',
      'reshmi.haridas.m',
      'uma.rani.battu',
      'aayushi.bharti',
      'rajath.mb',
      'd.d.choudhary',
      'shramika.s.ambolkar'
    ];

    const allowedUsersForAnalytics = [
        'ankur.e.mishra',
        'h.srinivasan',
        'aluru.akash',
        'arpita.m.singh',
        'jeevadharani.ramanan',
        'varsha.a.tyagi',
        'reshmi.haridas.m',
        'uma.rani.battu',
        'aayushi.bharti',
        'rajath.mb',
        'd.d.choudhary',
        'shramika.s.ambolkar',
        'vanitadevi.patil',
        'manisha.a.surve',
        'sheela.ganesh.t',
        'monisha.bhattacharya',
        'raji.raghunath'
      ];    

    const toggleClass = () => {
        setisMenu(isMenu === false ? true : false);
        setResponsiveclose(isResponsiveclose === false ? true : false);
    };
    let boxClass = ["main-menu"];
    if (isMenu) {
        boxClass.push('menuq2');
    } else {
        boxClass.push('');
    }

    return (
        <header className="header__middle">
            <div className="head-container">
                <div className="row">
                    <div className="header__middle__menus">
                        <nav className="main-nav " >
                            {/* Responsive Menu Button */}
                            {isResponsiveclose === true ? <>
                                <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiXCircle />   </span>
                            </> : <>
                                <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiAlignRight />   </span>
                            </>}
                            <ul className={boxClass.join(' ')}>
                                <li className="menu-item">
                                    <NavLink activeClassName='is-active' className="nav-links" onClick={toggleClass} to={`/home`}>
                                        {/* <img src="images/Home/home-icon.png" alt="" className='home-icon'/> */}
                                        Home
                                    </NavLink>
                                </li>
                                {/* <li className="menu-item">||</li> */}
                                {/* <li className="menu-item">
                                    <HashLink to='/home#sustainabilitybites' activeClassName='is-active' className="nav-links" onClick={toggleClass} exact>Sustainability Bites</HashLink>
                                </li> */}
                                <li className="menu-item">
                                    <HashLink to='/home#sustainabilitynews' activeClassName='is-active' className="nav-links" onClick={toggleClass} exact>Sustainability News <br /> From the World</HashLink>
                                </li>
                                {/* <li className="menu-item">||</li> */}
                                <li className="menu-item">
                                    <HashLink to='/home#entrepreneurshipseries' activeClassName='is-active' className="nav-links" onClick={toggleClass} exact>Sustainable Business <br /> Entrepreneurship Series</HashLink>
                                </li>
                                {/* <li className="menu-item">||</li> */}
                                <li className="menu-item">
                                    <HashLink to='/home#publications' activeClassName='is-active' className="nav-links" onClick={toggleClass} exact>Research <br /> Publications</HashLink>
                                </li>
                                {/* <li className="menu-item">||</li> */}
                                <li className="menu-item">
                                    <HashLink to='/home#whitepapersandarticles' activeClassName='is-active' className="nav-links" onClick={toggleClass} exact>White Papers <br /> and Articles</HashLink>
                                </li>
                                {/* <li className="menu-item">||</li> */}
                                <li className="menu-item">
                                    <HashLink to='/home#sustainabilityreels' activeClassName='is-active' className="nav-links" onClick={toggleClass} exact>Sustainability <br /> Reels</HashLink>
                                </li>
                                {/* <li className="menu-item">||</li> */}
                                <li className="menu-item">
                                    <HashLink to='/home#coreteam' activeClassName='is-active' className="nav-links" onClick={toggleClass} exact>Core <br /> Team</HashLink>
                                </li>
                                {/* <li className="menu-item">||</li> */}
                                { allowedUsersForFeedback.includes(loggedInEnterpriseId) ?
                                <li className="menu-item">
                                    <HashLink to='/feedbackdata' activeClassName='is-active' className="nav-links" onClick={toggleClass} exact>Admin</HashLink>
                                </li> 
                                 : "" }
                                { allowedUsersForAnalytics.includes(loggedInEnterpriseId) ?
                                    <li className="menu-item">
                                        <HashLink to='/views' activeClassName='is-active' className="nav-links" onClick={toggleClass} exact>Analytics</HashLink>
                                    </li>
                                : "" }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Navbarmenu
