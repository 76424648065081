import React from 'react';
import './SustainabilityReels.css';
import Carousel from "react-multi-carousel";
// import EntrepreneurshipTabs from '../function-components/EntrepreneurshipTabs'
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 992 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 991, min: 540 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 539, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

function SustainabilityReels() {
    return (
        <>
            <div className="SR-Container">
                <div className="SR-Wrap">
                    <h2><span>Sustainability Reels</span></h2>
                    <p>Engage with our dynamic and visually compelling content in this section, where our commitment to sustainability is vividly brought to life. These brief videos introduce you to some ideas on sustainability, demonstrating the practical application and tangible impact.</p>
                    <Carousel className='SR-card-carousel'
                        responsive={responsive}
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        arrows={true}
                        renderArrowsWhenDisabled={true}
                        keyBoardControl={true}
                    >
                        <div className='videoCard'>
                            <div className='videoImagebox'>
                                <a href="https://mediaexchange.accenture.com/media/t/1_uefcax1n" target='_blank'>
                                    <img src='images/Home/SustainabilityReels/Reel-0.png' className='img-responsive' />
                                    <h3 className='videoCaption'>Regenerative Design</h3>
                                </a>
                            </div>
                        </div>
                        <div className='videoCard'>
                            <div className='videoImagebox'>
                                <a href="https://mediaexchange.accenture.com/media/t/1_m0a6wtga" target='_blank'>
                                    <img src='images/Home/SustainabilityReels/Reel-2.png' className='img-responsive' />
                                    <h3 className='videoCaption'>Earth Overshoot Day</h3>
                                </a>
                            </div>
                        </div>
                        <div className='videoCard'>
                            <div className='videoImagebox'>
                                <a href="https://mediaexchange.accenture.com/media/t/1_114j41ee?st=0" target='_blank'>
                                    <img src='images/Home/SustainabilityReels/Reel-1.png' className='img-responsive' />
                                    <h3 className='videoCaption'>Companion Planting</h3>
                                </a>
                            </div>
                        </div>
                        <div className='videoCard'>
                            <div className='videoImagebox'>
                                <a href="https://mediaexchange.accenture.com/media/t/1_o50vmfx1" target='_blank'>
                                    <img src='images/Home/SustainabilityReels/Reel-3.png' className='img-responsive' />
                                    <h3 className='videoCaption'>Cradle to Cradle</h3>
                                </a>
                            </div>
                        </div>
                        {/* <div className='videoCard'>
                            <div className='videoImagebox'>
                                <a href="#" target='_blank'>
                                    <img src='images/Home/SustainabilityReels/Reel-1.png' className='img-responsive' />
                                    <h3 className='videoCaption'>Lorem Ipsum</h3>
                                </a>
                            </div>
                        </div> */}
                    </Carousel>

                </div>
                <img src="images/Home/bg11.png" alt="" className='bg11' />
                <img src="images/Home/bg12.png" alt="" className='bg12' />
            </div>
        </>
    )
}

export default SustainabilityReels