import React, { useEffect } from "react";
import "./SustainabilityNewsMay.css";
import "./SustainabilityNewsFeb.css";
import Feedback from '../function-components/Feedback';

const SustainabilityNewsMay = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div className="sustainability_main">
                <div className="sustainability_header">
                    <h2>Sustainability News From the World - May 2023 Edition</h2>
                </div>
                <div className='banner-container'>
                    <div className='home-banner'   >
                        <img src={process.env.PUBLIC_URL + "/images/Home/Sustainability_News/sustainability_news_banner.png"} className="sust_news_gif_img" />
                    </div>
                </div>
                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <div className="sustainability_mid_container">
                    <h2>LEADING WITH SUSTAINABILITY: TRANSLATING INTENT INTO ACTION</h2>
                    <p>As we cross the halfway mark in FY23, we want to thank you for helping us shape the sustainability agenda for ourselves and our clients to deliver value. It is indeed heartening to see such enthusiasm to bring sustainability into the heart of all conversations. </p><br />
                    <p>We continue to work toward our goal of translating sustainability from intent to action. As individuals and organizations around the world are stepping up to meet the sustainability challenge, it's now more critical than ever. The <b>ATCI Sustainability Research Cell</b> brings you the latest updates covering technology, industry, clients, Accenture, and the larger ecosystem. Hope you enjoy reading it and find inspiring ideas that you can apply to your ecosystem.</p>
                    <p className="sustainability_para">You can also <a className="sustainability_para_a" href="mailto:ATCI.Sust.Research@accenture.com?subject=ATCI Sustainability Research">share your ideas and suggestions</a> with us. We would love to hear more about your “sustainability stories” and feature them in our newsletter.</p><br />
                    <p>Let’s keep creating incredible conversations.</p>
                    <p className="sustainability_para">Regards,</p>
                    <p><strong>Shridhar Rajgopalan</strong>, Lead - Sustainability and Song, ATCI</p>
                    <p><strong>Anupama Nithyanand</strong>, Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI</p>
                </div>
                <div className="sustainability_li_container">
                    <h3>SUSTAINABILITY NEWS FROM THE WORLD - MAY 2023 EDITION</h3>
                    <div className="sustainability_wrap">
                        <ul>
                            <li>What's making news?</li>
                            <li>Can't miss: Trends & Technology</li>
                            <li>In-depth</li>
                            <li>Must-reads…</li>
                            <li>Soulful living!</li>
                            <li>Did you know</li>
                            <li>Sustainability Priority Learnings</li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_highlight_div">
                    <h4>What's making news?</h4>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon1.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Explore the Technology Vision 2023</h4>
                            <p>Can we combine physical sciences and digital technology to create sustainable solutions? Can building shared solutions accelerate sustainability goals? Explore this year's <a className="hightlight_b" href="https://www.accenture.com/content/dam/accenture/final/accenture-com/a-com-custom-component/iconic/document/Accenture-Technology-Vision-2023-Full-Report.pdf" target={"_blank"}>Technology Vision</a> for more.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon2.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Learn about Accenture's carbon offset policy</h4>
                            <p>Know how to respond when clients or suppliers approach you with an opportunity to engage in carbon offset programs which may be outside of Accenture’s Environment & Sustainability global program. <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=398&p=34556" target={"_blank"}>Get the guidance now</a>.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon3.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">New in SQ learning!</h4>
                            <p>Explore the new <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=282&p=36312" target={"_blank"}>Net-Zero Transitions SQ Learning module</a> to learn about circular economy and value chains, green software, sustainable performance measurement and more.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon4.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">New in digital twin technology</h4>
                            <p><a className="hightlight_b" href="https://newsroom.accenture.com/news/accenture-invests-in-virtonomy-to-help-medical-technology-companies-use-digital-twin-technology-to-accelerate-time-to-market-for-medical-devices.htm" target={"_blank"}>Accenture invests in Virtonomy</a> to help medical technology companies use digital twin technology to accelerate time to market for medical devices.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon5.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Pushing the pedal with akzente at BMW</h4>
                            <p><a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=282&p=36374&caturl=aHR0cHM6Ly9pbi5hY2NlbnR1cmUuY29tL3N1c3RhaW5hYmlsaXR5L25ld3Mv" target={"_blank"}>Learn how</a>  synergy from our akzente acquisition is helping BMW drive better ESG reporting to meet a new EU directive.</p>
                        </div>
                    </div>
                    {/* <div>
                    <h4 className="highlight_h4_tag">Sustainable Development Goals: How far we have reached</h4>
                    <p><i>Mint - Oct 02, 2022</i></p>
                    <p>Taking stock of how the world and India is faring on the SDG goals that they have set for 2030. Considering the covid and other uncertainties that unfolded, Will focusing on specific targets be more effective? the article delves into the progress statistics…</p>
                    <a className="hightlight_a" href="https://www.livemint.com/opinion/online-views/sustainable-development-goals-how-india-and-the-world-are-doing-11664731506364.html" target={"_blank"}>Full Article</a>
                </div> */}
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Can't miss: Trends & Technology</h4>
                    <div className="midsection_li">
                        <div className="trends-sec-row">
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/trends-icon1.png"} alt="" />
                                <p>How can the future generations be equipped for global change? By incorporating the STEM approach for sustainability—so they are equipped with the right knowledge early on. | <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/04/why-sustainability-must-become-an-integral-part-of-stem-education/" target={"_blank"}>Read more</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/trends-icon2.png"} alt="" />
                                <p>How can AI, IoT and blockchain be used to drive sustainability? Here are some specific use cases with potential for impact. | <a className="mid_section_a" href="https://www.informationweek.com/big-data/how-to-drive-sustainability-through-technology" target={"_blank"}>Know more</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/trends-icon3.png"} alt="" />
                                <p>How can we create a #CircularEconomy that supports global sustainability goals and helps meet the ambitious net-zero targets? | <a className="mid_section_a" href="https://www.reuters.com/business/sustainable-business/comment-why-it-pays-join-dots-between-circular-economy-climate-2023-04-03/" target={"_blank"}>Read more</a></p>
                            </div>
                        </div>
                        <div className="vertical-line-may"></div>
                        <div className="trends-sec-row">
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/trends-icon4.png"} alt="" />
                                <p>We have the technology to fight climate change and improve the energy security globally. What we need are investments and leadership to lead the change. | World Economic Forum | <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/04/energy-security-doesn-t-have-to-cost-us-the-planet/" target={"_blank"}>Explore</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/trends-icon5.png"} alt="" />
                                <p>How can we truly standardize and secure cloud-native technologies for more sustainable software application development? | Forbes | <a className="mid_section_a" href="https://www.forbes.com/sites/adrianbridgwater/2023/04/20/cncf-sustainable-software-projects-breed-eco-sustainable-computing-systems/?sh=60346fa44de2" target={"_blank"}>Read more</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/trends-icon6.png"} alt="" />
                                <p>Here’s a new report on how AI-as-a-service (AIaaS) platforms are transforming businesses. | Nasscom community | <a className="mid_section_a" href="https://community.nasscom.in/communities/ai/riding-wave-ai-depth-look-platforms-transforming-businesses" target={"_blank"}>Know more</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">In-depth</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>While businesses globally are waking up to the importance of net zero carbon emissions, the ambition isn’t matched yet by necessary action. | <a className="mid_section_a" href="https://www.accenture.com/content/dam/accenture/final/capabilities/strategy-and-consulting/strategy/document/Accenture-Net-Zero-By-2050-Global-Report-2022.pdf#zoom=40" target={"_blank"}>Accenture report</a></li>
                            <li>Ethical (generative) AI? The debate is more complex than we can imagine. | <a className="mid_section_a" href="https://diginomica.com/why-struggle-ethics-generative-ai-more-complex-we-imagine" target={"_blank"}>Diginomica</a></li>
                            <li>Generative AI and ChatGPT have taken the world by storm. But can they also help protect our planet? | <a className="mid_section_a" href="https://earth911.com/eco-tech/can-generative-ai-chatgpt-help-protect-our-planet/" target={"_blank"}>Read on</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Must-reads…</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>How can we usher in a “green industrial age“ for sustainable development? With urgent investments and global political will. | <a className="mid_section_a" href="https://news.un.org/en/story/2023/04/1135367" target={"_blank"}>Know more</a></li>
                            <li>#WorldEarthDay: Green finance helps increase the level of global financial flows to better manage environmental and social risks. Here’s why we need it so much. | <a className="mid_section_a" href="https://economictimes.indiatimes.com/industry/renewables/world-earth-day-what-is-green-finance-and-why-do-we-need-it-so-much/articleshow/99688450.cms?from=mdr" target={"_blank"}>Read more</a></li>
                            <li>Here are five ways in which generative AI can deliver transformative results for supply chain businesses, improving transparency, efficiency and resilience. | <a className="mid_section_a" href="https://www.supplychaindive.com/news/does-chatgpt-offer-real-value-to-the-supply-chain-1/645257/" target={"_blank"}>Know more</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    {/* <hr /> */}
                </div>

                {/* <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Reports and Magazines</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>5 charts from the IPCC, Intergovernmental Panel on Climate Change report highlights risks of lower temperatures due to climate change <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/03/climate-change-ipcc-emissions-risks-net-zero/" target={"_blank"}>Read more...</a></li>
                            <li>What are nature climate solutions and how it can help to reduce climate change and bio-diversity loss. <a className="mid_section_a" href="https://www3.weforum.org/docs/WEF_NCSA_NCS_for_Corporates_2021.pdf" target={"_blank"}>Read more...</a></li>
                            <li>Understanding progress in water related ecosystem, SDGs and global data availability. <a className="mid_section_a" href="https://www.unep.org/resources/report/measuring-progress-water-related-ecosystems-and-sdgs" target={"_blank"}>Read more...</a></li>
                            <li>A study on non-native plants and its rapid growth highlights need for monitoring schemes to understand long term impact. <a className="mid_section_a" href="https://www.nature.com/articles/d44151-023-00034-y" target={"_blank"}>Read more...</a></li>
                            <li>Only second ever UN conference on water saw host of countries discuss water disasters related to climate change. <a className="mid_section_a" href="https://www.theguardian.com/environment/2023/mar/23/water-un-conference-climate-crisis-disasters" target={"_blank"}>Read more...</a></li>
                        </ul>
                    </div>
                </div> */}

                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Soulful Living!</h4>
                    <div className="midsec_box">
                        <div className="midsection_para">
                            {/* <p><strong>Innovative use of AI to Assess Potholes issues on City Roads by a Bengaluru startup</strong></p> */}
                            <p className="mid_section_p">Can you imagine paying your child’s school lessons with plastic waste? Yet, that is exactly what is happening at a school in Assam’s Pamohi village! Not only the kids go to school clutching a bag of plastic waste in exchange for their day’s lessons, they also learn how to build eco-bricks from the plastic waste, which are used for building projects. <br /><br /><a className="mid_section_a" href="https://www.theguardian.com/global-development/2020/nov/25/the-indian-school-where-students-pay-for-lessons-with-plastic-waste#:~:text=The%20Indian%20school%20where%20students%20pay%20for%20lessons%20with%20plastic%20waste,-Villagers%20once%20burned&text=Every%20morning%2C%20students%20in%20Assam's,will%20get%20their%20day's%20lessons." target={"_blank"}>Know more about this fascinating initiative</a></p>
                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/ppl_img_may.png"} alt="sust_news" className="sust_news_img" />
                        </div>
                    </div>

                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>

                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Did You Know</h4>
                    <div className="lastsection_may">
                        <div className="lastsection_may_1stCard">
                            <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/dyk-1.png"} alt="" className="dyk-1"/>
                            <h4 className="lastsection_may_h4">4,000</h4>
                            {/* <p className="diduknow_p">Earth's surface mainly consists of 3 things: hydrosphere, biosphere & atmosphere.</p> */}
                            <p className="diduknow_p">A modern glass bottle takes 4,000 years or more to decompose. | <a className="mid_section_a" href="https://www.activesustainability.com/environment/waste-planet-digest-how-long-it-takes-to-decay/?_adin=02021864894" target={"_blank"}>Read on</a></p>
                        </div>
                        <div className="lastsection_may_2ndCard">
                            <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/dyk-2.png"} alt="" className="dyk-2"/>
                            <h4 className="lastsection_may_h4">50,000</h4>
                            <p className="diduknow_p">During the time it takes you to read this sentence, 50,000 Aluminium cans (30 ml) are made. | <a className="mid_section_a" href="https://www.fit.edu/facilities/sustainability/recycling/interesting-facts-about-recycling/#:~:text=More%20aluminum%20goes%20into%20beverage,aluminum%20can%20can%20be%20recycled." target={"_blank"}>Know more</a></p>
                        </div>
                        <div className="lastsection_may_3rdCard">
                            <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/dyk-3.png"} alt="" className="dyk-3"/>
                            <h4 className="lastsection_may_h4">2,000,000</h4>
                            <p className="diduknow_p">A whopping 2 million plastic bags are used every minute worldwide. | <a className="mid_section_a" href="https://givingcompass.org/article/10-facts-about-plastic-pollution-you-absolutely-need-to-know" target={"_blank"}>Find out more</a></p>
                        </div>
                    </div>
                </div>

                <div className="priority-learnings-container">
                    <h4 className="midsection_h4">Have you checked out the Sustainability Priority Learnings, yet?</h4>
                    <div className="priority-learnings-wrap">
                        <div className="priority-learnings-row">
                            <div className="priority-learnings-box">
                                <p>Course 1: <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/2020982" target="_blank">Green Software for Practitioners</a></p>
                                <p>|</p>
                                <p>Course 2: <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/1795845" target="_blank">Accenture Green Software</a></p>
                            </div>
                            <p>Here's a <a href="https://mediaexchange.accenture.com/media/t/1_n0hrgtw9?st=0" target="_blank">step-by-step video guide</a> on how to access Sustainability Priority Learnings.</p>
                            <p>Have queries? Check out the <a href="https://collections.accenture.com/FileViewer.aspx?f=4e9fb50d-00bd-4124-9212-33cdb7495382">FAQs here</a>.</p>
                        </div>
                    </div>
                </div>

                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <Feedback subject={"Sustainability News From The World - May 2023 Edition"} />

            </div>
        </>
    )
}

export default SustainabilityNewsMay;