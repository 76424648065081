import React, { useState } from 'react';
import './Entrepreneurship.css';
// import EntrepreneurshipTabs from '../function-components/EntrepreneurshipTabs'

function Entrepreneurship() {

    // const [yearEdition2, setYearEdition2] = useState('current');
    return (
        <>
            <div className="entrepreneurship-container">
                <div className="entrepreneurship-wrap">
                    <h2><span>Sustainable Business Entrepreneurship Series</span></h2>
                    <p>The <span className='text-highlighter'>Sustainable Business Entrepreneurship Series</span> brings to you inspirational stories and perspectives from people who envision the future, go ahead to create it, and make an impact. Each of these sessions has something we can take away and apply; also benefit from the thoughtfulness and knowledge of the speakers. The diverse topics cut across the <a href="https://www.undp.org/sustainable-development-goals?utm_source=EN&utm_medium=GSR&utm_content=US_UNDP_PaidSearch_Brand_English&utm_campaign=CENTRAL&c_src=CENTRAL&c_src2=GSR&gclid=Cj0KCQiA8t2eBhDeARIsAAVEga0wn2wYvtbbhI1H-HofbJHtWyCLUUgwnOq1qX3VAI7OIo9VJuKnzgsaAo5aEALw_wcB" target="_blank"> 17 UN SDGs</a>, and we do hope they would open up your minds to new thoughts and possibilities! </p>
                    <div className="entrepreneurship-box">

                        <div className='entrepreneurship-box-row'>
                            <p className='entrepreneurship-box-row-para active' >2023 Editions</p>
                            {/* <p className='vertical-line'></p>
                            <p className={yearEdition2 == 'previous' ? 'entrepreneurship-box-row-para active' : 'entrepreneurship-box-row-para inactive'} onClick={() => setYearEdition2('previous')}>Previous Year Edition (2022)</p> */}
                        </div>
                        <hr></hr>

                        {/* <EntrepreneurshipTabs/> */}
                        {/* {yearEdition2 == 'current' ? ( */}
                        <div className="entrepreneur-wrap">

                            <div className="entrepreneur-row">
                                <div className="entrepreneur-box box1">
                                    <a href="/organicfarms" target='_blank' className='entrepreneur-anchortag'>
                                        <img src="images/Home/Entrepreneur/card1-new.png" alt="card" className='card-imgs' />
                                        <div className="imageText">
                                            <h3 className='margin-T'>Satyajit Hange, Agripreneur, Cofounder-Two Brothers Organic Farms</h3>
                                            <h3 className='topic_header'>Topic: Sustainable Regenerative Farming</h3>
                                        </div>
                                    </a>
                                </div>
                                <h5><a href="images/Satyajit_Hange_TBOF.pdf#toolbar=0" target='_blank'>Know more about his journey</a></h5>
                            </div>
                            {/* <div className="entrepreneur-row">
                                <div className="entrepreneur-box box2">
                                    <img src="images/Home/Entrepreneur/card2.png" alt="card" className='card-imgs' />
                                    <h3>Lorem Ipsume</h3>
                                </div>
                            </div> */}
                            <div className="entrepreneur-row">
                                <div className="entrepreneur-box box2">
                                    <a href="/PondMan" target='_blank' className='entrepreneur-anchortag'>
                                        <img src="images/Home/Entrepreneur/card3.png" alt="card" className='card-imgs' />
                                        <div className="imageText">
                                            <h3 className='margin-T'>Ramveer Tanwar (PondMan), Founder-Say Earth NGO</h3>
                                            <h3 className='topic_header'>Topic: Pond Restoration & Rejuvenation</h3>
                                        </div>
                                    </a>
                                </div>
                                <h5><a href="images/Ramveer_Tanwar_PondMan.pdf#toolbar=0" target='_blank'>Know more about his journey</a></h5>
                            </div>
                            <div className="entrepreneur-row">
                                <div className="entrepreneur-box box3">
                                    <a href="/sankalptarufoundation" target='_blank' className='entrepreneur-anchortag'>
                                        <img src="images/Home/Entrepreneur/card_3.png" alt="card" className='card-imgs' />
                                        <div className="imageText">
                                            <h3 className='margin-T'>Apurva Bhandari, CEO, and Founder of SankalpTaru Foundation</h3>
                                            <h3 className='topic_header'>Topic: Afforestation and Environmental Sustainability</h3>
                                        </div>
                                    </a>
                                </div>
                                <h5><a href="images/Apurva Bhandari Profile.pdf#toolbar=0" target='_blank'>Know more about his journey</a>
                                    <a href="https://mediaexchange.accenture.com/media/t/1_jauw2xqh?st=0" target='_blank'><img src="images/Home/Entrepreneur/recording_img.png" alt="" className="recording_icon" /></a></h5>
                            </div>

                        </div>
                        <div className="entrepreneur-wrap1">

                            <div className="entrepreneur-row">
                                <div className="entrepreneur-box box1">
                                    <a href="/GayatriMenonFirstSession" target='_blank' className='entrepreneur-anchortag'>
                                        <img src="images/Home/Entrepreneur/Dr_new_image.png" alt="card" className='card-imgs' />
                                        <div className="imageText">
                                            <h3 className='margin-T'>Dr. Gayatri Menon, Principal Faculty-National Institute of Design</h3>
                                            <h3 className='topic_header'>Topic: Sustainability and Innovation</h3>
                                        </div>
                                    </a>
                                </div>
                                <h5><a href="images/Gayathri Menon.pdf#toolbar=0" target='_blank'>Know more about her journey</a></h5>
                            </div>
                            <div className="entrepreneur-row">
                                <div className="entrepreneur-box box1">
                                    <a href="/GayatriMenon" target='_blank' className='entrepreneur-anchortag'>
                                        <img src="images/Home/Entrepreneur/Dr_new_image.png" alt="card" className='card-imgs' />
                                        <div className="imageText">
                                            <h3 className='margin-T'>Dr. Gayatri Menon, Principal Faculty-National Institute of Design</h3>
                                            <h3 className='topic_header'>Topic: Advanced Sustainability Frameworks</h3>
                                        </div>
                                    </a>
                                </div>
                                <h5><a href="images/Gayathri Menon.pdf#toolbar=0" target='_blank'>Know more about her journey</a></h5>
                            </div>
                            <div className="entrepreneur-row">
                                <div className="entrepreneur-box box1">
                                    <a href="/SNOmkar" target='_blank' className='entrepreneur-anchortag'>
                                        <img src="images/Home/Entrepreneur/sn-omkar-new.png" alt="card" className='card-imgs' />
                                        <div className="imageText">
                                            <h3 className='margin-T'>Dr. S N Omkar, Chief Research Scientist at IISc</h3>
                                            <h3 className='topic_header'>Topic: Self-Sustainability-Managing Time and Stress</h3>
                                        </div>
                                    </a>
                                </div>
                                <h5><a href="images/Omkar S N Profile.pdf#toolbar=0" target='_blank'>Know more about his journey</a>
                                <a href="https://mediaexchange.accenture.com/media/t/1_3m5hmkyf?st=0" target='_blank'><img src="images/Home/Entrepreneur/recording_img.png" alt="" className="recording_icon" /></a></h5>
                            </div>

                            <div className="entrepreneur-row">
                                <div className="entrepreneur-box box1">
                                    <a href="/ankurWarikoo" target='_blank' className='entrepreneur-anchortag'>
                                        <img src="images/Home/Entrepreneur/ankurWarikoo.png" alt="card" className='card-imgs' />
                                        <div className="imageText">
                                            <h3 className='margin-T'>Ankur Warikoo, Founder, nearbuy.com</h3>
                                            <h3 className='topic_header'>Topic: Growing Sustainably</h3>
                                        </div>
                                    </a>
                                </div>
                                {/* <h5><a href="images/Satyajit_Hange_TBOF.pdf#toolbar=0" target='_blank'>Know more about his journey</a></h5> */}
                            </div>

                            {/* <div className="entrepreneur-row">
                                <div className="entrepreneur-box box2">
                                    <img src="images/Home/Entrepreneur/card2.png" alt="card" className='card-imgs' />
                                    <h3>Lorem Ipsume</h3>
                                </div>
                            </div> */}
                        </div>
                        {/* ) : (
                            <div>
                                <br /> <br /> <br /> <br /> <br /><br /> <br />
                            </div>
                        )
                        } */}
                    </div>
                </div>
                <img src="images/Home/bg5.png" alt="" className='bg5' />
                <img src="images/Home/bg6.png" alt="" className='bg6' />
            </div>
        </>
    )
}

export default Entrepreneurship