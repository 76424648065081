import React, { useEffect } from "react";
import "./SustainabilityNewsJune.css";
import "./SustainabilityNewsFeb.css";
import "./SustainabilityNewsJuly.css";
import "./SustainabilityNewsAugust.css";
import Feedback from './Feedback';
import { Link } from 'react-router-dom';

const SustainabilityNewsOct = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div className="sustainability_main">
                <div className="sustainability_header">
                    <h2>Sustainability News From the World - OCTOBER 2023 Edition</h2>
                </div>
                <div className='banner-container'>
                    <div className='home-banner'   >
                        <img src={process.env.PUBLIC_URL + "/images/Home/Sustainability_News/sustainability_news_banner.png"} className="sust_news_gif_img" />
                    </div>
                </div>
                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <div className="sustainability_mid_container">
                    <h2>LEADING WITH SUSTAINABILITY: TRANSLATING INTENT INTO ACTION</h2>
                    <p>As we continue to work toward our goal of translating sustainability from intent to action, we want to thank you for helping us shape the sustainability agenda for ourselves and our clients to deliver value.</p><br />
                    <p>It is indeed heartening to see such enthusiasm to bring sustainability into the heart of all conversations. Organizations around the world are stepping up to meet the sustainability challenge. The <b>ATCI Sustainability Research Cell</b> brings you the latest updates covering technology, industry, clients, Accenture, and the larger ecosystem. Hope you enjoy reading it and find inspiring ideas that you can apply to your ecosystem.</p>
                    <p className="sustainability_para">You can also <a className="sustainability_para_a" href="mailto:ATCI.Sust.Research@accenture.com?subject=ATCI Sustainability Research">share your ideas and suggestions</a> with us. We would love to hear more about your “sustainability stories” and feature them in our newsletter.</p><br />
                    <p>Let’s keep creating incredible conversations.</p>
                    <p className="sustainability_para">Regards,</p>
                    <p><strong>Shridhar Rajgopalan</strong>, Lead - Sustainability and Song, ATCI</p>
                    <p><strong>Anupama Nithyanand</strong>, Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI</p>
                </div>
                <div className="sustainability_li_container">
                    <h3>SUSTAINABILITY NEWS FROM THE WORLD - OCTOBER 2023 EDITION</h3>
                    <div className="sustainability_wrap">
                        <ul>
                            <li>What's making news?</li>
                            <li>Can't miss: Trends & Technology</li>
                            <li>In-depth</li>
                            <li>Must-reads…</li>
                            <li>Soulful living!</li>
                            <li>Did you know</li>
                            <li>Sustainability Priority Learnings</li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_highlight_div">
                    <h4>What's making news?</h4>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon1.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Latest in the Embed Sustainability blog series</h4>
                            <p><a className="hightlight_b" href="https://www.accenture.com/us-en/blogs/sustainability/rebalancing-books-nature-biodiversity" target={"_blank"}>Read the latest blog</a> on how to create a win-win situation for business and society through increased investments, business value, and engagement.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon2.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Alert: New sustainability solutions tablets</h4>
                            <p>We have introduced seven new solution tablets covering strategy, energy, value chain, M&E, and more. <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=282&p=36865&caturl=aHR0cHM6Ly9pbi5hY2NlbnR1cmUuY29tL3N1c3RhaW5hYmlsaXR5L25ld3Mv" target={"_blank"}>Head this way</a> to explore the awesome new solutions tablets that you can take to your clients!</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon3.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Driving deep, sustainable change</h4>
                            <p><a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=290&p=17707&caturl=aHR0cHM6Ly9pbi5hY2NlbnR1cmUuY29tL3N1c3RhaW5hYmlsaXR5L25ld3Mv" target={"_blank"}>Know how</a> we are taking on some of the world’s most pressing challenges such as poverty, climate change, or inequality by teaming up with Global Citizen.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon4.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">The UNGC – Accenture Global Private Sector Stocktake report is out</h4>
                            <p><a className="hightlight_b" href="https://www.accenture.com/us-en/insights/sustainability/getting-sdgs-back-on-track?c=acn_glb_ungc2023-stocktsprinklradvocac_13969664&n=smc_0923&abc=ungc+2023+-+stocktake_advocacy_100004614202056&linkId=100000218975935" target={"_blank"}>Read</a> the new report for metrics on the private sector’s strongest contributions and negative impacts to each of the 17 SDGs – and a clear plan of action on how businesses can propel us toward meeting the SDGs by 2030.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon5.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Gearing up for an event? Here’s how to reduce emissions</h4>
                            <p>From a range of apps to cut down on paper usage during events to alternative locations and meeting spaces that may offer emissions savings…<a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=626&p=3787&caturl=aHR0cHM6Ly9pbi5hY2NlbnR1cmUuY29tL3N1c3RhaW5hYmlsaXR5L25ld3Mv" target={"_blank"}>explore all that you can do</a> for more sustainable meetings and events.</p>
                        </div>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Can't miss: Trends & Technology</h4>
                    <div className="midsection_li">
                        <div className="trends-sec-row">
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon4.png"} alt="" />
                                <p>From carbon capture and storage to circular economy to green cloud computing, sustainable technology makes it to the top five breakthrough trends of 2023. | <a className="mid_section_a" href="https://www.forbes.com/sites/bernardmarr/2023/09/11/the-top-5-tech-trends-in-2024-everyone-must-be-ready-for/?sh=63b7a9489a6b" target={"_blank"}>Forbes</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon6.png"} alt="" />
                                <p>What can the construction sector “avoid, shift and improve” to cut carbon emissions? | <a className="mid_section_a" href="https://www.unep.org/news-and-stories/press-release/un-plan-promises-massive-emission-cuts-construction-sector-most" target={"_blank"}>Explore</a></p>
                            </div>     
                        </div>
                        <div className="vertical-line-may"></div>
                        <div className="trends-sec-row">
                        <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon7.png"} alt="" />
                                <p>Learn how sustainable data storage solutions are helping corporates cut emissions and drive environmental responsibility. | <a className="mid_section_a" href="https://www.itprotoday.com/storage/how-sustainable-data-storage-can-reduce-corporate-emissions" target={"_blank"}>Read on</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon8.png"} alt="" />
                                <p>Explore the 25 must-know emerging technologies that can accelerate innovation. | <a className="mid_section_a" href="https://www.gartner.com/en/articles/what-s-new-in-the-2023-gartner-hype-cycle-for-emerging-technologies" target={"_blank"}>Gartner</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">In-depth</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>Regenerative agriculture has a massive potential for managing carbon and biodiversity impact. But it needs public-private synergies to take off in a big way. | <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/09/unlocking-the-potential-of-regenerative-agriculture-through-public-private-synergies/" target={"_blank"}>Know more</a></li>
                            <li>Explore how IoT innovations are leading to eco-conscious urban development and sustainable urban future. | <a className="mid_section_a" href="https://www.iotforall.com/embracing-sustainable-smart-cities-a-journey-towards-a-greener-future" target={"_blank"}>Read on</a></li>
                            <li>Is AI sustainable? Perhaps, not yet. Here are the top 10 AI blunders we are living with every day. | <a className="mid_section_a" href="https://www.forbes.com/sites/eliamdur/2023/09/20/10-great-big-common-ai-blunders-dont-do-another-ai-query-until-you-read-this/?traffic_source=Connatix&sh=662664086c28" target={"_blank"}>Know more</a></li>
                            <li>Cloud sustainability is one of the top four emerging technologies that CIOs must pay attention to. | <a className="mid_section_a" href="https://www.itprotoday.com/industry-perspectives/4-emerging-technologies-cios-watch-2023#close-modal" target='_blank'>Gartner</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Must-reads…</h4>
                    <div className="midsection_li_june">
                        <ul>
                            <li>Rubbing salt into plastics…literally! Researchers have discovered that table salt can offer a simple, cost-effective solution to recycle plastics. It carries a massive potential for sustainable plastic recycling. | <a className="mid_section_a" href="https://www.openaccessgovernment.org/a-sustainable-future-unlocking-plastic-recycling-with-table-salt/166224/" target={"_blank"}>Read more</a></li>
                            <li>Innovation: How oysters can help keep you cool in summers. Oyster waste can be repurposed to replace the traditional calcium in its paint for rooftops. | <a className="mid_section_a" href="https://www.euronews.com/green/2023/08/23/meet-the-company-using-discarded-oyster-shells-to-cut-energy-costs-and-keep-frances-buildi" target={"_blank"}>Know more</a></li>
                            <li>Bio solutions to bring the land back to life after wildfires: Fungi that can eat methane. | <a className="mid_section_a" href="https://www.openaccessgovernment.org/mingu-microbes-wildfires-climate-change-methane/152852/" target={"_blank"}>Read on</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    {/* <hr /> */}
                </div>

                {/* <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Reports and Magazines</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>5 charts from the IPCC, Intergovernmental Panel on Climate Change report highlights risks of lower temperatures due to climate change <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/03/climate-change-ipcc-emissions-risks-net-zero/" target={"_blank"}>Read more...</a></li>
                            <li>What are nature climate solutions and how it can help to reduce climate change and bio-diversity loss. <a className="mid_section_a" href="https://www3.weforum.org/docs/WEF_NCSA_NCS_for_Corporates_2021.pdf" target={"_blank"}>Read more...</a></li>
                            <li>Understanding progress in water related ecosystem, SDGs and global data availability. <a className="mid_section_a" href="https://www.unep.org/resources/report/measuring-progress-water-related-ecosystems-and-sdgs" target={"_blank"}>Read more...</a></li>
                            <li>A study on non-native plants and its rapid growth highlights need for monitoring schemes to understand long term impact. <a className="mid_section_a" href="https://www.nature.com/articles/d44151-023-00034-y" target={"_blank"}>Read more...</a></li>
                            <li>Only second ever UN conference on water saw host of countries discuss water disasters related to climate change. <a className="mid_section_a" href="https://www.theguardian.com/environment/2023/mar/23/water-un-conference-climate-crisis-disasters" target={"_blank"}>Read more...</a></li>
                        </ul>
                    </div>
                </div> */}

                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Soulful Living!</h4>
                    <div className="midsec_box">
                        <div className="midsection_para_aug">
                            {/* <p><strong>Innovative use of AI to Assess Potholes issues on City Roads by a Bengaluru startup</strong></p> */}
                            <p className="mid_section_p">Meet Bheraram Bhakhar, the "Tree Teacher," who planted over 428,000 trees, transforming barren land into a thriving forest and exemplifying the profound impact of sustainability for our planet through his extraordinary journey. | <a className="mid_section_a" href="https://mad4india.com/environment/tree-teacher-of-rajasthan/" target="_blank">Know more</a></p>
                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/soulful_img_oct.png"} alt="sust_news" className="sust_news_img" />
                        </div>
                    </div>

                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>

                <div className="sustainability_mid_section_july">
                    <h4 className="midsection_h4">Did You Know</h4>
                    <div className="lastsection_march">
                        <div className="lastsection_march_1stCard">
                            <h4 className="lastsection_march_h4">500,000</h4>
                            {/* <p className="diduknow_p">Earth's surface mainly consists of 3 things: hydrosphere, biosphere & atmosphere.</p> */}
                            <p className="diduknow_p_sept">It takes nearly 500,000 litres of water to extract just 1 kg of gold. | <a className="mid_section_a" href="https://umadotc.wordpress.com/2017/03/12/sustainability-facts/" target={"_blank"}>Read on</a></p>
                        </div>
                        <div className="lastsection_march_2ndCard">
                            <h4 className="lastsection_march_h4">3.6 billion</h4>
                            <p className="diduknow_p_sept">UN: 3.6 billion people have inadequate access to water for at least one month a year. | <a className="mid_section_a" href="https://www.activesustainability.com/water/droughts-2023/?_adin=02021864894" target={"_blank"}>Know more</a></p>
                        </div>
                        <div className="lastsection_march_3rdCard">
                            <h4 className="lastsection_march_h4">1 million</h4>
                            <p className="diduknow_p_sept">Pollution kills more than 1 million seabirds and 100 million mammals every year. | <a className="mid_section_a" href="https://www.conserve-energy-future.com/various-pollution-facts.php" target={"_blank"}>Find out more</a></p>
                        </div>
                    </div>
                </div>

                <div className="sustainability_hr_div">
                    <hr />
                </div>

                <div className="sustainability_quiz_section">
                <h4 className="midsection_h4">The Green Challenge: Sustainability Quiz</h4>
                <p className="quiz_para">Think you have got what it takes to be a sustainability superstar? Fun quiz to find out.</p>
                <div className="quiz_section">
                <a className="quiz_link" href="https://www.menti.com/al2jier1gpdb/0" target="_blank">
                    Click here to start the quiz
                </a>
                </div>
                </div>

                <div className="sustainability_hr_div_quiz">
                    <hr />
                </div>

                <div className="priority-learnings-container_july">
                    <h4 className="midsection_h4">Have you checked out the Sustainability Priority Learnings, yet?</h4>
                    <div className="priority-learnings-wrap">
                        <div className="priority-learnings-row">
                            <div className="priority-learnings-box">
                                <p>Course 1: <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/2020982" target="_blank">Green Software for Practitioners</a></p>
                                <p>|</p>
                                <p>Course 2: <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/1795845" target="_blank">Accenture Green Software</a></p>
                            </div>
                            <p>Here's a <a href="https://mediaexchange.accenture.com/media/t/1_n0hrgtw9?st=0" target="_blank">step-by-step video guide</a> on how to access Sustainability Priority Learnings.</p>
                            <p>Have queries? Check out the <a href="https://collections.accenture.com/FileViewer.aspx?f=4e9fb50d-00bd-4124-9212-33cdb7495382">FAQs here</a>.</p>
                        </div>
                    </div>
                </div>

                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <Feedback subject={"Sustainability News From The World - October 2023 Edition"} />
            </div>
        </>
    )
}

export default SustainabilityNewsOct;