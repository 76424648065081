import React from 'react'
import './Footer.css';
import Scrolltop from './Scrolltop';
import Analytics from './Analytics/Analytics';

function Footer() {
    const footerYear = new Date().getFullYear()

    return (
        <>
        <div className="footer_container">
            <p>&copy; {footerYear} Accenture. All Rights Reserved. Accenture Confidential. For internal use only. Powered by Learning Experiences - L&TT, ATCI.
                <Analytics />                 
            </p>             
        </div>
        <Scrolltop />
        </>
    )
}

export default Footer