import React,{useEffect} from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect
} from "react-router-dom";
import SearchNav from "./function-components/Searchnav";
import Navbarmenu from "./function-components/Navbarmenu";
import Footer from "./function-components/Footer";
import Home from "./pages/Home";
import FAQ from "./pages/FAQ";
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { PageLayout } from "./function-components/PageLayout";
import Feedback from "./function-components/Feedback";
import FeedbackData from "./function-components/FeedbackData";
import Organicfarms from "./function-components/Organicfarms";
import PondMan from "./function-components/PondMan";
import SankalpTaruFoundation from "./function-components/SankalpTaruFoundation";
import GayatriMenon from "./function-components/GayatriMenon";
import GayatriMenonFirstSession from "./function-components/GayatriMenonFirstSession";
import SNOmkar from "./function-components/SNOmkar";
import Insights from "./function-components/Analytics/Insights";
import SustainabilityNews from './function-components/sustainbility-news/SustainbilityNews';
import AnkurWarikoo from "./function-components/AnkurWarikoo";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

function App({ pca }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);

  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <PageLayout>
        <div container justifyContent="center">
          <Pages />
        </div>
      </PageLayout>
    </MsalProvider>
  );
}
function Pages() {
  const msalInstance = new PublicClientApplication(msalConfig);    
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();        

  useEffect(() => {    
      if (activeAccount) {                        
          fetchAccessToken()          
      } 
  }, [activeAccount]);
  const fetchAccessToken = async () => {
      if (!activeAccount) 
          throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      try 
      {                     
          const response = await msalInstance.acquireTokenSilent({
              ...loginRequest,
              account: activeAccount
          });             
          const bearer = `Bearer ${response.accessToken}`;                                                
          localStorage.setItem("token", bearer)                      
      } 
      catch (error) 
      {
          console.error('Error fetching access token:', error);
          localStorage.clear()
      }
  };
  return (
    <>
      <Router>
        <SearchNav />
        <Switch>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/faq">
            <FAQ />
          </Route>
          <Route exact path="/sustainabilitynews">
            <Redirect to="/home" />
          </Route>
          <Route exact path="/sustainabilitynews/:calendar">
            <SustainabilityNews />
          </Route>
          <Route exact path="/feedback">
            <Feedback />
          </Route>
          <Route exact path="/feedbackdata">
            <FeedbackData />
          </Route>
          <Route exact path="/ankurWarikoo">
            <AnkurWarikoo />
          </Route>
          <Route exact path="/organicfarms">
            <Organicfarms />
          </Route>
          <Route exact path="/PondMan">
            <PondMan />
          </Route>
          <Route exact path="/GayatriMenon">
            <GayatriMenon />
          </Route>
          <Route exact path="/GayatriMenonFirstSession">
            <GayatriMenonFirstSession />
          </Route>
          <Route exact path="/sankalptarufoundation">
            <SankalpTaruFoundation />
          </Route>
          <Route exact path="/SNOmkar">
            <SNOmkar />
          </Route>
          <Route exact path="/views">
            <Insights />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
