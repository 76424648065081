import React, { useEffect } from "react";
import "./SustainabilityNewsApr.css";
import "./SustainabilityNewsFeb.css";
import Feedback from '../function-components/Feedback';

const SustainabilityNewsApr = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div className="sustainability_main">
                <div className="sustainability_header">
                    <h2>Sustainability News From the World - April 2023 Edition</h2>
                </div>
                <div className='banner-container'>
                    <div className='home-banner'   >
                        <img src={process.env.PUBLIC_URL + "/images/Home/Sustainability_News/sustainability_news_banner.png"} className="sust_news_gif_img" />
                    </div>
                </div>
                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <div className="sustainability_mid_container">
                    <p>It is wonderful to see a world where Sustainability is becoming the heart of most conversations.  There are significant commitments and actions as individuals, organizations, countries, and the world move to translate intentions into actions.   We are pleased to bring you monthly bytes that cover the curated news from the world of technology, industry, governments, clients, Accenture, and our larger ecosystem. This is coming straight from our <strong><i>ATCI Sustainability Research Cell</i></strong>. Hope you enjoy reading it and find some meaningful “sustainable aha” moments that you can apply to your ecosystem.</p>
                    <p className="sustainability_para">Do you have comments or suggestions? We would love to hear at <a className="sustainability_para_a" href="mailto:ATCI.Sust.Research@accenture.com">ATCI.Sust.Research@accenture.com</a></p>
                    <p className="sustainability_para">Regards,</p>
                    <p><strong>Shridhar Rajgopalan</strong>, Lead - Sustainability and Song, ATCI</p>
                    <p><strong>Anupama Nithyanand</strong>, Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI</p>
                </div>
                <div className="sustainability_li_container">
                    <h3>SUSTAINABILITY NEWS FROM THE WORLD - APRIL 2023 EDITION</h3>
                    <div className="sustainability_wrap">
                        <ul>
                            <li>Highlights</li>
                            <li>Trends & Technology</li>
                            <li>Industry Reads</li>
                            <li>Accenture Newsroom</li>
                            <li>Reports & magazines</li>
                            <li>Soulful Living!</li>
                            <li>Did you know</li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_highlight_div">
                    <h4>Highlights</h4>
                    <div>
                        <h4 className="highlight_h4_tag">UN Conference on Water Security brings focus to better manage world’s water resources</h4>
                        <p><i>Reuters - Mar 24, 2023</i></p>
                        <p>A three-day conference held by United Nations (UN) on water security concluded in March, highlights water for sustainable development and water action agenda.</p>
                        <a className="hightlight_a" href="https://www.reuters.com/business/environment/un-warns-vampiric-overconsumption-is-draining-worlds-water-2023-03-22/" target={"_blank"}>Full Article</a>
                    </div>
                    <div>
                        <h4 className="highlight_h4_tag">Balanced ESG Framework from SEBI to enhance reliability of ESG disclosures</h4>
                        <p><i>LiveMint.com- Mar 29, 2023</i></p>
                        <p>SEBI introduces Business Responsibility and Sustainability Report core to enhance the reliability of ESG reporting.</p>
                        <a className="hightlight_a" href="https://www.livemint.com/market/stock-market-news/sebi-board-approves-balanced-esg-framework-prescribes-glide-path-for-top-150-listed-firms-from-fy24-11680105637131.html" target={"_blank"}>Full Article</a>
                    </div>
                    <div>
                        <h4 className="highlight_h4_tag">Sustainability in emerging markets and how to fill the gap</h4>
                        <p><i>World Economic Forum - Mar 20, 2023</i></p>
                        <p>Sustainability key practices that can help businesses in emerging markets to close the sustainability gap and catch on with businesses in advanced markets.</p>
                        <a className="hightlight_a" href="https://www.weforum.org/agenda/2023/03/how-to-fill-the-sustainability-gap-in-emerging-markets-and-why-it-matters/" target={"_blank"}>Full Article</a>
                    </div>
                    {/* <div>
                    <h4 className="highlight_h4_tag">Sustainable Development Goals: How far we have reached</h4>
                    <p><i>Mint - Oct 02, 2022</i></p>
                    <p>Taking stock of how the world and India is faring on the SDG goals that they have set for 2030. Considering the covid and other uncertainties that unfolded, Will focusing on specific targets be more effective? the article delves into the progress statistics…</p>
                    <a className="hightlight_a" href="https://www.livemint.com/opinion/online-views/sustainable-development-goals-how-india-and-the-world-are-doing-11664731506364.html" target={"_blank"}>Full Article</a>
                </div> */}
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Trends & Technology</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>Key technologies such as AI, IOT, Remote sensing, Satellite tech, nature based & nature inclusive solution are crucial in developing early warning systems to help people adapt extreme climate events. <a className="mid_section_a" href="https://unfccc.int/news/early-warning-technologies-can-be-game-changers-for-climate-adaptation" target={"_blank"}>Read more...</a></li>
                            <li>Inspiring stories around the world how women are leveraging technology to protect the earth.  <a className="mid_section_a" href="https://www.unep.org/news-and-stories/story/these-women-are-using-technology-protect-planet" target={"_blank"}>Read more...</a></li>
                            <li>Heat generated by data center powers to keep the swimming pools heated.  <a className="mid_section_a" href="https://sustainabilitymag.com/articles/swimming-pool-heated-by-data-centre-to-reduce-energy-bills" target={"_blank"}>Read more...</a></li>
                            <li>Using cutting edge technology and systems thinking approach to manage water security challenges.  <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/03/how-tech-can-help-address-the-world-s-freshwater-crises/" target={"_blank"}>Read more...</a></li>
                            <li>Purpose led globalization and connectivity can help in driving net-zero transition. <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/03/globalization-sustainable-connectivity-purpose-led/" target={"_blank"}>Read more...</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Industry Reads</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>Assessment by University of Cambridge finds presence of infrastructure development on Key Biodiversity Areas (KBAs). <a className="mid_section_a" href="https://phys.org/news/2023-03-world-important-sites-biodiversity-human.html" target={"_blank"}>Read more...</a></li>
                            <li>CDP, Carbon Disclosure Project seek to help organizations on their supplier engagement strategy with tracking nature across supply chain. <a className="mid_section_a" href="https://sustainabilitymag.com/net-zero/flex-among-330-firms-given-a-list-cdp-sustainability-status" target={"_blank"}>Read more...</a></li>
                            <li>The Global Biodiversity Framework (GBF) at COP15 calls on businesses to reduce negative impacts on biodiversity. <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/02/5-ways-businesses-can-implement-the-new-global-biodiversity-framework?utm_source=linkedin&utm_medium=social_scheduler&utm_term=Climate+and+Nature&utm_content=28%2F02%2F2023+11%3A30" target={"_blank"}>Read more...</a></li>
                            <li>New guidelines introduced by Advertising Standards Authority (ASA) for advertisers to promote transparency in environmental claims and curtail greenwashing. <a className="mid_section_a" href="https://sustainabilitymag.com/articles/do-new-asa-guidelines-mean-an-end-to-greenwashing" target={"_blank"}>Read more...</a></li>
                            {/* <li>Understanding circularity from a systems thinker's view  <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/02/circular-economy-circularity-sustainability-environment?utm_source=linkedin&utm_medium=social_scheduler&utm_term=&utm_content=19%2F02%2F2023+14%3A15" target={"_blank"}>Read more...</a></li> */}
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Accenture Newsroom</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>The Ocean Race, Virtual Regatta and Accenture Launch Metaverse Experience. <a className="mid_section_a" href="https://newsroom.accenture.com/news/the-ocean-race-virtual-regatta-and-accenture-launch-metaverse-experience.htm" target={"_blank"}>Read more...</a></li>
                            <li>Accenture and Deloitte experts shares insights on safe and eco-friendly equipment disposal, a program organized by data security company, Blancco. <a className="mid_section_a" href="https://www.itworldcanada.com/article/accenture-and-deloitte-experts-weigh-in-on-safe-and-eco-friendly-equipment-disposal-at-blancco-webinar-series/534235" target={"_blank"}>Read more...</a></li>
                            <li>Accenture experts share insights on ESG compliance and complexities in Supply chain. <a className="mid_section_a" href="https://supplychaindigital.com/sustainability/how-to-navigate-esg-complexity-in-supply-chain" target={"_blank"}>Read more...</a></li>
                            <li>Accenture to acquire industrial AI company Flutura. <a className="mid_section_a" href="https://newsroom.accenture.com/news/accenture-to-acquire-industrial-ai-company-flutura.htm" target={"_blank"}>Read more...</a></li>
                            <li>Accenture expert talks about Technology and human ingenuity to push boundaries through a new wave of innovation in data and AI. <a className="mid_section_a" href="https://www.technologyreview.com/2023/03/28/1069668/technology-and-industry-convergence-a-historic-opportunity/" target={"_blank"}>Read more...</a></li>
                            {/* <li>European business leaders confident of future growth in the view of overcoming the pandemic <a className="mid_section_a" href="https://newsroom.accenture.com/news/european-business-leaders-confident-despite-current-headwinds-but-risk-underinvesting-in-technology-for-long-term-growth-accenture-report-finds.htm" target={"_blank"}>Read more...</a></li> */}
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Reports and Magazines</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>5 charts from the IPCC, Intergovernmental Panel on Climate Change report highlights risks of lower temperatures due to climate change <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/03/climate-change-ipcc-emissions-risks-net-zero/" target={"_blank"}>Read more...</a></li>
                            <li>What are nature climate solutions and how it can help to reduce climate change and bio-diversity loss. <a className="mid_section_a" href="https://www3.weforum.org/docs/WEF_NCSA_NCS_for_Corporates_2021.pdf" target={"_blank"}>Read more...</a></li>
                            <li>Understanding progress in water related ecosystem, SDGs and global data availability. <a className="mid_section_a" href="https://www.unep.org/resources/report/measuring-progress-water-related-ecosystems-and-sdgs" target={"_blank"}>Read more...</a></li>
                            <li>A study on non-native plants and its rapid growth highlights need for monitoring schemes to understand long term impact. <a className="mid_section_a" href="https://www.nature.com/articles/d44151-023-00034-y" target={"_blank"}>Read more...</a></li>
                            <li>Only second ever UN conference on water saw host of countries discuss water disasters related to climate change. <a className="mid_section_a" href="https://www.theguardian.com/environment/2023/mar/23/water-un-conference-climate-crisis-disasters" target={"_blank"}>Read more...</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Soulful Living!</h4>
                    <div className="midsec_box">
                        <div className="midsection_para">
                            {/* <p><strong>Innovative use of AI to Assess Potholes issues on City Roads by a Bengaluru startup</strong></p> */}
                            <p className="mid_section_p"><strong>Nekram Sharma</strong>, a farmer from Himachal Pradesh is conferred with prestigious Padma Shri award for his work in reviving traditional farming method of ‘<strong>Nau-Anaj</strong>’, growing 9 different foodgrains from lentils to vegetables that encourages crop biodiversity. <br /> <br /> <a className="mid_section_a" href="https://www.hindustantimes.com/cities/chandigarh-news/reviving-intercropping-system-millet-cultivation-earned-this-himachal-farmer-padma-shri-101674846560588.html" target={"_blank"}>Read more…</a></p>
                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + "/images/Home/Sustainability_News/people_img_apr.jpg"} alt="sust_news" className="sust_news_img" />
                        </div>
                    </div>

                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Did You Know</h4>
                    <div className="lastsection_march">
                        <div className="lastsection_march_1stCard">
                            <h4 className="lastsection_march_h4">90% of the heat</h4>
                            {/* <p className="diduknow_p">Earth's surface mainly consists of 3 things: hydrosphere, biosphere & atmosphere.</p> */}
                            <p className="diduknow_p">Oceans play an important role in absorption of heat and maintaining the temperature stable due to its vast size. <br /> <a className="mid_section_a" href="https://earth.org/data_visualization/11-interesting-facts-about-climate-change/" target={"_blank"}>Full article</a></p>
                        </div>
                        <div className="lastsection_march_2ndCard">
                            <h4 className="lastsection_march_h4">1% water on earth</h4>
                            <p className="diduknow_p">Groundwater as a source of freshwater supports life on earth by providing drinking water as well as water for agriculture. Nature based solutions can help recharge and replenish it. <br /><a className="mid_section_a" href="https://www.worldwildlife.org/stories/five-facts-you-might-not-know-about-groundwater" target={"_blank"}>Full article</a></p>
                        </div>
                        <div className="lastsection_march_3rdCard">
                            <h4 className="lastsection_march_h4">Floating leaf</h4>
                            <p className="diduknow_p">University of Cambridge Researchers have designed floating leaves that leverages solar technology to convert light into fuel. <br /> <a className="mid_section_a" href="https://www.euronews.com/green/2022/08/18/solar-power-breakthrough-floating-artificial-leaf-creates-fuel-from-sunlight" target={"_blank"}>Full article</a></p>
                        </div>
                    </div>
                </div>
                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <Feedback subject={"Sustainability News From The World - April 2023 Edition"} />
            </div>
        </>
    )
}

export default SustainabilityNewsApr;