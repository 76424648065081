import React from 'react';
import './HanggingMenu.css';

function HanggingMenu() {
  return (
    <>
        <div className="float-wrap">
            <div className="float-box box1">
                <p><a href="mailto:ATCI.SUST.RESEARCH@accenture.com" className='float-bar'><img src="images/mail.svg" alt="icons" className='float-icons' /> <span className='float-icon-img'>Contact Us</span></a></p>
            </div>
        </div>
    </>
  )
}

export default HanggingMenu