import {useState, useEffect} from 'react';
import axios from 'axios';
import { useMsal } from "@azure/msal-react";
import './SustainbilityNewsAnalytics.css';

export default function SustainabilityNewsAnalytics(props) {    
    const [allUsersViewsCount, setAllUsersViewsCount] = useState(0);
    const [allUsersUniqueViewsCount, setAllUsersUniqueViewsCount] = useState(0);                 
      
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();        
    let loggedInCareerLevel = "";
    let loggedInEnterpriseId = '';
    if(activeAccount) {
        loggedInCareerLevel = activeAccount.idTokenClaims.jobFamilyDescription;
        loggedInEnterpriseId = activeAccount.idTokenClaims.enterpriseID;
    }            

    // let url = "http://localhost:3051/sustainbility-news";
    let url = "https://atci.sustainabilityresearch.accenture.com/sustainability-research-apis/sustainbility-news";

    const pageWiseAnalytics = (page) => {    
        axios.post(url, {
            enterprise_id : loggedInEnterpriseId,            
            page: page   
        },{
            headers: {              
                'Authorization': localStorage.getItem("token"),
                'Content-Type': 'application/json'
              }
        })
        .then((response) => {                        
            getAllUsersViewsCount(props.page);
            getAllUsersUniqueViewsCount(props.page);
        })
        .catch((error)=>{
            console.log(error);
        })
    }
    
    const getAllUsersViewsCount = (page) => {
        axios.get(url+'/allUsersViewsCount/'+page,{
            headers: {              
                'Authorization': localStorage.getItem("token"),
                'Content-Type': 'application/json'
              }
        })        
        .then((response)=>{            
            setAllUsersViewsCount(response.data.result);            
        })  
        .catch((err)=>{
            console.log(err);    
        })
    }

    const getAllUsersUniqueViewsCount = (page) => {
        axios.get(url+'/allUsersUniqueViewsCount/'+page,{
            headers: {              
                'Authorization': localStorage.getItem("token"),
                'Content-Type': 'application/json'
              }
        })        
        .then((response)=>{                          
            setAllUsersUniqueViewsCount(response.data.result);          
        })  
        .catch((err)=>{
            console.log(err);    
        })
    }

    useEffect(() => {        
        if(process.env.NODE_ENV === 'production' && loggedInEnterpriseId !== '') {            
            if((loggedInCareerLevel !== ""  && loggedInCareerLevel != 7 && loggedInCareerLevel != 8 && loggedInCareerLevel != 9 && loggedInCareerLevel != 10 && loggedInCareerLevel != 11 && loggedInCareerLevel != 12)) {                                 
                pageWiseAnalytics(props.page);                            
            } else {
                getAllUsersViewsCount(props.page);
                getAllUsersUniqueViewsCount(props.page);
            }            
        }         
    },[]);  

    return (        
        <>            
            {process.env.NODE_ENV === 'production' && <span className='analytics-span'><img src={process.env.PUBLIC_URL + "/images/Home/medium-eye.png"} className='eye-icon' alt="eye" /> Total Views: {allUsersViewsCount} &nbsp; | &nbsp; Unique Views: {allUsersUniqueViewsCount}</span>}
        </>        
        
    )
}