import React from 'react';
import FAQContent from '../function-components/FAQContent';

function FAQ() {
  return (
    <>
    <FAQContent />
    </>
  )
}

export default FAQ