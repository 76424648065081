import {useLocation} from 'react-router-dom';

import SustainabilityNewsAnalytics from './SustainbilityNewsAnalytics';
import HomeAnalytics from './HomeAnalytics';

export default function Analytic() {
    
    const location = useLocation();  
    console.log(location);    
    let subject = location.pathname.split("/")[1];
    let page = location.pathname.split("/")[2];

    return <>
        {subject === 'sustainabilitynews' ? 
            <SustainabilityNewsAnalytics subject={subject} page={page} /> : 
            subject === 'views' ? "" : 
            <HomeAnalytics />
        }
    </>;    
}