import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./MonthlyRoundup.css";
import Feedback from './Feedback';

const MonthlyRoundup = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  // const [yearEdition1, setYearEdition1] = useState('current');

  return (
    <div className='monthly-roundup-container'>
      <div className='mr-wrapper'>
        <div className='mr-box'>
          <h2><span>Sustainability News From the World</span></h2>
          <p className='mr-para'>It is wonderful to see a world where Sustainability is becoming the heart of most conversations.  There are significant commitments and actions as individuals, organizations, countries, and the world move to translate intentions into actions.   We are pleased to bring you monthly bytes that cover the curated news from the world of technology, industry, governments, clients, Accenture, and our larger ecosystem.  This is coming straight from our ATCI Sustainability Research Cell. Hope you enjoy reading it and find some meaningful “sustainable aha” moments that you can apply to your ecosystem.</p>

          {/* <div className='mr-box-row'>
            <div className='calender-tile'>
              <img className='mr-tile' src='images/Home/MonthlyRoundup/Monthly-roundup-tile.jpg' />
              <p className='mr-tile-header'> <img className='mr-calendar-icon' src='images/Home/MonthlyRoundup/mr-calendar-icon.jpg' />Feb 2023 <img className='mr-arrow' src='images/Home/MonthlyRoundup/mr-arrow.jpg' /> May 2023</p>
            </div>
          </div> */}

          <div className='mr-box-row'>
            <p className='mr-box-row-para active'>2023 Editions</p>
            {/* <p className='vertical-line'></p>
            <p className={yearEdition1 == 'previous' ? 'mr-box-row-para active' : 'mr-box-row-para inactive'} onClick={() => setYearEdition1('previous')}>Previous Year Edition (2022)</p> */}
          </div>
          <hr></hr>

          {/* {yearEdition1 == 'current' ? ( */}
            <div className='mr-box-container'>
              <Carousel className='monthly_carousel'
                responsive={responsive}
                swipeable={false}
                draggable={false}
                showDots={true}
                arrows={true}
                renderArrowsWhenDisabled={true}
                keyBoardControl={true}
                partialVisible={false}>
                {/* <div className='mr-card1' >
                    <div className='mr-box-wrapper'>
                    <div>
                    <img src='images/Home/MonthlyRoundup/Jan.png' className='monthly_tile'/>
                    </div>
                    <div className='mr-box-text'>
                    <h5>Jan 2023 Edition</h5>
                    <p className='mr-box-content'>It is wonderful to see a world where Sustainability...</p>
                    </div>
                    </div>
                    <div className='rating_section'><Feedback/></div>
                    </div> */}
                    <div className='mr-card' >
                   <Link to="/sustainabilitynews/Nov2023" target={"_blank"} className="mr-card_link">
                    <div className='mr-box-wrapper'>
                      <div>
                        <img src='images/Home/MonthlyRoundup/Nov-new.png' className='monthly_tile' />
                      </div>
                      <div className='mr-box-text'>
                        <h5>Nov 2023</h5>
                      </div>
                    </div>
                  </Link>
                    <div className='rating_section'><Feedback subject={"November 2023 Edition"} /></div>
                    </div>
                    <div className='mr-card' >
                   <Link to="/sustainabilitynews/Oct2023" target={"_blank"} className="mr-card_link">
                    <div className='mr-box-wrapper'>
                      <div>
                        <img src='images/Home/MonthlyRoundup/Oct-new.png' className='monthly_tile' />
                      </div>
                      <div className='mr-box-text'>
                        <h5>Oct 2023</h5>
                      </div>
                    </div>
                  </Link>
                    <div className='rating_section'><Feedback subject={"October 2023 Edition"} /></div>
                    </div>
                    <div className='mr-card' >
                  <Link to="/sustainabilitynews/Sep2023" target={"_blank"} className="mr-card_link">
                    <div className='mr-box-wrapper'>
                      <div>
                        <img src='images/Home/MonthlyRoundup/Sept-new.png' className='monthly_tile' />
                      </div>
                      <div className='mr-box-text'>
                        <h5>Sep 2023</h5>
                      </div>
                    </div>
                  </Link>
                  <div className='rating_section'><Feedback subject={"September 2023 Edition"} /></div>
                </div>
                   <div className='mr-card' >
                  <Link to="/sustainabilitynews/Aug2023" target={"_blank"} className="mr-card_link">
                    <div className='mr-box-wrapper'>
                      <div>
                        <img src='images/Home/MonthlyRoundup/Aug-new.png' className='monthly_tile' />
                      </div>
                      <div className='mr-box-text'>
                        <h5>Aug 2023</h5>
                      </div>
                    </div>
                  </Link>
                  <div className='rating_section'><Feedback subject={"August 2023 Edition"} /></div>
                </div>
                  <div className='mr-card' >
                  <Link to="/sustainabilitynews/jul2023" target={"_blank"} className="mr-card_link">
                    <div className='mr-box-wrapper'>
                      <div>
                        <img src='images/Home/MonthlyRoundup/July_new.png' className='monthly_tile' />
                      </div>
                      <div className='mr-box-text'>
                        <h5>July 2023</h5>
                      </div>
                    </div>
                  </Link>
                  <div className='rating_section'><Feedback subject={"July 2023 Edition"} /></div>
                </div>
                 <div className='mr-card' >
                  <Link to="/sustainabilitynews/jun2023" target={"_blank"} className="mr-card_link">
                    <div className='mr-box-wrapper'>
                      <div>
                        <img src='images/Home/MonthlyRoundup/June.png' className='monthly_tile' />
                      </div>
                      <div className='mr-box-text'>
                        <h5>June 2023</h5>
                      </div>
                    </div>
                  </Link>
                  <div className='rating_section'><Feedback subject={"June 2023 Edition"} /></div>
                </div>
                <div className='mr-card' >
                  <Link to="/sustainabilitynews/may2023" target={"_blank"} className="mr-card_link">
                    <div className='mr-box-wrapper'>
                      <div>
                        <img src='images/Home/MonthlyRoundup/May.png' className='monthly_tile' />
                      </div>
                      <div className='mr-box-text'>
                        <h5>May 2023</h5>
                      </div>
                    </div>
                  </Link>
                  <div className='rating_section'><Feedback subject={"May 2023 Edition"} /></div>
                </div>

                <div className='mr-card' >
                  <Link to="/sustainabilitynews/apr2023" target={"_blank"} className="mr-card_link">
                    <div className='mr-box-wrapper'>
                      <div>
                        <img src='images/Home/MonthlyRoundup/April.png' className='monthly_tile' />
                      </div>
                      <div className='mr-box-text'>
                        <h5>Apr 2023</h5>
                        {/* <p className='mr-box-content'>It is wonderful to see a world where Sustainability...</p> */}
                      </div>
                    </div>
                  </Link>
                  <div className='rating_section'><Feedback subject={"Apr 2023 Edition"} /></div>
                </div>

                <div className='mr-card' >
                  <Link to="/sustainabilitynews/mar2023" target={"_blank"} className="mr-card_link">
                    <div className='mr-box-wrapper'>
                      <div>
                        <img src='images/Home/MonthlyRoundup/Mar.png' className='monthly_tile' />
                      </div>
                      <div className='mr-box-text'>
                        <h5>Mar 2023</h5>
                        {/* <p className='mr-box-content'>It is wonderful to see a world where Sustainability...</p> */}
                      </div>
                    </div>
                  </Link>
                  <div className='rating_section'><Feedback subject={"Mar 2023 Edition"} /></div>
                </div>

                <div className='mr-card' >
                  <Link to="/sustainabilitynews/feb2023" target={"_blank"} className="mr-card_link">
                    <div className='mr-box-wrapper'>
                      <div>
                        {/* <div className='mr-live-box'>LIVE <div className='mr-live-dot'></div></div> */}
                        <img src='images/Home/MonthlyRoundup/Feb.png' className='monthly_tile' />
                      </div>
                      <div className='mr-box-text'>
                        <h5>Feb 2023</h5>
                        {/* <p className='mr-box-content'>It is wonderful to see a world where Sustainability...</p> */}
                      </div>
                    </div>
                  </Link>
                  <div className='rating_section'><Feedback subject={"Feb 2023 Edition"} /></div>
                </div>







                {/* <div className='mr-card1' >
                  <div className='mr-box-wrapper'>
                    <div>
                      <img src='images/Home/MonthlyRoundup/Jun.png' className='monthly_tile' />
                    </div>
                    <div className='mr-box-text'>
                      <h5>Jun 2023 Edition</h5>
                    </div>
                  </div>
                  <div className='rating_section'><Feedback subject={"Jun 2023 Edition"} /></div>
                </div>

                <div className='mr-card1' >
                  <div className='mr-box-wrapper'>
                    <div>
                      <img src='images/Home/MonthlyRoundup/July.png' className='monthly_tile' />
                    </div>
                    <div className='mr-box-text'>
                      <h5>Jul 2023 Edition</h5>
                    </div>
                  </div>
                  <div className='rating_section'><Feedback subject={"Jul 2023 Edition"} /></div>
                </div>

                <div className='mr-card1' >
                  <div className='mr-box-wrapper'>
                    <div>
                      <img src='images/Home/MonthlyRoundup/Aug.png' className='monthly_tile' />
                    </div>
                    <div className='mr-box-text'>
                      <h5>Aug 2023 Edition</h5>
                    </div>
                  </div>
                  <div className='rating_section'><Feedback subject={"Aug 2023 Edition"} /></div>
                </div>

                <div className='mr-card1' >
                  <div className='mr-box-wrapper'>
                    <div>
                      <img src='images/Home/MonthlyRoundup/Sep.png' className='monthly_tile' />
                    </div>
                    <div className='mr-box-text'>
                      <h5>Sep 2023 Edition</h5>
                    </div>
                  </div>
                  <div className='rating_section'><Feedback subject={"Sep 2023 Edition"} /></div>
                </div>

                <div className='mr-card1' >
                  <div className='mr-box-wrapper'>
                    <div>
                      <img src='images/Home/MonthlyRoundup/Oct.png' className='monthly_tile' />
                    </div>
                    <div className='mr-box-text'>
                      <h5>Oct 2023 Edition</h5>
                    </div>
                  </div>
                  <div className='rating_section'><Feedback subject={"Oct 2023 Edition"} /></div>
                </div>

                <div className='mr-card1' >
                  <div className='mr-box-wrapper'>
                    <div>
                      <img src='images/Home/MonthlyRoundup/Nov.png' className='monthly_tile' />
                    </div>
                    <div className='mr-box-text'>
                      <h5>Nov 2023 Edition</h5>
                    </div>
                  </div>
                  <div className='rating_section'><Feedback subject={"Nov 2023 Edition"} /></div>
                </div>
                
                <div className='mr-card1' >
                  <div className='mr-box-wrapper'>
                    <div>
                      <img src='images/Home/MonthlyRoundup/Dec.png' className='monthly_tile' />
                    </div>
                    <div className='mr-box-text'>
                      <h5>Dec 2023 Edition</h5>
                    </div>
                  </div>
                  <div className='rating_section'><Feedback subject={"Dec 2023 Edition"} /></div>
                </div> */}

              </Carousel>
            </div>
          {/* ) : (
            <div>
              <br /> <br /> <br /> <br /> <br /><br /> <br />
            </div>
          )
          } */}

        </div>

      </div>
      <img src="images/Home/bg3.png" alt="" className='bg3' />
      <img src="images/Home/bg4.png" alt="" className='bg4' />
    </div>
  )
}

export default MonthlyRoundup