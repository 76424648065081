import React , {useEffect} from "react";
import "./SustainabilityNewsMar.css";
import "./SustainabilityNewsFeb.css";
import Feedback from '../function-components/Feedback';

const SustainabilityNewsMar = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
        <div className="sustainability_main">
        <div className="sustainability_header">
            <h2>Sustainability News From the World - March 2023 Edition</h2>
        </div>
        <div className='banner-container'>
                    <div className='home-banner'   >
                    <img src={process.env.PUBLIC_URL + "/images/Home/Sustainability_News/sustainability_news_banner.png"} className="sust_news_gif_img"  />
                    </div>
                </div>
                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <div className="sustainability_mid_container">
                <p>It is wonderful to see a world where Sustainability is becoming the heart of most conversations.  There are significant commitments and actions as individuals, organizations, countries, and the world move to translate intentions into actions.   We are pleased to bring you monthly bytes that cover the curated news from the world of technology, industry, governments, clients, Accenture, and our larger ecosystem. This is coming straight from our <strong><i>ATCI Sustainability Research Cell</i></strong>. Hope you enjoy reading it and find some meaningful “sustainable aha” moments that you can apply to your ecosystem.</p>
                <p className="sustainability_para">Do you have comments or suggestions? We would love to hear at <a className="sustainability_para_a" href="mailto:ATCI.Sust.Research@accenture.com">ATCI.Sust.Research@accenture.com</a></p>   
                <p className="sustainability_para">Regards,</p>
                <p><strong>Shridhar Rajgopalan</strong>, Lead - Sustainability and Song, ATCI</p>
                <p><strong>Anupama Nithyanand</strong>, Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI</p>
                </div>
                <div className="sustainability_li_container">
                    <h3>SUSTAINABILITY NEWS FROM THE WORLD – MARCH 2023 EDITION</h3>
                    <div className="sustainability_wrap">
                    <ul>
                        <li>Highlights</li>
                        <li>Trends & Technology</li>
                        <li>Industry Reads</li>
                        <li>Accenture Newsroom</li>
                        <li>Reports & magazines</li>
                        <li>Soulful Living!</li>
                        <li>Did you know</li>
                    </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                <hr/>
                </div>
                <div className="sustainability_highlight_div">
                <h4>Highlights</h4>
                <div>
                    <h4 className="highlight_h4_tag">Changing mindset towards sustainability and climate urgency for making the long term impact</h4>
                    <p><i>World Economic Forum- Feb 2, 2023</i></p>
                    <p>As organizations catch on the urgency of sustainable development, how they can drive the change for the planet and people to thrive, A sustainability mindset and engagement at a personal level can help in driving this change…</p>
                    <a className="hightlight_a" href="https://www.weforum.org/agenda/2023/02/sustainability-mindset-climate-change/" target={"_blank"}>Full Article</a>
                </div>
                <div>
                    <h4 className="highlight_h4_tag">Realize Sustainable Development Goals for 2030</h4>
                    <p><i>United Nations News - Feb 6, 2023</i></p>
                    <p>As the half-way point to 2030 approaches, meeting all the 17 SDGs is important, and governments need to put people first by creating more opportunities for them to learn skills, educate, work and transition to green economy…</p>
                    <a className="hightlight_a" href="https://news.un.org/en/story/2023/02/1133202" target={"_blank"}>Full Article</a>
                </div>
                <div>
                    <h4 className="highlight_h4_tag">RBI study notes ESG performance of domestic companies and their broad market counterparts</h4>
                    <p><i>Business Standard - Feb 21, 2023</i></p>
                    <p>Transitioning into new energy systems and Energy security strategies need to go hand in hand as it complements each other. The World Economic Forum, in collaboration with Accenture, proposes a comprehensive framework which talks about six dimensions that are interconnected and how it can address security challenges to ensure security of energy transition…</p>
                    <a className="hightlight_a" href="https://www.business-standard.com/article/current-affairs/domestic-esg-focused-firms-performed-better-than-global-peers-rbi-123021900422_1.html" target={"_blank"}>Full Article</a>
                </div>
                {/* <div>
                    <h4 className="highlight_h4_tag">Sustainable Development Goals: How far we have reached</h4>
                    <p><i>Mint - Oct 02, 2022</i></p>
                    <p>Taking stock of how the world and India is faring on the SDG goals that they have set for 2030. Considering the covid and other uncertainties that unfolded, Will focusing on specific targets be more effective? the article delves into the progress statistics…</p>
                    <a className="hightlight_a" href="https://www.livemint.com/opinion/online-views/sustainable-development-goals-how-india-and-the-world-are-doing-11664731506364.html" target={"_blank"}>Full Article</a>
                </div> */}
                </div>
                <div className="sustainability_hr_div">
                <hr/>
                </div>
                <div className="sustainability_mid_section">
                <h4 className="midsection_h4">Trends & Technology</h4>
                <div className="midsection_li">
                    <ul>
                    <li>IoT based solutions can help achieve SDG goals. Number of goals where you can already see IoT based solutions can make a big difference  <a className="mid_section_a" href="https://www.counterpointresearch.com/iot-key-to-meeting-sustainable-development-goals-2030/" target={"_blank"}>Read more...</a></li>
                    <li>Ethical use of ‘Digital Public Technologies’ can create level playing field, and economic opportunities for the people <a className="mid_section_a" href="https://www.brookings.edu/research/how-can-digital-public-technologies-accelerate-progress-on-the-sustainable-development-goals/" target={"_blank"}>Read more...</a></li>
                    <li>Using Digital and Technology can play a role in creating resilient and sustainable growth <a className="mid_section_a" href="https://www.mckinsey.com/capabilities/risk-and-resilience/our-insights/seizing-the-momentum-to-build-resilience-for-a-future-of-sustainable-inclusive-growth" target={"_blank"}>Read more...</a></li>
                    <li>Role of Blockchain technology in ESG as part of sustainability practices and policies <a className="mid_section_a" href="https://inc42.com/resources/how-blockchain-technology-can-drive-innovation-based-sustainability/" target={"_blank"}>Read more...</a></li>
                    <li>Using ‘Tech for Good’ to make a world better place where innovative solutions are paving the way <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/01/tech-for-good-innovations/" target={"_blank"}>Read more...</a></li>
                    </ul>
                </div>
                </div>
                <div className="sustainability_hr_div">
                <hr/>
                </div>
                <div className="sustainability_mid_section">
                <h4 className="midsection_h4">Industry Reads</h4>
                <div className="midsection_li">
                    <ul>
                    <li>Sustainability has key appeal for the employees and organizations that have positive impact on the world  <a className="mid_section_a" href="https://www.paulpolman.com/wp-content/uploads/2023/02/MC_Paul-Polman_Net-Positive-Employee-Barometer_Final_web.pdf" target={"_blank"}>Read more...</a></li>
                    <li>EU Commission’s approach to combat greenwashing in product labelling   <a className="mid_section_a" href="https://www.openaccessgovernment.org/standardised-approach-stop-greenwashing-labelling-companies/153451/" target={"_blank"}>Read more...</a></li>
                    <li>An Agritech startup, Otipy, helps farmers sell fresh produce and earn more  <a className="mid_section_a" href="https://www.thebetterindia.com/310667/varun-khurana-iit-delhi-agritech-startup-otipy-helps-small-farmers-raise-income-sell-fresh-produce/" target={"_blank"}>Read more...</a></li>
                    <li>COP15, Biodiversity conservation, countries commit to ensure protection of at least 30% of all land and oceans by 2030 <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/01/cop15-historic-deal-protect-nature-earth-2030/" target={"_blank"}>Read more...</a></li>
                    <li>Understanding circularity from a systems thinker's view  <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/02/circular-economy-circularity-sustainability-environment?utm_source=linkedin&utm_medium=social_scheduler&utm_term=&utm_content=19%2F02%2F2023+14%3A15" target={"_blank"}>Read more...</a></li>
                    </ul>
                </div>
                </div>
                <div className="sustainability_hr_div">
                <hr/>
                </div>
                <div className="sustainability_mid_section">
                <h4 className="midsection_h4">Accenture Newsroom</h4>
                <div className="midsection_li">
                    <ul>
                    <li>Accenture recognized as a leader in ESG / Sustainability Strategy consulting services, according to 2023 IDC MarketScape report <a className="mid_section_a" href="https://idcdocserv.com/download/US49044922e_Accenture.pdf" target={"_blank"}>Read more...</a></li>
                    <li>Early warning systems for building nature-positive and climate resilient businesses <a className="mid_section_a" href="https://www.europeanbusinessreview.com/sounding-the-alarm-early-warning-systems-to-build-nature-positive-and-climate-resilient-businesses/" target={"_blank"}>Read more...</a></li>
                    <li>Accenture invests in Cosmo Tech, a global provider of digital twin simulation technology in the area of complex supply chain operations <a className="mid_section_a" href="https://newsroom.accenture.com/news/accenture-invests-in-cosmo-tech-expanding-clients-access-to-digital-twin-simulation-technology.htm" target={"_blank"}>Read more...</a></li>
                    <li>Semiconductor industry and role of sustainability in semiconductor value chain <a className="mid_section_a" href="https://www.accenture.com/us-en/blogs/sustainability/energy-efficiency-three-levers-to-consider-inspired-by-the-b20https://newsroom.accenture.com/news/three-in-four-semiconductor-executives-expect-supply-chain-challenges-to-ease-by-2024-accenture-report-finds.htm" target={"_blank"}>Read more...</a></li>
                    <li>Building long-term and resilient businesses through sustainability <a className="mid_section_a" href="https://newsroom.accenture.com/news/alarmed-by-speed-and-scale-of-disruption-ceos-embrace-sustainability-to-build-long-term-resilience-according-to-un-global-compact-and-accenture.htm" target={"_blank"}>Read more...</a></li>
                    <li>European business leaders confident of future growth in the view of overcoming the pandemic <a className="mid_section_a" href="https://newsroom.accenture.com/news/european-business-leaders-confident-despite-current-headwinds-but-risk-underinvesting-in-technology-for-long-term-growth-accenture-report-finds.htm" target={"_blank"}>Read more...</a></li>
                    </ul>
                </div>
                </div>
                <div className="sustainability_hr_div">
                <hr/>
                </div>
                <div className="sustainability_mid_section">
                <h4 className="midsection_h4">Reports and Magazines</h4>
                <div className="midsection_li">
                    <ul>
                    <li>Need for backing the net-zero commitments by credible actions <a className="mid_section_a" href="https://www.un.org/en/climatechange/net-zero-coalition" target={"_blank"}>Read more...</a></li>
                    <li>Understanding challenges and opportunities for the businesses on ESG and Climate change <a className="mid_section_a" href="https://www.msci.com/www/blog-posts/six-esg-and-climate-trends-to/03547161547" target={"_blank"}>Read more...</a></li>
                    <li>2023 Sustainability Trends report from ‘The SustainAbility Institute’  <a className="mid_section_a" href="https://www.sustainability.com/globalassets/sustainability.com/thinking/pdfs/2023/2023_sustainability_trends_report.pdf" target={"_blank"}>Read more...</a></li>
                    <li>Social and economic effects of net-zero transition and what it would mean for the Asia-Pacific region <a className="mid_section_a" href="https://www.mckinsey.com/featured-insights/future-of-asia/asias-net-zero-transition-opportunity-and-risk-amid-climate-action" target={"_blank"}>Read more...</a></li>
                    <li>Circular economy to create a value in financial sector <a className="mid_section_a" href="https://emf.thirdlight.com/link/29wifcw68gx1-yw31dj/@/preview/1?o" target={"_blank"}>Read more...</a></li>
                    </ul>
                </div>
                </div>
                <div className="sustainability_hr_div">
                <hr/>
                </div>
                <div className="sustainability_mid_section">
                <h4 className="midsection_h4">Soulful Living!</h4>
                <div className="midsec_box">
                <div className="midsection_para">
                    <p><strong>Innovative use of AI to Assess Potholes issues on City Roads by a Bengaluru startup</strong></p>
                    <p className="mid_section_p">RoadMetrics, a Bangalore based startup has a unique take on how navigation maps show fastest or slowest routes to travel but not the conditions of the roads, AI based solution that they have developed helps companies and government municipalities in fixing the issues to make the roads safer and pothole free <a className="mid_section_a" href="https://www.thebetterindia.com/311278/bengaluru-startup-roadmetrics-dipen-babariya-finds-fixes-potholes-using-ai-road-safety/" target={"_blank"}>Read more…</a></p>
                </div>
                <div>
                    <img src={process.env.PUBLIC_URL + "/images/Home/Sustainability_News/people_img.jpg"} alt="sust_news" className="sust_news_img"/>
                </div>
                </div>
                
                </div>
                <div className="sustainability_hr_div">
                <hr/>
                </div>
                <div className="sustainability_mid_section">
                <h4 className="midsection_h4">Did You Know</h4>
                <div className="lastsection_march">
                <div className="lastsection_march_1stCard">
                  <h4 className="lastsection_march_h4">One-third Earth’s Forest</h4>  
                  <p className="diduknow_p">Earth's surface mainly consists of 3 things: hydrosphere, biosphere & atmosphere.</p>
                  <p className="diduknow_p">While more than 70% of the surface is covered in water, remaining is covered by the uninhabitable and habitable land. From this, how much of the forest cover from the land has changed over the years, let’s take a look… <a className="mid_section_a" href="https://www.visualcapitalist.com/visualizing-the-worlds-loss-of-forests-since-the-ice-age/" target={"_blank"}>Full article</a></p>
                </div>
                <div className="lastsection_march_2ndCard">
                  <h4 className="lastsection_march_h4">13%</h4>  
                  <p className="diduknow_p">Renewable energy forms 13% of global energy mix in 2021. When we look at the energy consumption in the past 200 years, we see major energy transitions happening from time to time where energy sources evolved to fuel the economic growth and development. Get to know more about the energy transition here… <a className="mid_section_a" href="https://www.visualcapitalist.com/sp/visualizing-the-past-and-future-of-energy-transitions/" target={"_blank"}>Full article</a></p>
                </div>
                <div className="lastsection_march_3rdCard">
                  <h4 className="lastsection_march_h4">7.6 billion</h4>  
                  <p className="diduknow_p">The world's forest plays a crucial role as carbon sinks through absorbing 7.6 billion tonnes of CO2 annually. Nature based carbon solutions provide a way to sequester carbon or reduce the GHG emissions related to deforestation and land use. Organizations are embracing sustainability in a big way, giving rise to increasing demand for nature-based solutions… <a className="mid_section_a" href="https://www.visualcapitalist.com/the-rising-demand-for-nature-based-climate-solutions/" target={"_blank"}>Full article</a></p>
                </div>
                </div>
                </div>
                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <Feedback subject={"Sustainability News From The World - March 2023 Edition"}/>
        </div>     
        </>
    )
}

export default SustainabilityNewsMar;