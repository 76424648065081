import React, { useEffect } from "react";
import "./SustainabilityNewsJune.css";
import "./SustainabilityNewsFeb.css";
import "./SustainabilityNewsJuly.css";
import "./SustainabilityNewsAugust.css";
import Feedback from './Feedback';
import { Link } from 'react-router-dom';

const SustainabilityNewsAugust = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div className="sustainability_main">
                <div className="sustainability_header">
                    <h2>Sustainability News From the World - AUGUST 2023 Edition</h2>
                </div>
                <div className='banner-container'>
                    <div className='home-banner'   >
                        <img src={process.env.PUBLIC_URL + "/images/Home/Sustainability_News/sustainability_news_banner.png"} className="sust_news_gif_img" />
                    </div>
                </div>
                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <div className="sustainability_mid_container">
                    <h2>LEADING WITH SUSTAINABILITY: TRANSLATING INTENT INTO ACTION</h2>
                    <p>As we continue to work toward our goal of translating sustainability from intent to action, we want to thank you for helping us shape the sustainability agenda for ourselves and our clients to deliver value.</p><br />
                    <p>It is indeed heartening to see such enthusiasm to bring sustainability into the heart of all conversations. Organizations around the world are stepping up to meet the sustainability challenge. The <b>ATCI Sustainability Research Cell</b> brings you the latest updates covering technology, industry, clients, Accenture, and the larger ecosystem. Hope you enjoy reading it and find inspiring ideas that you can apply to your ecosystem.</p>
                    <p className="sustainability_para">You can also <a className="sustainability_para_a" href="mailto:ATCI.Sust.Research@accenture.com?subject=ATCI Sustainability Research">share your ideas and suggestions</a> with us. We would love to hear more about your “sustainability stories” and feature them in our newsletter.</p><br />
                    <p>Let’s keep creating incredible conversations.</p>
                    <p className="sustainability_para">Regards,</p>
                    <p><strong>Shridhar Rajgopalan</strong>, Lead - Sustainability and Song, ATCI</p>
                    <p><strong>Anupama Nithyanand</strong>, Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI</p>
                </div>
                <div className="sustainability_li_container">
                    <h3>SUSTAINABILITY NEWS FROM THE WORLD - AUGUST 2023 EDITION</h3>
                    <div className="sustainability_wrap">
                        <ul>
                            <li>What's making news?</li>
                            <li>Can't miss: Trends & Technology</li>
                            <li>In-depth</li>
                            <li>Must-reads…</li>
                            <li>Soulful living!</li>
                            <li>Did you know</li>
                            <li>Sustainability Priority Learnings</li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_highlight_div">
                    <h4>What's making news?</h4>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon12.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Check out the replay of the Accenture Supplier Eco Summit</h4>
                            <p><a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=398&p=37085&caturl=aHR0cHM6Ly9pbi5hY2NlbnR1cmUuY29tL3N1c3RhaW5hYmlsaXR5L25ld3Mv" target={"_blank"}>Know from experts</a> how businesses can treat the environment better and accelerate sustainable transformation to meet their net-zero targets.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon14.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">POV: Harnessing the power of voluntary carbon markets </h4>
                            <p>Discover the impact of Voluntary Carbon Markets (VCMs) and know how you can empower your clients with strategies for can seize value and mitigate risks in the future of carbon markets in a sustainable world. <a className="hightlight_b" href="https://www.accenture.com/us-en/insights/strategy/macro-foresight#block-voluntary-carbon-markets" target={"_blank"}>Explore our latest POV now</a>.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewMay/icon6.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Powering the future of energy</h4>
                            <p>Accenture is the knowledge partner for Eurelectric’s Decarbonisation Speedways study which provides alternative scenarios towards full decarbonisation to lead Europe’s energy transition with clean electricity. <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=560&p=37139" target={"_blank"}>Read the study</a> and include it in your conversations with clients and prospects.</p>
                        </div>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Can't miss: Trends & Technology</h4>
                    <div className="midsection_li">
                        <div className="trends-sec-row">
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewAug/icon1.png"} alt="" />
                                <p>How can companies build sustainable supply chains? By adopting transparent and flexible supply chain practices, circular economy, and more. | <a className="mid_section_a" href="https://www.ibtimes.co.in/embracing-resilience-sustainability-value-chains-building-strategies-rapidly-changing-world-861051" target={"_blank"}>Read on</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewAug/icon2.png"} alt="" />
                                <p>The global Waste Management Market is expected to touch $542B by 2030. What are the key growth factors and trends driving sustainable solutions? | <a className="mid_section_a" href="https://www.digitaljournal.com/pr/news/newsmantraa/waste-management-market-key-growth-factors-and-trends-driving-sustainable-solutions-by-2030" target={"_blank"}>Explore</a></p>
                            </div>     
                        </div>
                        <div className="vertical-line-may"></div>
                        <div className="trends-sec-row">
                        <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewAug/icon3.png"} alt="" />
                                <p>#NewTech: Flexible packaging comes with low costs, reduced material usage, lower transportation costs, and recyclability and is set to accelerate sustainability measures across industries. | <a className="mid_section_a" href="https://www.digitaljournal.com/pr/news/newsmantraa/flexible-packaging-market-global-trends-growth-drivers-and-sustainable-solutions-by-2030" target={"_blank"}>Read on</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewAug/icon4.png"} alt="" />
                                <p>Explore the sustainability trends that are driving business transformation to manage risks, reinvent operations and create value | <a className="mid_section_a" href="https://ceo-na.com/ceo-life/environment/13-sustainability-trends-driving-business-in-2023/" target={"_blank"}>Explore</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">In-depth</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>From sustainable computing to aviation fuel, check out WEF’s list of top emerging technology for 2023. | <a className="mid_section_a" href="https://telecom.economictimes.indiatimes.com/news/internet/generative-ai-flexible-batteries-metaverse-among-top-emerging-tech-for-2023-wef/102030469" target={"_blank"}>Read on</a></li>
                            <li>AI is no longer the buzzword; it’s AGI, or Artificial General Intelligence. Experts believe it can greatly boost SDGs, particularly in tackling inequality. | <a className="mid_section_a" href="https://news.un.org/en/audio/2023/07/1139042" target={"_blank"}>Tune in</a></li>
                            <li>What is the business case for circular economy and what are sustainable business models? | <a className="mid_section_a" href="https://www.arabianbusiness.com/opinion/why-circular-economy-makes-business-sense" target={"_blank"}>Explore</a></li>
                            <li>What the world is doing to save the planet is not enough. We need more sustainability science and action to back it up. | <a className="mid_section_a" href="https://www.nature.com/articles/d41586-023-01989-9" target='_blank'>Nature</a></li>
                            <li>Explore the trends propelling a revolution in farm automation #FutureOfAgriculture. | <a className="mid_section_a" href="https://www.mckinsey.com/industries/agriculture/our-insights/trends-driving-automation-on-the-farm" target='_blank'>Know more</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Must-reads…</h4>
                    <div className="midsection_li_june">
                        <ul>
                            <li>How can we reduce global food wastage and rescue food before it hit the bins? A new application is making it possible across 17 countries. | <a className="mid_section_a" href="https://www.azocleantech.com/article.aspx?ArticleID=1700" target={"_blank"}>Read on</a></li>
                            <li>As climate change intensifies, AI weather models may face the challenge of simulating unprecedented forecasts. | <a className="mid_section_a" href="https://www.scientificamerican.com/article/climate-change-could-stump-ai-weather-prediction/" target={"_blank"}>Know more</a></li>
                            <li>According to UN, cities consume about 78% of the world’s energy and account for more than 60% of global greenhouse gas emissions. So, how can we build a sustainable city at scale? | <a className="mid_section_a" href="https://www.strategy-business.com/article/How-to-build-a-sustainable-city-at-scale" target={"_blank"}>Explore to know more</a></li>
                            <li>With a whopping 300M eco-friendly jobs estimated by 2050, it is no wonder so many are switching to green jobs. | <a className="mid_section_a" href="https://www.euronews.com/green/2023/07/09/can-you-change-a-polluting-industry-from-the-inside-ex-aviation-mining-and-gas-employees-s" target={"_blank"}>Read on</a></li>
                            <li>Rare minerals are critical for clean energy transition. But the global market is in chaos, putting the sustainability, equity and security goals at risk. | <a className="mid_section_a" href="https://www.nature.com/articles/d41586-023-02330-0" target={"_blank"}>Learn why</a></li>
                            <li>More than half of the Earth's oceans are turning green due to disturbing marine ecosystems. | <a className="mid_section_a" href="https://www.euronews.com/green/2023/07/13/turning-green-earths-oceans-are-changing-colour-due-to-climate-change" target={"_blank"}>More on climate change</a></li>
                            <li>New idea in making: Modifying solar radiation to cool the earth. | <a className="mid_section_a" href="https://www.euronews.com/green/2023/07/05/sun-blockers-us-scientists-aim-to-cool-the-earth-by-reflecting-sunlight-into-space" target={"_blank"}>Explore</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    {/* <hr /> */}
                </div>

                {/* <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Reports and Magazines</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>5 charts from the IPCC, Intergovernmental Panel on Climate Change report highlights risks of lower temperatures due to climate change <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/03/climate-change-ipcc-emissions-risks-net-zero/" target={"_blank"}>Read more...</a></li>
                            <li>What are nature climate solutions and how it can help to reduce climate change and bio-diversity loss. <a className="mid_section_a" href="https://www3.weforum.org/docs/WEF_NCSA_NCS_for_Corporates_2021.pdf" target={"_blank"}>Read more...</a></li>
                            <li>Understanding progress in water related ecosystem, SDGs and global data availability. <a className="mid_section_a" href="https://www.unep.org/resources/report/measuring-progress-water-related-ecosystems-and-sdgs" target={"_blank"}>Read more...</a></li>
                            <li>A study on non-native plants and its rapid growth highlights need for monitoring schemes to understand long term impact. <a className="mid_section_a" href="https://www.nature.com/articles/d44151-023-00034-y" target={"_blank"}>Read more...</a></li>
                            <li>Only second ever UN conference on water saw host of countries discuss water disasters related to climate change. <a className="mid_section_a" href="https://www.theguardian.com/environment/2023/mar/23/water-un-conference-climate-crisis-disasters" target={"_blank"}>Read more...</a></li>
                        </ul>
                    </div>
                </div> */}

                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Soulful Living!</h4>
                    <div className="midsec_box">
                        <div className="midsection_para_aug">
                            {/* <p><strong>Innovative use of AI to Assess Potholes issues on City Roads by a Bengaluru startup</strong></p> */}
                            <p className="mid_section_p">Straw homes are back, but now they are stronger and greener! Meet innovative construction start-ups using cereal farming by-products to build smarter, cooler homes, potentially reducing greenhouse gas emissions and promoting greener construction practices. | <a className="mid_section_a" href="https://www.ft.com/content/b1709f76-0995-4861-87a2-a18e747d88ba" target="_blank">Learn more</a></p>
                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewAug/new_ppl_img.png"} alt="sust_news" className="sust_news_img" />
                        </div>
                    </div>

                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>

                <div className="sustainability_mid_section_july">
                    <h4 className="midsection_h4">Did You Know</h4>
                    <div className="lastsection_march">
                        <div className="lastsection_march_1stCard">
                            <h4 className="lastsection_march_h4">50,000,000</h4>
                            {/* <p className="diduknow_p">Earth's surface mainly consists of 3 things: hydrosphere, biosphere & atmosphere.</p> */}
                            <p className="diduknow_p">The amount of wood and paper discarded each year can provide heat to warm 50,000,000 homes for 20 years. | <a className="mid_section_a" href="https://www.earthecho.org/news/did-you-know-some-interesting-facts-about-the-environment#:~:text=We%20consume%20over%2080%20trillion,circle%20the%20earth%2020%20times!" target={"_blank"}>Know more</a></p>
                        </div>
                        <div className="lastsection_march_2ndCard">
                            <h4 className="lastsection_march_h4">200M</h4>
                            <p className="diduknow_p">Global coastal areas currently home to more than 200M people could be permanently below sea level by 2100. | <a className="mid_section_a" href="https://rebellion.global/blog/2021/04/26/10-facts-sea-level-rise/" target={"_blank"}>Know more</a></p>
                        </div>
                        <div className="lastsection_march_3rdCard">
                            <h4 className="lastsection_march_h4">5,000</h4>
                            <p className="diduknow_p">5,000 people die every day as due to lack drinking unclean water.  | <a className="mid_section_a" href="https://www.conserve-energy-future.com/various-pollution-facts.php" target={"_blank"}>Know more</a></p>
                        </div>
                    </div>
                </div>

                <div className="sustainability_hr_div">
                    <hr />
                </div>

                <div className="sustainability_quiz_section">
                <h4 className="midsection_h4">The Green Challenge: Sustainability Quiz</h4>
                <p className="quiz_para">Think you have got what it takes to be a sustainability superstar? Take the fun quiz to find out.</p>
                <div className="quiz_section">
                <a className="quiz_link" href="https://www.menti.com/alu6ashzzbkv/0" target="_blank">
                    Click here to start the quiz
                </a>
                </div>
                </div>

                <div className="sustainability_hr_div_quiz">
                    <hr />
                </div>

                <div className="priority-learnings-container_july">
                    <h4 className="midsection_h4">Have you checked out the Sustainability Priority Learnings, yet?</h4>
                    <div className="priority-learnings-wrap">
                        <div className="priority-learnings-row">
                            <div className="priority-learnings-box">
                                <p>Course 1: <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/2020982" target="_blank">Green Software for Practitioners</a></p>
                                <p>|</p>
                                <p>Course 2: <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/1795845" target="_blank">Accenture Green Software</a></p>
                            </div>
                            <p>Here's a <a href="https://mediaexchange.accenture.com/media/t/1_n0hrgtw9?st=0" target="_blank">step-by-step video guide</a> on how to access Sustainability Priority Learnings.</p>
                            <p>Have queries? Check out the <a href="https://collections.accenture.com/FileViewer.aspx?f=4e9fb50d-00bd-4124-9212-33cdb7495382">FAQs here</a>.</p>
                        </div>
                    </div>
                </div>

                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <Feedback subject={"Sustainability News From The World - August 2023 Edition"} />

            </div>
        </>
    )
}

export default SustainabilityNewsAugust;