import React, { useEffect } from "react";
import "./SustainabilityNewsJune.css";
import "./SustainabilityNewsFeb.css";
import "./SustainabilityNewsJuly.css";
import "./SustainabilityNewsAugust.css";
import Feedback from './Feedback';
import { Link } from 'react-router-dom';

const SustainabilityNewsNov = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div className="sustainability_main">
                <div className="sustainability_header">
                    <h2>Sustainability News From the World - NOVEMBER 2023 Edition</h2>
                </div>
                <div className='banner-container'>
                    <div className='home-banner'   >
                        <img src={process.env.PUBLIC_URL + "/images/Home/Sustainability_News/sustainability_news_banner.png"} className="sust_news_gif_img" />
                    </div>
                </div>
                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <div className="sustainability_mid_container">
                    <h2>LEADING WITH SUSTAINABILITY: TRANSLATING INTENT INTO ACTION</h2>
                    <p>As we continue to work toward our goal of translating sustainability from intent to action, we want to thank you for helping us shape the sustainability agenda for ourselves and our clients to deliver value.</p><br />
                    <p>It is indeed heartening to see such enthusiasm to bring sustainability into the heart of all conversations. Organizations around the world are stepping up to meet the sustainability challenge. The <b>ATCI Sustainability Research Cell</b> brings you the latest updates covering technology, industry, clients, Accenture, and the larger ecosystem. Hope you enjoy reading it and find inspiring ideas that you can apply to your ecosystem.</p>
                    <p className="sustainability_para">You can also <a className="sustainability_para_a" href="mailto:ATCI.Sust.Research@accenture.com?subject=ATCI Sustainability Research">share your ideas and suggestions</a> with us. We would love to hear more about your “sustainability stories” and feature them in our newsletter.</p><br />
                    <p>Let’s keep creating incredible conversations.</p>
                    <p className="sustainability_para">Regards,</p>
                    <p><strong>Shridhar Rajgopalan</strong>, Lead - Sustainability and Song, ATCI</p>
                    <p><strong>Anupama Nithyanand</strong>, Lead - Learning & Talent Transformation and Sustainability Research Cell, ATCI</p>
                </div>
                <div className="sustainability_li_container">
                    <h3>SUSTAINABILITY NEWS FROM THE WORLD - NOVEMBER 2023 EDITION</h3>
                    <div className="sustainability_wrap">
                        <ul>
                            <li>What's making news?</li>
                            <li>Can't miss: Trends & Technology</li>
                            <li>In-depth</li>
                            <li>Must-reads…</li>
                            <li>Soulful living!</li>
                            <li>Did you know</li>
                            <li>Sustainability Priority Learnings</li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_highlight_div">
                    <h4>What's making news?</h4>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon1.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Celebrating 20 years of impact</h4>
                            <p>As we mark 20 years of Accenture Development Partnerships (ADP) this year, <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=345&p=12546" target={"_blank"}>here’s a look</a> at the impact we’ve created with more than 1,900 projects in nearly 100 countries.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon2.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Accenture’s Life Trends 2024 report is out</h4>
                            <p>Investing in sustainability-related behavior change is becoming more important finds <a className="hightlight_b" href="https://www.accenture.com/us-en/insights/song/accenture-life-trends?c=acn_glb_accenturelifetrmediarelations_13980720&n=mrl_1023" target={"_blank"}>Accenture’s Life Trends 2024 report</a>. Explore the five greatest consumer trends that are set to changes business as we know it.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon3.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Latest in the Embed Sustainability blog series</h4>
                            <p><a className="hightlight_b" href="https://www.accenture.com/us-en/blogs/consulting/minting-a-sustainable-future" target={"_blank"}>Read the latest blog</a> on how sustainability is no longer seen to be a fringe compliance responsibility but a core business-performance issue for businesses.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon4.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Meet the champs</h4>
                            <p>As our Sustainability Services Network Function continues to grow bigger and better, get to know our colleagues and learn more about their incredible skillsets in our <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=282&p=38147" target={"_blank"}>people spotlight</a>.</p>
                        </div>
                    </div>
                    <div className="making-news">
                        <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon5.png"} alt="" />
                        <div>
                            <h4 className="highlight_h4_tag_may">Latest in events: The legal landscape of ESG (Environment, Social, Governance)</h4>
                            <p>Block your calendar on November 9 for the Backstage Pass event to learn more about the resources available to answer client questions in the ESG space. <a className="hightlight_b" href="https://in.accenture.com/sustainability/?site=1&blog=398&p=37637&caturl=aHR0cHM6Ly9pbi5hY2NlbnR1cmUuY29tL3N1c3RhaW5hYmlsaXR5L25ld3Mv" target={"_blank"}>Download the invite now</a>.</p>
                        </div>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Can't miss: Trends & Technology</h4>
                    <div className="midsection_li">
                        <div className="trends-sec-row">
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon4.png"} alt="" />
                                <p>Semiconductor chips power many devices, but they come at a significant environmental cost. How can chip manufacturers drive greener solutions? | <a className="mid_section_a" href="https://www.azonano.com/article.aspx?ArticleID=6570" target={"_blank"}>Explore</a></p>
                            </div>
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon6.png"} alt="" />
                                <p>Sustainable technology—a framework of digital solutions that enable ESG outcomes—makes it to Gartner’s top 10 strategic technology trends for 2024 | <a className="mid_section_a" href="https://www.forbes.com/sites/peterhigh/2023/10/17/gartners-top-10-strategic-tech-trends-for-2024/?sh=49d63f4e4b7b" target={"_blank"}>Forbes</a></p>
                            </div>     
                        </div>
                        <div className="vertical-line-may"></div>
                        <div className="trends-sec-row">
                            <div className="trends-sec">
                                <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/icon8.png"} alt="" />
                                <p>Know how Deep tech, which combines physical and digital technologies, is revolutionizing manufacturing and enhancing the Profitability-Sustainability profile of products. | <a className="mid_section_a" href="https://hbr.org/2023/09/how-deep-tech-can-drive-sustainability-and-profitability-in-manufacturing?autocomplete=true" target={"_blank"}>Read on</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">In-depth</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>The Gen Z is living a sustainability paradox. They want to embrace circular economy, but do not want to let go of fast fashion. Is there a middle path? | <a className="mid_section_a" href="https://www.harpersbazaar.in/fashion/story/what-does-the-genz-really-want-698923-2023-10-24" target={"_blank"}>Read on</a></li>
                            <li>From sustainable tourism to sustainable finance to technology and climate change are among the key priorities in future: The Global Future Councils 2023 | <a className="mid_section_a" href="https://www.forbes.com/sites/soulaimagourani/2023/10/24/navigating-the-future-of-work-and-global-leadership-trends/?sh=1615178230e2" target={"_blank"}>Explore</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Must-reads…</h4>
                    <div className="midsection_li_june">
                        <ul>
                            <li>Leveraging plant biology insights, know how companies are optimizing plant performance, develop new varieties, enhance quality and reduce costs. | <a className="mid_section_a" href="https://sustainablereview.com/the-future-of-agriculture-exploring-aerofarms-vertical-farming-technology/" target={"_blank"}>Read on</a></li>
                            <li>A sustainable brew: Beanless coffee causes 93 percent fewer carbon emissions and uses 94 percent less water than regular coffee. | <a className="mid_section_a" href="https://www.euronews.com/green/2023/10/04/superfood-based-beanless-coffee-could-slash-emissions-and-water-use-by-94" target={"_blank"}>Know more</a></li>
                            <li>How can we adopt farming practices that guarantee food security as well as restore nature? | <a className="mid_section_a" href="https://www.euronews.com/green/2023/09/27/changing-the-way-we-farm-good-for-people-and-good-for-the-planet" target={"_blank"}>Read on</a></li>
                            <li>From next-generation solar cells to special viewfinders that help people with low vision enjoy taking pictures, this sustainability tech show covered it all. | <a className="mid_section_a" href="https://www.japantimes.co.jp/business/2023/10/17/tech/ceatec-2023-sustainablity-focus/" target={"_blank"}>Explore</a></li>
                        </ul>
                    </div>
                </div>
                <div className="sustainability_hr_div">
                    {/* <hr /> */}
                </div>

                {/* <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Reports and Magazines</h4>
                    <div className="midsection_li">
                        <ul>
                            <li>5 charts from the IPCC, Intergovernmental Panel on Climate Change report highlights risks of lower temperatures due to climate change <a className="mid_section_a" href="https://www.weforum.org/agenda/2023/03/climate-change-ipcc-emissions-risks-net-zero/" target={"_blank"}>Read more...</a></li>
                            <li>What are nature climate solutions and how it can help to reduce climate change and bio-diversity loss. <a className="mid_section_a" href="https://www3.weforum.org/docs/WEF_NCSA_NCS_for_Corporates_2021.pdf" target={"_blank"}>Read more...</a></li>
                            <li>Understanding progress in water related ecosystem, SDGs and global data availability. <a className="mid_section_a" href="https://www.unep.org/resources/report/measuring-progress-water-related-ecosystems-and-sdgs" target={"_blank"}>Read more...</a></li>
                            <li>A study on non-native plants and its rapid growth highlights need for monitoring schemes to understand long term impact. <a className="mid_section_a" href="https://www.nature.com/articles/d44151-023-00034-y" target={"_blank"}>Read more...</a></li>
                            <li>Only second ever UN conference on water saw host of countries discuss water disasters related to climate change. <a className="mid_section_a" href="https://www.theguardian.com/environment/2023/mar/23/water-un-conference-climate-crisis-disasters" target={"_blank"}>Read more...</a></li>
                        </ul>
                    </div>
                </div> */}

                <div className="sustainability_hr_div">
                    <hr />
                </div>
                <div className="sustainability_mid_section">
                    <h4 className="midsection_h4">Soulful Living!</h4>
                    <div className="midsec_box">
                        <div className="midsection_para_aug">
                            {/* <p><strong>Innovative use of AI to Assess Potholes issues on City Roads by a Bengaluru startup</strong></p> */}
                            <p className="mid_section_p"><a className="mid_section_a" href="https://www.thebetterindia.com/332504/water-heroes-in-india-revamp-lakes-rivers-canals-solve-drought/" target="_blank">Meet five eco warriors</a> who have made it their mission to bring life back into lakes, rejuvenating local ponds in remote areas in India and bringing clean drinking water to thousands of people.</p>
                        </div>
                        <div>
                            <img src={process.env.PUBLIC_URL + "/images/Home/SustainabilityNewOct/soulful_img_nov.png"} alt="sust_news" className="sust_news_img" />
                        </div>
                    </div>

                </div>
                <div className="sustainability_hr_div">
                    <hr />
                </div>

                <div className="sustainability_mid_section_july">
                    <h4 className="midsection_h4">Did You Know</h4>
                    <div className="lastsection_march">
                        <div className="lastsection_march_1stCard">
                            <h4 className="lastsection_march_h4">135 million</h4>
                            {/* <p className="diduknow_p">Earth's surface mainly consists of 3 things: hydrosphere, biosphere & atmosphere.</p> */}
                            <p className="diduknow_p_sept">The Indo-Gangetic Plain is India's Food Bowl, that produces 30% of its food and ~135M metric tonnes of cereals. | <a className="mid_section_a" href="https://www.nature.com/articles/s44221-023-00135-z" target={"_blank"}>Read on</a></p>
                        </div>
                        <div className="lastsection_march_2ndCard">
                            <h4 className="lastsection_march_h4">60 percent</h4>
                            <p className="diduknow_p_sept">Restoring 15% of converted lands and halting further conversion can avert 60% of anticipated species extinctions. | <a className="mid_section_a" href="https://www.worldenvironmentday.global/did-you-know/facts-figures" target={"_blank"}>Know more</a></p>
                        </div>
                        <div className="lastsection_march_3rdCard">
                            <h4 className="lastsection_march_h4">90,000</h4>
                            <p className="diduknow_p_sept">In a single square mile, mangroves hold as much carbon as the annual emissions of 90,000 cars. | <a className="mid_section_a" href="https://www.conservation.org/priorities/mangroves#:~:text=Why%20mangroves%20matter,them%20from%20predators%20and%20currents." target={"_blank"}>Find out more</a></p>
                        </div>
                    </div>
                </div>

                <div className="sustainability_hr_div">
                    <hr />
                </div>

                <div className="sustainability_quiz_section">
                <h4 className="midsection_h4">The Green Challenge: Sustainability Quiz</h4>
                <p className="quiz_para">Think you have got what it takes to be a sustainability superstar? Fun quiz to find out.</p>
                <div className="quiz_section">
                <a className="quiz_link" href="https://www.menti.com/alr7dg1mk6o9/0" target="_blank">
                    Click here to start the quiz
                </a>
                </div>
                </div>

                <div className="sustainability_hr_div_quiz">
                    <hr />
                </div>

                <div className="priority-learnings-container_july">
                    <h4 className="midsection_h4">Have you checked out the Sustainability Priority Learnings, yet?</h4>
                    <div className="priority-learnings-wrap">
                        <div className="priority-learnings-row">
                            <div className="priority-learnings-box">
                                <p>Course 1: <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/2020982" target="_blank">Green Software for Practitioners</a></p>
                                <p>|</p>
                                <p>Course 2: <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/1795845" target="_blank">Accenture Green Software</a></p>
                            </div>
                            <p>Here's a <a href="https://mediaexchange.accenture.com/media/t/1_n0hrgtw9?st=0" target="_blank">step-by-step video guide</a> on how to access Sustainability Priority Learnings.</p>
                            <p>Have queries? Check out the <a href="https://collections.accenture.com/FileViewer.aspx?f=4e9fb50d-00bd-4124-9212-33cdb7495382">FAQs here</a>.</p>
                        </div>
                    </div>
                </div>

                <div className="go_back_button">
                    <a href="/home">Home</a>
                </div>
                <Feedback subject={"Sustainability News From The World - November 2023 Edition"} />
            </div>
        </>
    )
}

export default SustainabilityNewsNov;